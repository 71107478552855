import React from "react";
import "./NavBar.scss";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import MoovSoonLogo from "../../assets/Movsoon logo.png";
import MoovSoonBranding from "../../assets/branding bg.png";

import { navigationLinks } from "./helper";

const NavBar = ({ userData }) => {
    const oddEvent = (match, location, to) => {
        return location.pathname.startsWith(to);
    };

    return (
        <aside className="moovsoon-navbar">
            <Scrollbars autoHide={true}>
                <div className="navbar-container">
                    <div className="navbar-logo">
                        <img src={MoovSoonLogo} alt="MOOVSOON" />
                    </div>
                    <nav className="navbar-nav">
                        <ul>
                            {navigationLinks({ userData }).map((nav, index) =>
                                nav.hide ? null : (
                                    <li key={index}>
                                        <NavLink
                                            to={nav.to}
                                            isActive={(match, location) =>
                                                oddEvent(
                                                    match,
                                                    location,
                                                    nav.to
                                                )
                                            }
                                            activeClassName="active"
                                        >
                                            <i className={`icon ${nav.icon}`} />
                                            {nav.label}
                                        </NavLink>
                                    </li>
                                )
                            )}
                        </ul>
                    </nav>
                    <div className="navbar-branding">
                        <img src={MoovSoonBranding} alt="Branding" />
                    </div>
                </div>
            </Scrollbars>
        </aside>
    );
};

export default NavBar;
