import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "./assets/style.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { store } from "./store";

import { ClerkProvider  } from '@clerk/clerk-react';

const publishableKey = 'pk_test_d29uZHJvdXMtemVicmEtODIuY2xlcmsuYWNjb3VudHMuZGV2JA';

ReactDOM.render(

    <Provider store={store}>
      <ClerkProvider fallbackRedirectUrl='/' publishableKey={publishableKey}>
	      {/*<SignedIn>
	        <UserButton afterSignOutUrl="/" />
	      </SignedIn> */}
        <BrowserRouter basename={"/"}>
            <App />
        </BrowserRouter>
      </ClerkProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
