import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import Select from "react-select";
import { get } from "lodash";

import { getCompanyChartData, getMarketList } from "../../store/actions";
import DashboardMarketDetails from "./dashboardMarketDetails";
import emptyImageMarket from "../../assets/market not yet created.png";

const colors = ["#4edd7a", "#4ca6ea", "#654adc", "#FF5722"];

const initialStates = {
  marketList: null,
  selectedMarket: { label: "All", value: "", type: -1 },
  userChartData: null,
  totalListingsCount: 0,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const UserChart = ({
  getCompanyChartData,
  getMarketList,
  marketDetails,
  loggedInAs,
}) => {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const { marketList, selectedMarket, userChartData, totalListingsCount } =
    state;

  const options = {
    legend: {
      display: false,
    },
    cutoutPercentage: 70,
  };

  const doughnutStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        paddingLeft:
          data.type === 2 ? "45px" : data.type === 1 ? "30px" : "15px",
        color: "#000",
        backgroundColor: isSelected ? "#cbdaef" : "transparent",
      };
    },
  };

  useEffect(() => {
    const newList = [];
    const processList = (list) => {
      list.forEach((market) => {
        const type = market.bucketId ? 2 : market.submarketId ? 1 : 0;
        const label =
          market.bucketName || market.subMarketName || market.marketName;
        const value = market.bucketId || market.submarketId || market.marketId;

        newList.push({ type, label, value });
        if (market.subMarkets && market.subMarkets.length) {
          processList(market.subMarkets);
        }
        if (market.buckets && market.buckets.length) {
          processList(market.buckets);
        }
      });
      return newList;
    };

    const successHandler = (e) => {
      const result = [
        { label: "All", value: "", type: -1 },
        ...processList(e.result),
      ];
      dispatch({
        marketList: result,
      });
    };

    getMarketList({ compRefId: null }, successHandler);
  }, [getMarketList]);

  useEffect(() => {
    const successHandler = (e) => {
      const result = e.result;
      const labels = [];
      const types = [];
      const ids = [];
      const data = [];
      const backgroundColor = [];
      let colorIndex = 0;
      result.forEach((item, index) => {
        labels.push(item.marketName);
        types.push(item.marketType);
        ids.push(item.marketId);
        data.push(item.propertyCount);
        colorIndex = colors[colorIndex] ? colorIndex : 0;
        backgroundColor.push(colors[colorIndex]);
        colorIndex++;
      });

      dispatch({
        userChartData: {
          labels,
          ids,
          types,
          datasets: [
            {
              data,
              backgroundColor,
            },
          ],
        },
        totalListingsCount:
          data && data.length ? data.reduce((total, item) => total + item) : 0,
      });
    };
    selectedMarket &&
      getCompanyChartData(
        selectedMarket && selectedMarket.type > -1 && selectedMarket.value
          ? {
              marketType: selectedMarket.type,
              marketId: selectedMarket.value,
            }
          : selectedMarket && selectedMarket.type > -1
          ? {
              marketType: selectedMarket.type,
            }
          : selectedMarket && selectedMarket.value
          ? {
              marketId: selectedMarket.value,
            }
          : null,
        successHandler
      );
  }, [selectedMarket, getCompanyChartData]);

  const onMarketChange = (selectedMarket) => {
    dispatch({
      selectedMarket,
    });
  };

  const renderMarketsDropdown = () => {
    return marketList && marketList.length ? (
      <div className="market-dropdown form-group w-20">
        <Select
          className="form-control custom-select-box p-0"
          options={marketList.filter((item) => item.value || item.value === "")}
          defaultInputValue={""}
          defaultValue={selectedMarket}
          onChange={onMarketChange}
          styles={doughnutStyles}
        />
      </div>
    ) : null;
  };

  const onDoughnutClick = (e) => {
    if (userChartData && e && e.length) {
      const newSelectedMarket = {
        type: userChartData.types[e[0]._index],
        value: userChartData.ids[e[0]._index],
        label: userChartData.labels[e[0]._index],
      };
      newSelectedMarket &&
        newSelectedMarket.value !== "00000000-0000-0000-0000-000000000000" &&
        dispatch({
          selectedMarket: newSelectedMarket,
        });
    }
  };

  const renderAvailableNumbers = () => {
    if (userChartData && selectedMarket) {
      const length = userChartData.labels.filter(
        (item) => item !== "Unallocated"
      ).length;
      switch (selectedMarket.type) {
        case 0:
          return (
            <div className="available-market-length text-gray text-sm">
              {length} submarket(s) available
            </div>
          );
        case 1:
          return (
            <div className="available-market-length text-gray text-sm">
              {length} bucket(s) available
            </div>
          );
        default:
          return (
            <div className="available-market-length text-gray text-sm">
              {length} market(s) available
            </div>
          );
      }
    }
    return null;
  };

  return (
    <div className="card card-primary p-4">
      <div className="d-flex user-chart-header">
        <div className="flex-fill d-flex align-items-center mb-4">
          <h2 className="mb-0">Market Overview</h2>
          <span className="flex-fill ml-3 text-primary">
            <i className="icon icon-wifi mr-1" />
            <em>Live Listings</em>
          </span>
        </div>
        {renderMarketsDropdown()}
      </div>

      {(userChartData && userChartData.labels && userChartData.labels.length) ||
      (selectedMarket && selectedMarket.value) ? (
        <div className="my-3 text-center">
          {selectedMarket ? (
            <div className="text-md">
              {selectedMarket.type === -1 ? "Overall" : selectedMarket.label}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="empty-component">
          <img
            src={emptyImageMarket}
            alt="emptyImageMarket"
            className="empty-component-icon"
          />
          <h3 className="color-1 text-uppercase">Market not yet available!</h3>
        </div>
      )}

      <div className="user-chart-wrap">
        {userChartData &&
        userChartData.labels &&
        userChartData.labels.length ? (
          <div className="user-chart-container">
            <Doughnut
              data={userChartData}
              options={options}
              width={160}
              height={160}
              onElementsClick={onDoughnutClick}
            />
            {selectedMarket ? (
              <div className="chart-overlay-data">
                <div className="text-gray">Total Listings</div>
                <h2 className="m-0">{totalListingsCount}</h2>
              </div>
            ) : null}
            {renderAvailableNumbers()}
          </div>
        ) : null}
        {selectedMarket && selectedMarket.value ? (
          <div className="market-details">
            <DashboardMarketDetails
              marketRefId={selectedMarket.value}
              marketType={selectedMarket.type}
              className="flex-column flex-fill"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    marketDetails: get(state, "Dashboard.marketDetails", null),
  };
};

const mapDispatchToProps = {
  getCompanyChartData,
  getMarketList,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserChart);
