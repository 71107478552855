import React from "react";
import Tooltip from "../../utils/tooltip";
import { Currency, Date } from "../../utils/formatter";

const AppliedFiltersList = (props) => {
  const {
    filterObject,
    setFilters,
    setFilterSelectedValues,
    setSelectedDate,
    filterSelectedValues,
    selectedDate,
    propertyStatuses,
    propertyRealtors,
    setSelectedSavedValue,
    selectedPendingDate,
    setSelectedPendingDate,
  } = props;

  /**
   * removeFilter function using to remove the selected filter properties.
   * @param {filterKey} string
   * if the filterKey is empty , it will remove all filter properties
   */
  const removeFilter = (filterKey = "") => {
    if (filterKey === "") {
      setSelectedSavedValue("-");
      setFilters({
        ...filterObject,
        filters: [],
      });
      setFilterSelectedValues({}); //Reset form values
      setSelectedDate({}); //Reset if datepicker has a selected value
      setSelectedPendingDate({});
    } else {
      //Generate the filter object bypassing the passed item
      const filterSet = filterObject.filters.filter((item) => {
        return item.key !== filterKey;
      });
      if (!filterSet.length) setSelectedSavedValue("-");
      setFilters({
        ...filterObject,
        filters: filterSet,
      });
      //Reset form value
      let filteredFormValues = filterSelectedValues;
      if (filteredFormValues.hasOwnProperty(filterKey))
        delete filteredFormValues[filterKey];
      setFilterSelectedValues(filteredFormValues);
      if (filterKey === "dateaddedfrom")
        setSelectedDate({ ...selectedDate, from: "" });
      if (filterKey === "dateaddedto")
        setSelectedDate({ ...selectedDate, to: "" });
      if (filterKey === "statusdateaddedfrom")
        setSelectedPendingDate({ ...selectedPendingDate, from: "" });
      if (filterKey === "statusdateaddedto")
        setSelectedPendingDate({ ...selectedPendingDate, to: "" });
    }
  };

  const nonSAfilterSettingsOptions = [
    { label: "Favorite", value: "markAsFavourite" },
    { label: "In Process", value: "markAsInProgress" },
    { label: "Opportunities", value: "opportunities" },
    { label: "Booked", value: "booked" },
    { label: "Discarded", value: "markAsDiscarded" },
  ];

  const getTooltipData = (row) => {
    switch (row.key) {
      case "status":
        return propertyStatuses.find((element) => element.value === row.value)
          .label;
      case "realtor":
        return propertyRealtors.find((element) => element.value === row.value)
          .label;
      case "settings":
        return nonSAfilterSettingsOptions.find(
          (element) => element.value === row.value
        ).label;
      case "dateaddedfrom":
        return <Date utcTime={row.value} />;
      case "dateaddedto":
        return <Date utcTime={row.value} />;
      case "statusdateaddedfrom":
        return <Date utcTime={row.value} />;
      case "statusdateaddedto":
        return <Date utcTime={row.value} />;
      case "pricemin":
        return <Currency amount={row.value} currency="USD" />;
      case "pricemax":
        return <Currency amount={row.value} currency="USD" />;
      case "sqftmin":
        return `${row.value} SQFT`;
      case "sqftmax":
        return `${row.value} SQFT`;
      default:
        return row.value;
    }
  };
  return (
    <div>
      {filterObject && filterObject.filters && filterObject.filters.length ? (
        <div className="row align-items-start">
          <div className="col-md-12  form-group">
            <div className="form-value">
              {filterObject.filters
                .filter((item) => item.value != null)
                .map((item, index) =>
                  item.value ? (
                    <Tooltip
                      message={getTooltipData(item)}
                      key={index}
                      component={
                        <div className="badge badge-secondary badge-sm mr-2">
                          {item.label}
                          <span
                            onClick={() => removeFilter(item.key)}
                            className="remove-filter"
                          >
                            X
                          </span>
                        </div>
                      }
                    />
                  ) : null
                )}
              <span
                className="text-primary pointer"
                onClick={() => removeFilter()}
              >
                Reset Filter
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default AppliedFiltersList;
