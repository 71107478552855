import moment from "moment";

export function formatOpenHouseDate(openHouse) {
    const startDateMatch = openHouse?.match(/'start_date': '([^']+)'/);
    const timeZoneMatch = openHouse?.match(/'time_zone': '([^']+)'/);
  
    let startDate = '';
    let timeZone = '';
  
    if (startDateMatch) {
      startDate = startDateMatch[1];
    }
  
    if (timeZoneMatch) {
      timeZone = timeZoneMatch[1];
    }
    const formatDate = moment(startDate).format("MM/DD/YYYY, HH:mm");

  
    return { formatDate, timeZone };
}