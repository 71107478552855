import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import TemplatePanelBlock from "./templatePanelBlock";
import { Field } from "redux-form";
import FormGroup from "./formGroup";

import { outboundCommunicationType } from "../constant/templateManagement";

const TemplatePanel = (state) => {
	const {
		setPanel,
		ownerTemplateProspectingEvents,
		relatorTemplateProspectingEvents,
		templateList,
		initialValues,
		formValues,
		dataValues,
		createMode,
		property,
		showFilterPanel,
		savePropertySettings,
	} = state;
	const [tab, setTab] = useState(1);
	//Function to get the Notificatin Prospecting Status based on the outbound Communication Type (owner/relator)
	const getNotificatinProspectingStatus = (outboundCommunicationType) => {
		const selected = dataValues.subMarketNotificationSettings.length
			? dataValues.subMarketNotificationSettings.filter((item) => {
					return (
						item.outboundProspectingType ===
						outboundCommunicationType
					);
			  })[0]
			: null;
		return selected ? selected.turnOffAutomaticProspecting : true;
	};

	return (
		<>
			{createMode ? (
				<div className="d-flex">
					<h2 className="mb-4 flex-fill">Templates</h2>
					<div
						className="ml-3 btn btn-default btn-sm mb-3"
						onClick={() =>
							property ? showFilterPanel(false) : setPanel(1)
						}>
						<span className="text-primary">&lt;</span> Back
					</div>
				</div>
			) : null}
			<Tabs
				className="template-tabs"
				activeKey={tab}
				onSelect={(k) => setTab(k)}>
				<Tab
					eventKey={1}
					title={
						<div>
							<i className="icon icon-home" /> Home Owner
						</div>
					}>
					<div className="form-container">
						<div className="h-100">
							{createMode ? (
								<>
									<Field
										name="ownerTurnOffAutomaticProspecting"
										label="Turn off home owner prospecting"
										type="boolean"
										component={FormGroup}
									/>
									<div
										className={
											formValues &&
											formValues.ownerTurnOffAutomaticProspecting
												? "hide"
												: ""
										}>
										{ownerTemplateProspectingEvents.map(
											(item, index) => (
												<TemplatePanelBlock
													{...{
														index,
														templateList,
														item,
														outboundType:
															outboundCommunicationType
																.homeOwner
																.value,
														key: index,
														initialValues,
														createMode,
														property,
														componentType: 'homeowner',
													}}
												/>
											)
										)}
									</div>
								</>
							) : (
								<>
									{!getNotificatinProspectingStatus(
										outboundCommunicationType.homeOwner
											.value
									) ? (
										ownerTemplateProspectingEvents.map(
											(item, index) => (
												<TemplatePanelBlock
													{...{
														templateList,
														item,
														outboundType:
															outboundCommunicationType
																.homeOwner
																.value,
														key: index,
														initialValues: dataValues,
														createMode,
														componentType: 'homeowner',
													}}
												/>
											)
										)
									) : (
										<div className="form-group flex-row align-items-center">
											<div className="form-label">
												Automatic Prospecting for Home
												Owner :
											</div>
											<div className="form-value pl-3">
												Off
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</Tab>
				<Tab
					eventKey={2}
					title={
						<div>
							<i className="icon icon-users-check" /> Realtor
						</div>
					}>
					<div className="form-container">
						<div className="h-100">
							{createMode ? (
								<>
									<Field
										name="realtorTurnOffAutomaticProspecting"
										label="Turn off realtor prospecting"
										type="boolean"
										component={FormGroup}
									/>
									<div
										className={
											formValues &&
											formValues.realtorTurnOffAutomaticProspecting
												? "hide"
												: ""
										}>
										{relatorTemplateProspectingEvents.map(
											(item, index) => (
												<TemplatePanelBlock
													{...{
														index,
														templateList,
														item,
														outboundType:
															outboundCommunicationType
																.realtor.value,
														key: index,
														initialValues,
														createMode,
														property,
														componentType: 'realtor',
													}}
												/>
											)
										)}
									</div>
								</>
							) : (
								<>
									{!getNotificatinProspectingStatus(
										outboundCommunicationType.realtor.value
									) ? (
										ownerTemplateProspectingEvents.map(
											(item, index) => (
												<TemplatePanelBlock
													{...{
														templateList,
														item,
														outboundType:
															outboundCommunicationType
																.realtor.value,
														key: index,
														initialValues: dataValues,
														createMode,
														componentType: 'realtor',
													}}
												/>
											)
										)
									) : (
										<div className="form-group flex-row align-items-center">
											<div className="form-label">
												Automatic Prospecting for
												Realtor :
											</div>
											<div className="form-value pl-3">
												Off
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</Tab>
			</Tabs>
			{createMode ? (
				<div className="d-flex flex-row-reverse">
					<div
						className="ml-3 btn btn-default btn-sm mb-3"
						onClick={() =>
							property ? savePropertySettings() : setPanel(1)
						}>
						{property ? `Save` : `Set and Go`}{" "}
						<span className="text-primary">&gt;</span>
					</div>
				</div>
			) : null}
		</>
	);
};

export default TemplatePanel;
