import React from "react";
import { Field } from "redux-form";
import FormGroup from "../../utils/formGroup";

const APISettings = ({ fields }) => {
	return (
		<>
			<h3 className="color-1 text-uppercase">API Settings</h3>
			<div className="row mb-4">
				<Field component={FormGroup} {...fields.apiProviderId} />
				<Field component={FormGroup} {...fields.apiKey} />
			</div>
		</>
	);
};

export default APISettings;
