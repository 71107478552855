import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import FormGroup from "../../utils/formGroup";
import { required, max1600 } from "../../constant/formValidators";
import { Button } from "../uiElements";

const SMSTemplate = ({
  handleSubmit,
  onSubmit,
  parentDispatch,
  propertyDetails,
}) => {
  const [charCount, setCharCount] = useState(0);

  const formFields = {
    templateSMSBody: {
      name: "templateSMSBody",
      type: "textarea",
      formGroupClass: "col-12",
      validate: [required, max1600],
      onChange: (e) => {
        setCharCount(e.target.value.length);
      },
      placeholder: "Write something here..",
    },
  };

  return (
    <div className="create-template-wrap">
      <div className="d-flex align-items-center justify-content-between px-4 py-3 bb-1">
        <div className="d-flex flex-row align-items-center">
          <h2 className="color-1 m-0">Compose SMS</h2>
          {propertyDetails?.address && (
            <h3 className="color-1 m-0 flex-fill ml-2">-</h3>
          )}
          <h3 className="color-1 m-0 flex-fill ml-1">
            {propertyDetails?.address}
          </h3>
        </div>
        <button
          type="button"
          className="btn btn-default btn-sm"
          onClick={() =>
            parentDispatch({
              showSMSPanel: false,
            })
          }
        >
          Close
        </button>
      </div>

      <form className="flex-fill d-flex" onSubmit={handleSubmit(onSubmit)}>
        <div className="create-template-right">
          <div className="create-template-box">
            <div className="create-template-form px-4">
              <div className="form-container">
                <div className="card card-primary p-4">
                  <div className="row">
                    {charCount >= 360 && (
                      <div className="col-12 mb-3 alert alert-text">
                        SMS has exceeded 360 characters. This may incur
                        additional charges.
                      </div>
                    )}
                    <Field
                      component={FormGroup}
                      {...formFields.templateSMSBody}
                    />
                  </div>
                </div>
              </div>
              <div className="form-footer text-right py-3">
                <Button
                  className="btn btn-primary mt-2"
                  type="submit"
                  value="Send"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "smsTemplateForm",
  enableReinitialize: true,
})(SMSTemplate);
