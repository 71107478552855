import React, { useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, change } from "redux-form";

import TemplatePanel from "../../../utils/templatesPanel";
import { get } from "lodash";

const AutomaticProspectingForm = ({
	initialValuesData = null,
	createMode,
	setPanel,
	ownerTemplateProspectingEvents,
	relatorTemplateProspectingEvents,
	templateList,
	formValues,
	panel,
	initialValues,
	autoFromValues,
	change,
	updateProspectingInitial,
}) => {
	useEffect(() => {
		if (updateProspectingInitial) {
			change(
				"AutomaticProspectingForm",
				"realtorTurnOffAutomaticProspecting",
				initialValues.realtorTurnOffAutomaticProspecting
			);
			change(
				"AutomaticProspectingForm",
				"ownerTurnOffAutomaticProspecting",
				initialValues.ownerTurnOffAutomaticProspecting
			);
		}
	}, [updateProspectingInitial, change, initialValues]);
	return (
		<>
			{createMode || (!createMode && initialValuesData) ? (
				<div
					className={`panel-switch ${panel === 2 ? "active" : ""}`}
					id="template">
					<TemplatePanel
						{...{
							setPanel,
							ownerTemplateProspectingEvents,
							relatorTemplateProspectingEvents,
							templateList,
							initialValues,
							formValues: autoFromValues,
							createMode: 1,
						}}
					/>
				</div>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		initialValues: get(state, "Market.propspectinginitialValues", []),
	};
};

const mapDispatchToProps = {
	change,
};
const decoratedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(AutomaticProspectingForm);

export default reduxForm({
	form: "AutomaticProspectingForm",
	enableReinitialize: true,
})(decoratedComponent);
