import React, { useEffect, useCallback, useReducer } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import "./MarketListing.scss";

import {
  getMarkets,
  getMarket,
  toggleMainLoader,
  resetField,
  deleteMarket,
  getCompanyMarkets,
  getmarketTemplateProspectingEvents,
  getAllmarketTemplates,
  setAutoProspectingInitial,
  settingsToggleMainLoader,
} from "../../../store/actions";
import { Table, Pagination } from "../../../partials/table/table";
import { columns as columnsFn, getMarketNotificationData } from "./helper";
import TableLoader from "../../../utils/tableLoader";
import { errorMessage } from "../../../utils/errorMessage";

import CreateMarket from "../create/MarketCreate";
import SidePanel from "../../../utils/sidePanel";
import SearchField from "../../../utils/searchTable";
import { userTypes } from "../../../constant/userManagement";
import Confirm from "../../../utils/confirmDelete";
import { successMessage } from "../../../utils/successMessage";
import Tooltip from "../../../utils/tooltip";

import MarketDetails from "../view/MarketDetails";
import MarketSlider from "./marketSlider";
import { outboundCommunicationType } from "../../../constant/templateManagement";
import emptyImageMarket from "../../../assets/market.png";

const initialStates = {
  userId: null,
  userType: null,
  marketId: null,
  marketType: null,
  subMarketId: null,
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "marketName",
  sortDirection: "asc",
  searchKey: "",
  forceLoad: 0,

  marketList: null,
  recordsTotal: 0,
  availableMarkets: null,
  showCreateMarketPanel: false,
  createMode: true,
  editMarketData: null,
  deleteMarketData: null,
  marketDetails: null,
  reloadData: false,
  ownerTemplateProspectingEvents: [],
  relatorTemplateProspectingEvents: [],
  templateList: [],
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const MarketListing = ({
  match,
  history,
  userDetails,
  toggleMainLoader,
  getMarkets,
  getMarket,
  deleteMarket,
  resetField,
  getCompanyMarkets,
  getmarketTemplateProspectingEvents,
  getAllmarketTemplates,
  setAutoProspectingInitial,
  settingsToggleMainLoader,
}) => {
  const [state, dispatch] = useReducer(reducer, initialStates);

  const {
    marketList,
    recordsTotal,
    marketId,
    marketType,
    subMarketId,
    pageIndex,
    pageSize,
    sortColumn,
    sortDirection,
    searchKey,
    availableMarkets,
    showCreateMarketPanel,
    createMode,
    editMarketData,
    deleteMarketData,
    marketDetails,
    userType,
    forceLoad,
    reloadData,
    ownerTemplateProspectingEvents,
    relatorTemplateProspectingEvents,
    templateList,
  } = state;

  useEffect(() => {
    dispatch({
      userId: userDetails.userId,
      userType: userDetails.userType,
    });
  }, [userDetails]);

  useEffect(() => {
    dispatch({
      marketType: userType === userTypes.SM ? 2 : 1,
    });
  }, [userType]);

  useEffect(() => {
    const successHandler = (event) => {
      dispatch({
        marketList: event.result.data,
        recordsTotal: event.result.recordsTotal,
      });    };
    const errorHandler = (event) => {
      errorMessage(event);
      toggleMainLoader(false);
    };
    const { params = null } = match;

    userType &&
      marketType &&
      ((params.id && marketId) || !params.id) &&
      getMarkets(
        {
          pageIndex,
          pageSize,
          sortColumn,
          sortDirection,
          searchKey,
          marketId,
          subMarketId,
          marketType,
          userType,
        },
        successHandler,
        errorHandler
      );
  }, [
    pageIndex,
    pageSize,
    sortColumn,
    sortDirection,
    searchKey,
    marketId,
    subMarketId,
    marketType,
    getMarkets,
    userType,
    toggleMainLoader,
    match,
    forceLoad,
  ]) && toggleMainLoader(true)

  useEffect(() => {
    const successHandler = (e) => {
      e && e.result && dispatch({ availableMarkets: e.result });
    };
    const errorHandler = (e) => {
      dispatch({ availableMarkets: [] });
    };
    userDetails &&
      userDetails.userId &&
      userDetails.userType !== userTypes.AD &&
      getCompanyMarkets(
        {
          userType: userDetails.userType,
          companyUserId: userDetails.userId,
        },
        successHandler,
        errorHandler
      );
  }, [getCompanyMarkets, userDetails]);

  useEffect(() => {
    return () => {
      resetField("Market.marketDetails", null);
    };
  }, [resetField]);

  const loggedInAs = useCallback(
    (type) => {
      return userDetails.userType === userTypes[type];
    },
    [userDetails]
  );

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerOwner = (event) => {
        dispatch({
          ownerTemplateProspectingEvents: event.result,
        });
      };

      getmarketTemplateProspectingEvents(
        outboundCommunicationType.homeOwner.value,
        successHandlerOwner
      );
    }
  }, [getmarketTemplateProspectingEvents, loggedInAs]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerRelator = (event) => {
        dispatch({
          relatorTemplateProspectingEvents: event.result,
        });
      };
      getmarketTemplateProspectingEvents(
        outboundCommunicationType.realtor.value,
        successHandlerRelator
      );
    }
  }, [getmarketTemplateProspectingEvents, loggedInAs]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandler = (event) => {
        dispatch({
          templateList: event.result,
        });
      };
      getAllmarketTemplates(successHandler);
    }
  }, [getAllmarketTemplates, loggedInAs]);

  const toggleAddEditUserPanel = (row = null, show = true) => {
    dispatch({ createMode: row ? false : true });
    row &&
      getMarket(
        row.subMarketId
          ? { subMarketId: row.subMarketId }
          : { marketId: row.marketId },
        (e) => {
          dispatch({
            editMarketData: {
              ...e.result,
              ...getMarketNotificationData(e.result),
            },
          });

          setAutoProspectingInitial(getMarketNotificationData(e.result));
        }
      );
    dispatch({ showCreateMarketPanel: show, forceLoad: forceLoad + 1 });
    !show && resetField("Market.marketDetails", null);
    !show && dispatch({ editMarketData: null });
  };

  const onCreate = (event) => {
    successMessage(3001);
    toggleAddEditUserPanel(null, false);
  };
  const onEdit = (event) => {
    successMessage(3002);
    toggleAddEditUserPanel(null, false);
    dispatch({ reloadData: !reloadData });
  };
  const onCancel = (event) => {
    toggleAddEditUserPanel(null, false);
  };
  const onDelete = (event) => {
    dispatch({ deleteMarketData: event });
  };

  const confirmDelete = (event) => {
    settingsToggleMainLoader(true);
    dispatch({ deleteMarketData: null });
    const successHandler = () => {
      settingsToggleMainLoader(false);
      let newPageIndex = {};
      if (pageIndex > 1 && recordsTotal - 1 <= (pageIndex - 1) * pageSize) {
        newPageIndex = { pageIndex: pageIndex - 1 };
      }
      successMessage(3003);
      dispatch({ deleteMarketData: null, ...newPageIndex });
      toggleAddEditUserPanel(null, false);
    };
    const errorHandler = (event) => {
      errorMessage(event);
      settingsToggleMainLoader(false);
    };
    const query =
      userDetails && userDetails.userType === userTypes.AD
        ? {
            marketId: deleteMarketData.marketId
              ? deleteMarketData.marketId
              : null,
          }
        : {
            subMarketId: deleteMarketData.subMarketId
              ? deleteMarketData.subMarketId
              : null,
            subMarketType:
              deleteMarketData.bucketName ||
              deleteMarketData.category === "Bucket"
                ? 2
                : 1,
          };
    deleteMarket(query, successHandler, errorHandler);
  };

  const onGetMarketSuccess = useCallback(
    (e = null) => {
      const result = e && e.result ? e.result : null;
      dispatch({ marketDetails: result ? result : null });
      const newFilters = {
        marketId: result && result.marketId ? result.marketId : null,
        subMarketId: result && result.subMarketId ? result.subMarketId : null,
        marketType: result
          ? result.subMarketId
            ? 2
            : result.marketId
            ? 1
            : 0
          : marketType,
      };
      (!result || !result.subMarketType || result.subMarketType !== 2) &&
        dispatch(newFilters);
    },
    [marketType]
  );

  useEffect(() => {
    const { path = null, params = null } = match;
    if (path && path.startsWith("/markets")) {
      if (path === "/markets/:id/view") {
        getMarket({ marketId: params.id }, onGetMarketSuccess);
      } else if (path === "/markets/sub-markets/:id/view") {
        getMarket({ subMarketId: params.id }, onGetMarketSuccess);
      } else {
        onGetMarketSuccess();
      }
    }
  }, [match, getMarket, onGetMarketSuccess, reloadData]);

  const renderAddUserBtn = () => {
    const show = !marketDetails;
    return show ? (
      <Tooltip
        message={
          loggedInAs("AD")
            ? "Create market and assign owner"
            : loggedInAs("CA")
            ? "Create child market, assign owner and determine outreach"
            : "Create bucket, assign owner and determine outreach"
        }
        component={
          <button
            type="button"
            className="btn btn-primary btn-md"
            onClick={() => {
              dispatch({ showCreateMarketPanel: true });
              setAutoProspectingInitial([]);
            }}
          >
            {(marketDetails && marketDetails.marketId) || loggedInAs("CA")
              ? "Create Child Market"
              : loggedInAs("SM")
              ? "Create Bucket"
              : "Create Market"}
          </button>
        }
      />
    ) : null;
  };

  const selectMarket = (row) => {
    resetField("Market.marketList", null);
    history.push(
      row.subMarketId
        ? `/markets/sub-markets/${row.subMarketId}/view`
        : `/markets/${row.marketId}/view`
    );
  };

  const columns = columnsFn({
    toggleAddEditUserPanel,
    selectMarket,
    onDelete,
    loggedInAs,
    listType: marketDetails ? (marketDetails.subMarketId ? 2 : 1) : 0,
  });

  const onSort = (e) =>
    e.by !== sortColumn || e.order !== sortDirection
      ? dispatch({
          sortColumn: e.by,
          sortDirection: e.order,
          pageIndex: 1,
        })
      : null;

  const emptyComponent = () => {
    return (
      <div className="empty-component">
        <img
          src={emptyImageMarket}
          alt="emptyImageMarket"
          className="empty-component-icon"
        />
        <h3 className="color-1 text-uppercase">Adding markets to moovsoon</h3>
        <p>
          <span className="text-primary">Step 1: </span>Create child market from
          available parent market.
        </p>
        <p>
          <span className="text-primary">Step 2: </span>Assign owner.
        </p>
        <p>
          <span className="text-primary">Step 3: </span>Automatically prospect
          to the home owner or realtor by setting email, sms and postcard
          templates for various events in listing status.
        </p>
      </div>
    );
  };

  const renderTableSection = () =>
    marketDetails && marketDetails.subMarketType === 2 ? null : (
      <>
        <div className="d-flex align-items-center mb-3">
          <h2 className="mr-3 mb-0">
            {(marketDetails &&
              marketDetails.subMarketId &&
              marketDetails.subMarketType === 1) ||
            loggedInAs("SM")
              ? "Buckets"
              : (marketDetails && marketDetails.marketId) || loggedInAs("CA")
              ? "Child Markets"
              : "Markets"}
          </h2>
          <SearchField
            initialValue={searchKey}
            onSearch={(searchKey) => dispatch({ searchKey, pageIndex: 1 })}
            className={`flex-fill mr-3 form-group mb-0`}
            placeholder="Enter your query"
          />
          {renderAddUserBtn()}
        </div>
        {marketList && marketList.length ? (
          <div>
            <Table columns={columns} data={marketList} onSort={onSort} />
            <Pagination
              total={recordsTotal}
              count={pageSize}
              page={pageIndex}
              onChange={(pageIndex) => dispatch({ pageIndex })}
            />
          </div>
        ) : null}
        <TableLoader list={marketList} emptyComponent={emptyComponent} />
      </>
    );
  return (
    <section className="moovsoon-page-container page-market-listing">
      <div className="moovsoon-page-body">
        {marketDetails ? (
          <MarketDetails
            history={history}
            data={marketDetails}
            onEdit={(e) => {
              toggleAddEditUserPanel(e);
              toggleMainLoader(false);
            }}
            loggedInAs={loggedInAs}
            ownerTemplateProspectingEvents={ownerTemplateProspectingEvents}
            relatorTemplateProspectingEvents={relatorTemplateProspectingEvents}
            templateList={templateList}
          />
        ) : null}
        {marketDetails ||
        loggedInAs("AD") ||
        (loggedInAs("CA") && marketType !== 1) ? null : (
          <div className="mb-3">
            {availableMarkets && availableMarkets.length ? (
              <MarketSlider
                data={availableMarkets}
                marketType={marketType}
                loggedInAs={loggedInAs}
              />
            ) : (
              <div className="alert alert-info text-center p-3">
                Fetching available markets/submarkets
              </div>
            )}
          </div>
        )}
        {match &&
          match.params &&
          ((match.params.id && marketDetails) || !match.params.id) &&
          renderTableSection()}
      </div>
      <SidePanel
        show={showCreateMarketPanel}
        onHide={() => toggleAddEditUserPanel(null, false)}
        component={CreateMarket}
        componentProps={{
          loggedInAs,
          createMode,
          onCreate,
          onEdit,
          onCancel,
          initialValues: editMarketData,
          availableMarkets,
          ownerTemplateProspectingEvents,
          relatorTemplateProspectingEvents,
          templateList,
        }}
      />
      <Confirm
        message={`${
          userDetails && userDetails.userType === userTypes.AD
            ? "Deleting this market will delete all other sub-markets and buckets under this market."
            : ""
        } Are you sure you want to delete this ${
          deleteMarketData && deleteMarketData.category === "Bucket"
            ? "bucket"
            : deleteMarketData && deleteMarketData.category === "SubMarket"
            ? "sub-market"
            : "market"
        }?`}
        onYes={confirmDelete}
        onNo={(e) => dispatch({ deleteMarketData: null })}
        show={deleteMarketData ? true : false}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: get(state, "Auth.userDetails", {}),
  };
};

const mapDispatchToProps = {
  getMarkets,
  getMarket,
  toggleMainLoader,
  resetField,
  deleteMarket,
  getCompanyMarkets,
  getmarketTemplateProspectingEvents,
  getAllmarketTemplates,
  setAutoProspectingInitial,
  settingsToggleMainLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketListing);
