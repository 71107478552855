import React, { useEffect, useCallback, useState, useReducer } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { getFormValues, change } from "redux-form";
import queryString from "query-string";

import { Button } from "./../../../utils/uiElements";
import { successMessage } from "../../../utils/successMessage";
import Preloader from "../../../utils/preloader";
import SidePanel from "../../../utils/sidePanel";
import Tooltip from "../../../utils/tooltip";

import { userTypes } from "../../../constant/userManagement";
import { outboundCommunicationType } from "../../../constant/templateManagement";

import { getPropertyNotificationData, updateSettingsHelper } from "./helper";

import TimeLine from "./TimeLine";
import OwnerBlock from "./OwnerBlock";
import RealtorBlock from "./RealtorBlock";
import AutomaticProspectingBlock from "./AutomaticProspectingBlock";
import SettingsBlock from "./SettingsBlock";
import PropertyInfoBlock from "./PropertyInfoBlock";
import EmailPanel from "./EmailPanel";
import MailerPanel from "./MailerPanel";
import TrackConversionPanel from "./TrackConversionPanel";
import SliderBlock from "./SliderBlock";
import SMSPanel from "./SMSPanel";
import CommunicationFeed from "../../realtors/view/communicationFeed";

import {
  getProperty,
  clearProperty,
  updatePropertySettings,
  getmarketTemplateProspectingEvents,
  getAllmarketTemplates,
  getPropertyNotificationSetting,
  savePropertyNotification,
  getPropertySourceList,
  getMoveTypeList,
  updateTrackConversion,
  getPropertyOwnerInfo,
  fetchPropertyOwnerInfo,
  getTemplateCompanyDetails,
  getTrackConversion,
  sendManualProspecting,
  getRealtorCommunicationFeed,
  toggleMainLoader,
  setPersistFilter,
  getMailerSizeOptions,
  setOwnerInfoRequests,
} from "../../../store/actions";

import "../properties.scss";
import { errorMessage } from "../../../utils/errorMessage";

const reducer = (state, action) => {
  return { ...state, ...action };
};

const initialStates = {
  dataLoaded: false,
  propertySettings: {},
  selectedID: null,
  ownerTemplateProspectingEvents: [],
  relatorTemplateProspectingEvents: [],
  templateList: [],
  mailerSizeOptions: [],
  templateSizeId: 1,
};

const PropertyDetails = (props) => {
  const {
    history,
    location,
    match,
    getProperty,
    clearProperty,
    getmarketTemplateProspectingEvents,
    getAllmarketTemplates,
    getPropertyNotificationSetting,
    getPropertySourceList,
    getMoveTypeList,
    getPropertyOwnerInfo,
    getTemplateCompanyDetails,
    getTrackConversion,
    getRealtorCommunicationFeed,
    savePropertyNotification,
    updatePropertySettings,
    updateTrackConversion,
    fetchPropertyOwnerInfo,
    sendManualProspecting,
    trackConversionformValues,
    automaticProspectingformValues,
    emailTemplateformValues,
    mailerTemplateformValues,
    propertyDetails,
    userDetails,
    toggleMainLoader,
    setPersistFilter,
    getMailerSizeOptions,
    setOwnerInfoRequests,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialStates);

  const [trackConversionDisplay, showtrackConversion] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dataReload, setDataReload] = useState(false);
  const [communicationFeedType, setCommunicationFeedType] = useState(false);
  const [trackConversionData, setTrackConversionData] = useState(false);
  const[confirmRequest, setConfirmRequest] = useState(false)
  const[updatePropertyInfo, setUpdatePropertyInfo] = useState(false)

  const [propertyNotificationSetting, setPropertyNotificationSetting] =
    useState({});

  const realtorId = queryString.parse(location.search).realtorId;

  const {
    dataLoaded,
    propertySettings,
    selectedID,
    ownerTemplateProspectingEvents,
    relatorTemplateProspectingEvents,
    templateList,
    propertySourceList,
    moveTypeList,
    propertyOwnerInfo,
    showMailPanel,
    showPostCardPanel,
    showSMSPanel,
    templateCompanyDetails,
    manualProspectingType,
    mailerSizeOptions,
    templateSizeId,
  } = state;

  const loggedInAs = useCallback(
    (type) => {
      return userDetails.userType === userTypes[type];
    },
    [userDetails]
  );
  useEffect(() => {
    clearProperty()
  },[])

  useEffect(() => {
    if (userDetails && userDetails.userId) {
      let { params = null } = match;
      const successHandler = (e) => {
        dispatch({
          propertySettings: {
            markAsFavourite: e.result.markAsFavourite,
            markAsDiscarded: e.result.markAsDiscarded,
            markAsInProgress: e.result.markAsInProgress,
          },
        });
        dispatch({ selectedID: params.id });
      };
      params && params.id && getProperty(params.id, successHandler)
    }
  }, [getProperty, userDetails, dataReload]);
  
  useEffect(()=> {
    return () => {
      clearProperty()
    }
  },[])

  //To get template size options for mailer
  useEffect(() => {
    const successHandler = (event) => {
      event && event.result && dispatch({ mailerSizeOptions: event.result });
    };
    getMailerSizeOptions(successHandler);
  }, [getMailerSizeOptions]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerOwner = (event) => {
        dispatch({
          propertySourceList: event.result,
        });
      };
      getPropertySourceList(successHandlerOwner);
    }
  }, [getPropertySourceList, loggedInAs]);

  useEffect(() => {
    if (selectedID) {
      const successHandlerOwner = (event) => {
        dispatch({
          propertyOwnerInfo: event.result,
        });
        dispatch({ dataLoaded: true });

      };
      getPropertyOwnerInfo(selectedID, successHandlerOwner);
    }
  }, [getPropertyOwnerInfo, loggedInAs, selectedID, updatePropertyInfo]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerOwner = (event) => {
        dispatch({
          moveTypeList: event.result,
        });
      };
      getMoveTypeList(successHandlerOwner);
    }
  }, [getMoveTypeList, loggedInAs]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD") && selectedID) {
      const successHandlerOwner = (event) => {
        setTrackConversionData(event.result);
      };
      getTrackConversion(selectedID, successHandlerOwner);
    }
  }, [getTrackConversion, loggedInAs, selectedID]);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.userId &&
      typeof loggedInAs === "function" &&
      !loggedInAs("AD")
    ) {
      const successHandler = (e) => {
        setPropertyNotificationSetting(e.result);
      };
      selectedID &&
        getPropertyNotificationSetting(
          selectedID,
          successHandler,
          successHandler
        );
    }
  }, [getPropertyNotificationSetting, selectedID, userDetails, loggedInAs]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerOwner = (event) => {
        dispatch({
          ownerTemplateProspectingEvents: event.result,
        });
      };

      getmarketTemplateProspectingEvents(
        outboundCommunicationType.homeOwner.value,
        successHandlerOwner
      );
    }
  }, [getmarketTemplateProspectingEvents, loggedInAs]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandlerRelator = (event) => {
        dispatch({
          relatorTemplateProspectingEvents: event.result,
        });
      };
      getmarketTemplateProspectingEvents(
        outboundCommunicationType.realtor.value,
        successHandlerRelator
      );
    }
  }, [getmarketTemplateProspectingEvents, loggedInAs]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandler = (event) => {
        dispatch({
          templateList: event.result,
        });
      };
      getAllmarketTemplates(successHandler);
    }
  }, [getAllmarketTemplates, loggedInAs]);

  useEffect(() => {
    if (typeof loggedInAs === "function" && !loggedInAs("AD")) {
      const successHandler = (event) => {
        event &&
          event.result &&
          dispatch({ templateCompanyDetails: event.result });
      };
      getTemplateCompanyDetails(successHandler);
    }
  }, [getTemplateCompanyDetails, loggedInAs]);

  const updateSettings = (type) => {
    return updateSettingsHelper(
      type,
      propertySettings,
      selectedID,
      setShowLoader,
      dispatch,
      updatePropertySettings
    );
  };

  const requestOwnerData = () => {
    toggleMainLoader(true);
    const successHandler = (data) => {
      successMessage(7002);
      setConfirmRequest(false)
      toggleMainLoader(false);
      setUpdatePropertyInfo(true)
    };
    const errorHandler = (event) => {
      errorMessage(event);
      setConfirmRequest(false)
      toggleMainLoader(false);
    };

    setOwnerInfoRequests([selectedID], successHandler, errorHandler);
  };

  return (
    <section className="moovsoon-page-container pt-0">
      <Preloader show={showLoader} />
      <div className="moovsoon-page-body">
        <div className="market-details">
          <Button
            type="button"
            value="< Back"
            className="btn btn-default btn-sm mr-2 mb-3"
            onClick={() => {
              
              clearProperty()
              setPersistFilter(true, () => {
                dispatch({ dataLoaded: false });
                realtorId
                  ? history.push(`/listings?realtorId=${realtorId}`)
                  : history.push(`/listings`);
                  
              });
            }}
          />
          {propertyDetails && dataLoaded ? (
            <>
              <div className="card card-primary mb-3 h-100">
                <div className="d-flex flex-row align-items-center">
                  <div className="w-75 p-3">
                    <TimeLine
                      propertyDetails={propertyDetails}
                      dataLoaded={dataLoaded}
                    />
                  </div>
                  {typeof loggedInAs === "function" &&
                  !loggedInAs("AD") &&
                  trackConversionData ? (
                    <div className="w-25 d-flex align-items-end flex-column mr-2">
                      <Tooltip
                        message="Update your lead conversion status"
                        component={
                          <button
                            type="button"
                            className="btn btn-default btn-sm m-3"
                            onClick={() => showtrackConversion(true)}
                          >
                            Track Conversion
                          </button>
                        }
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <SliderBlock propertyDetails={propertyDetails} />

                  <div className="col-5 pl-0">
                    <div className="d-flex flex-column">
                      {typeof loggedInAs === "function" && !loggedInAs("AD") ? (
                        <div className="d-flex flex-row bb-1 pt-0 mb-4 h-48">
                          <SettingsBlock
                            {...{
                              propertySettings,
                              updateSettings,
                            }}
                          />

                          <AutomaticProspectingBlock
                            {...{
                              initialValues: getPropertyNotificationData(
                                propertyNotificationSetting
                              ),
                              propertyNotificationSetting,
                              loggedInAs,
                              ownerTemplateProspectingEvents,
                              relatorTemplateProspectingEvents,
                              templateList,
                              formValues: automaticProspectingformValues,
                              userDetails,
                              selectedID,
                              getPropertyNotificationSetting,
                              savePropertyNotification,
                              setShowLoader,
                              successMessage,
                              setPropertyNotificationSetting,
                            }}
                          />
                        </div>
                      ) : null}

                      <PropertyInfoBlock
                        {...{
                          propertyDetails,
                          updateSettings,
                          loggedInAs,
                          propertySettings,
                          propertyOwnerInfo,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card-primary p-4 h-100 bt-2">
                <div className="row d-flex flex-row">
                  <div className="w-50 relator-block">
                    {propertyDetails ? (
                      <RealtorBlock
                        {...{
                          propertyDetails,
                          dispatch,
                          setShowLoader,
                          loggedInAs,
                          setCommunicationFeedType,
                          history,
                          match,
                        }}
                      />
                    ) : (
                      <div className="alert alert-info p-3 text-center">
                        No property details available
                      </div>
                    )}
                  </div>

                  <div className="w-50 pl-2">
                    {propertyOwnerInfo ? (
                      <OwnerBlock
                        {...{
                          selectedID,
                          propertyOwnerInfo,
                          fetchPropertyOwnerInfo,
                          dispatch,
                          setShowLoader,
                          loggedInAs,
                          setCommunicationFeedType,
                          setOwnerInfoRequests,
                          requestOwnerData,
                          confirmRequest,
                          setConfirmRequest
                        }}
                      />
                    ) : (
                      <div className="alert alert-info p-3 text-center">
                        Loading Data
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {communicationFeedType === 1 || communicationFeedType === 2 ? (
                <div className="mt-3">
                  <CommunicationFeed
                    getCommunicationFeed={getRealtorCommunicationFeed}
                    realtor={{
                      realtorId: propertyDetails.realtorId,
                    }}
                    propertyOwnerId={
                      propertyOwnerInfo && propertyOwnerInfo.ownerInfo
                        ? propertyOwnerInfo.ownerInfo.propertyOwnerId
                        : null
                    }
                    isPropertyPage={1}
                    communicationFeedType={communicationFeedType}
                    propertyId={selectedID}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <div className="mt-3 alert alert-info p-2 text-center">Loading</div>
          )}
        </div>
      </div>
      {propertyDetails && dataLoaded ? (
        <>
          <SidePanel
            show={showMailPanel}
            onHide={() => dispatch({ showMailPanel: false })}
            component={EmailPanel}
            enforceFocus={false}
            className="create-template-panel create-template-panel-sm"
            componentProps={{
              setShowLoader,
              dispatch,
              selectedID,
              data: templateCompanyDetails,
              formValues: emailTemplateformValues,
              change,
              sendManualProspecting,
              propertyDetails,
              propertyOwnerInfo,
              manualProspectingType,
              toggleMainLoader,
            }}
          />

          <SidePanel
            show={showPostCardPanel}
            onHide={() => dispatch({ showPostCardPanel: false })}
            component={MailerPanel}
            enforceFocus={false}
            className="create-template-panel create-template-panel-mm"
            componentProps={{
              setShowLoader,
              dispatch,
              selectedID,
              data: templateCompanyDetails,
              formValues: mailerTemplateformValues,
              change,
              sendManualProspecting,
              propertyDetails,
              propertyOwnerInfo,
              manualProspectingType,
              toggleMainLoader,
              mailerSizeOptions,
              templateSizeId,
            }}
          />

          <SidePanel
            show={showSMSPanel}
            onHide={() => dispatch({ showSMSPanel: false })}
            component={SMSPanel}
            className="create-template-panel create-template-panel-sm"
            componentProps={{
              setShowLoader,
              dispatch,
              selectedID,
              sendManualProspecting,
              propertyDetails,
              propertyOwnerInfo,
              manualProspectingType,
              toggleMainLoader,
            }}
          />

          <SidePanel
            show={trackConversionDisplay}
            onHide={() => showtrackConversion(false)}
            component={TrackConversionPanel}
            componentProps={{
              setShowLoader,
              successMessage,
              initialValues: trackConversionData,
              showtrackConversion,
              setTrackConversionData,
              formValues: trackConversionformValues,
              loggedInAs,
              selectedID,
              propertySourceList,
              moveTypeList,
              updateTrackConversion,
              dataReload,
              setDataReload,
            }}
          />
        </>
      ) : null}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    propertyDetails: get(state, "Property.propertyDetails", null),
    userDetails: get(state, "Auth.userDetails", {}),
    automaticProspectingformValues: getFormValues(
      "PropertyautomaticProspectingForm"
    )(state),
    emailTemplateformValues: getFormValues("emailTemplateForm")(state),
    mailerTemplateformValues: getFormValues("mailerTemplateForm")(state),
    trackConversionformValues: getFormValues("trackConversionForm")(state),
  };
};

const mapDispatchToProps = {
  getProperty,
  clearProperty,
  updatePropertySettings,
  getmarketTemplateProspectingEvents,
  getAllmarketTemplates,
  getPropertyNotificationSetting,
  savePropertyNotification,
  getPropertySourceList,
  getMoveTypeList,
  updateTrackConversion,
  getPropertyOwnerInfo,
  fetchPropertyOwnerInfo,
  getTemplateCompanyDetails,
  getTrackConversion,
  getRealtorCommunicationFeed,
  sendManualProspecting,
  toggleMainLoader,
  setPersistFilter,
  getMailerSizeOptions,
  setOwnerInfoRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
