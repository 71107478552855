export const propertyStatus = {
	new: {
		value: 1,
		label: "New",
	},
	pending: {
		value: 2,
		label: "Pending",
	},
	active: {
		value: 3,
		label: "Active",
	},
	offmarket: {
		value: 4,
		label: "Off Market",
	},
};
