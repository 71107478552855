import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Link } from "react-router-dom";

import {
	sendResetPasswordCredentials,
	updateLoginCredentials,
	toggleMainLoader,
} from "../../store/actions";

import FormGroup from "../../utils/formGroup";
import { required, passwordStrength } from "../../constant/formValidators";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";

const ResetPassword = ({
	set,
	handleSubmit,
	formValues,
	sendResetPasswordCredentials,
	match,
	history,
	toggleMainLoader,
}) => {
	const [error, setError] = useState(null);
	const submitForm = (values) => {
		toggleMainLoader(true);
		const token = match && match.params ? match.params.token : null;
		const { password, confirmPassword } = values;
		const successHandler = () => {
			successMessage(1002);
			history.push("/signin");
			toggleMainLoader(false);
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};
		const notMatching = () => {
			errorMessage("PASSWORD_NOT_MATCH");
			toggleMainLoader(false);
		};
		password && confirmPassword && password === confirmPassword && token
			? sendResetPasswordCredentials(
					{ ...values, newPassword: values.password, token },
					successHandler,
					errorHandler
			  )
			: notMatching();
	};

	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container">
				<form
					className="reset-password-form"
					onSubmit={handleSubmit(submitForm)}>
					<h4 className="mb-3">{set ? "Set" : "Reset"} Password</h4>
					<Field
						name="password"
						label="Password"
						type="password"
						component={FormGroup}
						onChange={() => setError(null)}
						validate={[required, passwordStrength]}
						info="Password should contain a number, a special character, an upper & lower case alphabet and should be minimum 8 characters long. Blank space is not accepted"
					/>
					<Field
						name="confirmPassword"
						showPassword={false}
						matchWith={{ formValues, field: "password" }}
						onChange={() => setError(null)}
						label="Confirm Password"
						type="password"
						component={FormGroup}
						validate={[required]}
					/>
					<div className="d-flex flex-row-reverse align-items-center mt-4">
						<button className="btn btn-primary" type="submit">
							Submit
						</button>
						<Link className="mr-3" to="/signin">
							Login
						</Link>
					</div>
					{error && (
						<div className="error small text-danger text-center mt-3">
							{error}
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	formValues: getFormValues("ResetPasswordForm")(state),
});

const mapDispatchToProps = {
	sendResetPasswordCredentials,
	updateLoginCredentials,
	toggleMainLoader,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "ResetPasswordForm",
	})(ResetPassword)
);
