import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";

import Preloader from "../../utils/preloader";

import { getPerformanceHistory } from "../../store/actions";

const initialStates = {
	timeframe: 1,
	performanceHistoryData: null,
	performanceHistoryIcons: {
		booked: require("../../assets/dashboard/booked.png"),
		customers: require("../../assets/dashboard/customers.png"),
		listings: require("../../assets/dashboard/listings.png"),
		oppurtunity: require("../../assets/dashboard/oppurtunity.png"),
		process: require("../../assets/dashboard/process.png"),
		zip: require("../../assets/dashboard/zip.png"),
	},
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const PerformanceHistory = ({ getPerformanceHistory, loggedInAs }) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const {
		timeframe,
		performanceHistoryData,
		performanceHistoryIcons,
	} = state;

	useEffect(() => {
		const successHandler = (e) => {
			dispatch({ performanceHistoryData: e.result });
		};
		timeframe && getPerformanceHistory({ timeframe }, successHandler);
	}, [getPerformanceHistory, timeframe]);

	const renderTabBtns = () => {
		const timeframeList = [
			{
				value: 1,
				label: "R12",
			},
			{
				value: 2,
				label: "QTD",
			},
			{
				value: 3,
				label: "MTD",
			},
		];

		return (
			<div className="tab-btns">
				{timeframeList.map(({ label, value }) => (
					<div
						key={value}
						className={`tab-btn ${
							timeframe === value ? "active" : ""
						}`}
						onClick={() =>
							timeframe !== value &&
							dispatch({
								timeframe: value,
								performanceHistoryData: null,
							})
						}
					>
						{label}
					</div>
				))}
			</div>
		);
	};

	const renderPerformanceHistory = () => {
		const {
			customers,
			inProcess,
			opportunities,
			totalListings,
			zipcodes,
			booked,
		} = performanceHistoryData;

		const list = [
			{
				label: "Customers",
				value: customers,
				icon: performanceHistoryIcons.customers,
				hide: !loggedInAs("AD"),
			},
			{
				label: "Zip Codes",
				value: zipcodes,
				icon: performanceHistoryIcons.zip,
				hide: !loggedInAs("AD"),
			},
			{
				label: "Total Listings",
				value: totalListings,
				icon: performanceHistoryIcons.listings,
			},
			{
				label: "In Process",
				value: inProcess,
				icon: performanceHistoryIcons.process,
			},
			{
				label: "Opportunities",
				value: opportunities,
				icon: performanceHistoryIcons.oppurtunity,
			},
			{
				label: "Booked",
				value: booked,
				icon: performanceHistoryIcons.booked,
				hide: loggedInAs("SA"),
			},
		];
		return (
			<div className="d-flex performace-history-pills">
				{list.map(
					({ label, value, icon, hide }, index) =>
						!hide && (
							<div
								key={index}
								className="d-flex align-items-center performace-history-pill"
							>
								<div className="performace-history-pill-content">
									<h2 className="mb-0">{value}</h2>
									<div className="text-sm">{label}</div>
								</div>
								<div className="performace-history-pill-icon">
									<img src={icon} alt={label} />
								</div>
							</div>
						)
				)}
			</div>
		);
	};

	return (
		<div className="card card-primary p-4 mb-3">
			<div className="d-flex mb-3">
				<h2 className="mr-3 mb-0 flex-fill">Performance History</h2>
				{renderTabBtns()}
			</div>
			{performanceHistoryData ? (
				renderPerformanceHistory()
			) : (
				<div className="performance-history-loader">
					<Preloader show={true} className="inner" />
				</div>
			)}
		</div>
	);
};

const mapDispatchToProps = {
	getPerformanceHistory,
};

export default connect(null, mapDispatchToProps)(PerformanceHistory);
