import fetchHandler from "../../utils/fetchHandler";
import { serialize } from "../../utils/helper";
import { REALTOR } from "../types";

export const getRealtors = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `realtors/get-realtorlist-with-pagination`,
		method: "POST",
		secure: true,
		actionType: REALTOR.GET_REALTORS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getRealtor = (realtorId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `realtors/get-realtor?realtorId=${realtorId}`,
		method: "GET",
		secure: true,
		actionType: REALTOR.GET_REALTOR_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getRealtorListingCoverage = (
	values,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `realtors/get-realtor-listing-coverage?${serialize(values)}`,
		method: "GET",
		secure: true,
		actionType: REALTOR.GET_REALTOR_LISTING_COVERAGE,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getRealtorMarketList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `realtors/get-realtor-market-submarket-bucket-list?${serialize(
			values
		)}`,
		method: "GET",
		secure: true,
		actionType: REALTOR.GET_REALTOR_MARKET_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export function setFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: REALTOR.SET_FILTER,
		payload: filter,
	};
}

export const getRealtorEditData = (realtorId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `realtors/get-realtor-basic-details?realtorId=${realtorId}`,
		method: "GET",
		secure: true,
		actionType: REALTOR.GET_REALTOR_EDIT_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateRealtorData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `realtors/update-realtor-basic-details`,
		method: "POST",
		secure: true,
		actionType: REALTOR.UPDATE_REALTOR_DATA,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
