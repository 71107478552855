import React from "react";
import { Field, reduxForm } from "redux-form";
import FormGroup from "./formGroup";
import { ButtonDefault, ButtonPrimary } from "./uiElements";

const FilterForm = (props) => {
	const { onApplyFilter, onCancelFilter, filtersArray, handleSubmit } = props;

	const submitForm = (values = null) => {
		onApplyFilter(values);
	};

	return (
		<div>
			<form
				className="create-user-form"
				onSubmit={handleSubmit(submitForm)}>
				<div className="panel-switch  active">
					<h1 className="mb-4">Filter</h1>
					<div className="form-container">
						<div className="row">
							{filtersArray.map((item, index) => (
								<Field
									key={index}
									{...item}
									component={FormGroup}
								/>
							))}
						</div>
					</div>
					<div className="form-footer">
						<ButtonDefault
							type="button"
							onClick={onCancelFilter}
							value="Cancel"
						/>
						<ButtonPrimary type="submit" value="Submit" />
					</div>
				</div>
			</form>
		</div>
	);
};
export default reduxForm({
	form: "FilterForm",
	enableReinitialize: true,
})(FilterForm);
