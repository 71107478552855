import moment from "moment";

export const getUtcDateFromDatePicker = (value) => {
	if (value == null) return null;
	const formatted = moment(value, "YYYY-MM-DDTHH:mm").format();
	return formatted;
};

export const getDatePickerDateFromtUtcDate = (value) => {
	if (value == null) return null;
	return new Date(value);
};

export const serialize = (obj) => {
	var str = [];
	for (var p in obj)
		if (obj.hasOwnProperty(p)) {
			obj[p] !== null &&
				str.push(
					encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
				);
		}
	return str.join("&");
};

export const colors = [
	"#529AC8",
	"#72B874",
	"#F8C600",
	"#3BACA1",
	"#EC8154",
	"#907CBD",
	"#CC405E",
	"#30559B",
	"#7CA400",
	"#4CC6D5",
	"#EC9795",
	"#527381",
	"#C4504E",
	"#39A35E",
];
