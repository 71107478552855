import React, { useState } from "react";
import SMSTemplate from "../../../utils/manualProspecting/smsTemplate";
import SMSTemplateFromList from "../../../utils/manualProspecting/smsTemplateFromList";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

const SMSPanel = (props) => {
  const {
    selectedID,
    sendManualProspecting,
    propertyDetails,
    propertyOwnerInfo,
    dispatch,
    manualProspectingType,
    toggleMainLoader,
  } = props;

  const onSubmit = (values) => {
    toggleMainLoader(true);
    const postData = {
      templateType: "sms",
      prospectingSource: 1,
      propertyId: selectedID,
      prospectingType: manualProspectingType,
      notificationTitle: "",
      notificationData: values.templateSMSBody,
      prospectingUserId:
        manualProspectingType === 1
          ? propertyDetails.realtorId
          : propertyOwnerInfo.ownerInfo.propertyOwnerId,
    };
    sendManualProspecting(
      postData,
      () => {
        successMessage(6007);
        toggleMainLoader(false);
        dispatch({ showSMSPanel: false });
      },
      (data) => {
        toggleMainLoader(false);
        dispatch({ showSMSPanel: false });
        errorMessage(data.error.code);
      }
    );
  };

  const [prospectingType, setProspectingType] = useState(false);

  return prospectingType === 1 ? (
    <SMSTemplate
      {...{
        onSubmit,
        parentDispatch: dispatch,
		propertyDetails,
      }}
    />
  ) : prospectingType === 2 ? (
    <SMSTemplateFromList
      {...{
        onSubmit,
        parentDispatch: dispatch,
        prospectingSource: 1,
        propertyId: selectedID,
        prospectingType: manualProspectingType,
		propertyDetails,
        prospectingUserId:
          manualProspectingType === 1
            ? propertyDetails.realtorId
            : propertyOwnerInfo.ownerInfo.propertyOwnerId,
      }}
    />
  ) : (
    <div className="create-template-wrap">
      <div className="d-flex align-items-center justify-content-between px-4 py-3 bb-1">
        <div className="d-flex flex-row align-items-center">
          <h2 className="color-1 m-0">Compose SMS</h2>
          <h3 className="color-1 m-0 flex-fill ml-2">-</h3>
          <h3 className="color-1 m-0 flex-fill ml-1">
            {propertyDetails?.address}
          </h3>
        </div>
        <button
          type="button"
          className="btn btn-default btn-sm"
          onClick={() =>
            dispatch({
              showSMSPanel: false,
            })
          }
        >
          Close
        </button>
      </div>
      <div className="row manual-option-wrap mr-2 ml-2">
        <div className="col">What would you like to do ?</div>
      </div>
      <div className="row manual-option-wrap mr-2 ml-2">
        <div
          className="col md-6 manual-option-wrap-option text-center ml-3 hand"
          onClick={() => setProspectingType(1)}
        >
          Compose Now
        </div>
        <div
          className="col md-6 manual-option-wrap-option text-center hand"
          onClick={() => setProspectingType(2)}
        >
          Select From Templates
        </div>
      </div>
    </div>
  );
};
export default SMSPanel;
