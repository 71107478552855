import React from "react";
import { getUserTypeName } from "../../../constant/userManagement";
import { userTypes } from "../../../constant/userManagement";
import Tooltip from "../../../utils/tooltip";

export const columns = ({
    onSelectUser,
    toggleAddEditUserPanel = null,
    onDeleteUser = null,
    userDetails = null,
    detailsView = false,
    listOf = null,
}) => [
    {
        name: "User",
        accessor: "fullName",
        className: "col-user-id",
        headerClassName: "col-user-id-header",
        render: (row) => (
            <div
                className="text-primary pointer ellipsis"
                onClick={() => onSelectUser(row)}
            >
                {row.fullName}
            </div>
        ),
    },
    {
        name: "Role",
        className: "col-role",
        sortable:
            userDetails &&
            userDetails.userType !== userTypes.AD &&
            userDetails.userType !== userTypes.CA,
        headerClassName: "col-role-header",
        hide:
            userDetails &&
            ((userDetails.userType === userTypes.AD && !listOf) ||
                userDetails.userType === userTypes.SM),
        render: (row) => {
            return <div>{getUserTypeName(row.userType)}</div>;
        },
    },
    {
        name: "Company",
        accessor: "companyName",
        className: "col-company",
        sortable: userDetails && userDetails.userType === userTypes.AD,
        hide:
            (detailsView && userDetails.userType === userTypes.AD) ||
            (userDetails &&
                (userDetails.userType === userTypes.CA ||
                    userDetails.userType === userTypes.SM)),
        headerClassName: "col-company-header",
    },
    {
        name: "Market",
        accessor: "market",
        className: "col-market",
        headerClassName: "col-market-header",
        sortable: false,
    },
    {
        name: "Email ID",
        accessor: "emailAddress",
        className: "col-email-address",
        headerClassName: "col-email-address-header",
    },
    {
        name: "Status",
        className: "col-status",
        headerClassName: "col-status-header",
        sortable: false,
        render: (row) => {
            return (
                <div
                    className={`badge badge-${
                        row.isActive ? "success" : "secondary"
                    }`}
                >
                    {row.isActive ? "Active" : "Inactive"}
                </div>
            );
        },
    },
    {
        name: "Actions",
        className: "col-actions text-right",
        headerClassName: "col-actions-header",
        sortable: false,
        hide:
            userDetails.userType === userTypes.SM ||
            !onDeleteUser ||
            !toggleAddEditUserPanel,
        render: (row) => {
            return (
                <div>
                    <Tooltip
                        message="Edit"
                        component={
                            <button
                                type="button"
                                className="btn btn-cell btn-default"
                                onClick={() => toggleAddEditUserPanel(row)}
                            >
                                <i className="icon icon-edit" />
                            </button>
                        }
                    />
                    <Tooltip
                        message="Delete"
                        component={
                            <button
                                type="button"
                                className="btn btn-cell btn-default ml-2"
                                onClick={() => onDeleteUser(row)}
                            >
                                <i className="icon icon-trash" />
                            </button>
                        }
                    />
                </div>
            );
        },
    },
];
