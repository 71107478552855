import { DASHBOARD } from "../types";

export default (state = {}, action) => {
	switch (action.type) {
		case DASHBOARD.GET_MARKET_DETAILS:
			return {
				...state,
				marketDetails: action.payload ? action.payload.result : {},
			};
		default:
			return state;
	}
};
