import React from "react";
import { dateFormat } from "../constant/global";

var currencyFormatter = require("currency-formatter");
var moment = require("moment");

export const Currency = ({ amount = 0, currency = "USD" }) => {
  return (
    <span>
      {currencyFormatter.format(Math.trunc(amount), {
        code: currency,
        precision: 0,
      })}
    </span>
  );
};

export const Date = ({ utcTime = moment.utc(), format = dateFormat }) => {
  return (
    <span>
      {moment(utcTime).isValid() ? moment(utcTime).format(format) : ""}
    </span>
  );
};
