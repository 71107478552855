import React from "react";
import { Field } from "redux-form";
import { get } from "lodash";

import { templateTypeOptions } from "../constant/templateManagement";
// import TemplatePrview from "./templatePreview";
import FormGroup from "./formGroup";

const TemplatePanelBlock = (state) => {
  const {
    templateList,
    item,
    outboundType,
    initialValues,
    createMode,
    property,
    componentType,
  } = state;

  //Check if there is any tempate is mapped with the prospecting Event Type
  //If the templateType argument is missing it will check regardless of the template type
  const ifExistCombination = (prospectingEventTypeId, templateType, outboundType) => {
    return templateList.filter((item) => {

	  if (componentType === "homeowner" && (outboundType === "emailTemplate" || outboundType === "smsTemplate") ) {
		return false
	  }

      if (typeof templateType === "undefined")
        return item.prospectingEventTypeId === prospectingEventTypeId;
      else
        return (
          item.prospectingEventTypeId === prospectingEventTypeId &&
          item.templateType === templateType
        );
    }).length;
  };

  //Mapper function to create a value/label object array for the select drop down
  const getOptionValues = (prospectingEventTypeId, templateType) => {
    const data = templateList
      .filter((item) => {
        return (
          item.prospectingEventTypeId === prospectingEventTypeId &&
          item.templateType === templateType
        );
      })
      .map((value, key) => {
        return {
          value: value.templateId,
          label: value.templateName,
          templateThumbnailUrl: value ? value.templateThumbnailUrl : null,
        };
      });
    return [{ value: "", label: "Select" }, ...data];
  };

  //Function to identify the selected option, Key will be any of (emailTemplateId/smsTemplateId/mailerTemplateId)
  const getSelectedValue = (notificationEventTypeId, key, filendName) => {
    if (
      typeof initialValues[filendName] !== "undefined" &&
      typeof initialValues[filendName]["value"] !== "undefined"
    ) {
      const selectedObject = templateList.filter(
        (item) => item.templateId === initialValues[filendName]["value"]
      )[0];

      return selectedObject && selectedObject.templateName
        ? {
            label: selectedObject.templateName,
            value: selectedObject.templateId,
            templateThumbnailUrl: selectedObject.templateThumbnailUrl,
          }
        : null;
    } else {
      const keyName = property
        ? "companyPropertyNotificationSettings"
        : "subMarketNotificationSettings";
      if (
        typeof initialValues === "undefined" ||
        initialValues === null ||
        typeof initialValues[keyName] === "undefined"
      )
        return null;

      const selected = initialValues[keyName].filter((item) => {
        return item.outboundProspectingType === outboundType;
      })[0];
      if (typeof selected === "undefined") return null;
      const notificationEvent = selected.templateMappings.filter(
        (item) => item.notificationEventTypeId === notificationEventTypeId
      )[0];
      if (typeof notificationEvent === "undefined") return null;
      const templateId = get(notificationEvent, key, null);

      const selectedObject = templateList.filter(
        (item) => item.templateId === templateId
      )[0];

      return selectedObject && selectedObject.templateName
        ? {
            label: selectedObject.templateName,
            value: selectedObject.templateId,
            templateThumbnailUrl: selectedObject.templateThumbnailUrl,
          }
        : null;
    }
  };

  //usage for view page
  const getValueBlock = (notificationEventTypeId, key) => {
    const currentItem = getSelectedValue(notificationEventTypeId, key);

    return currentItem === null ? (
      "None"
    ) : currentItem.templateThumbnailUrl !== "" ? (
      <span className="text-primary">{currentItem.label}</span>
    ) : (
      currentItem.label
    );
  };

  return (
    <>
      {ifExistCombination(item.value) ? (
        <div className="card card-secondary p-3 h-100 mb-3">
          <div className="d-block align-items-center">
            <h3 className="text-gray text-uppercase flex-fill mr-2 mb-2">
              {item.label}
            </h3>

            {createMode ? (
              <div>
                {ifExistCombination(
                  item.value,
                  templateTypeOptions.email.value,
                  `emailTemplate`
                ) ? (
                  <Field
                    name={`select${outboundType}emailTemplate${item.value}`}
                    label="Email Template"
                    type="select"
                    options={getOptionValues(
                      item.value,
                      templateTypeOptions.email.value
                    )}
                    component={FormGroup}
                    formGroupClass="col-12 p-0"
                    defaultValue={getSelectedValue(
                      item.value,
                      "emailTemplateId",
                      `select${outboundType}emailTemplate${item.value}`
                    )}
                  />
                ) : null}
                {ifExistCombination(
                  item.value,
                  templateTypeOptions.sms.value,
                  `smsTemplate`
                ) ? (
                  <Field
                    name={`select${outboundType}smsTemplate${item.value}`}
                    label="SMS Template"
                    type="select"
                    options={getOptionValues(
                      item.value,
                      templateTypeOptions.sms.value
                    )}
                    component={FormGroup}
                    formGroupClass="col-12 p-0"
                    defaultValue={getSelectedValue(
                      item.value,
                      "smsTemplateId",
                      `select${outboundType}smsTemplate${item.value}`
                    )}
                  />
                ) : null}
                {ifExistCombination(
                  item.value,
                  templateTypeOptions.mailer.value
                ) ? (
                  <Field
                    name={`select${outboundType}mailerTemplate${item.value}`}
                    label="Mailer Template"
                    type="select"
                    options={getOptionValues(
                      item.value,
                      templateTypeOptions.mailer.value
                    )}
                    component={FormGroup}
                    formGroupClass="col-12 p-0"
                    defaultValue={getSelectedValue(
                      item.value,
                      "mailerTemplateId",
                      `select${outboundType}mailerTemplate${item.value}`
                    )}
                  />
                ) : null}
              </div>
            ) : (
              <div className="row w-100">
                <div className=" col-4">
                  <span className="text-gray template-label">Email</span>
                  <div>{getValueBlock(item.value, "emailTemplateId")}</div>
                </div>
                <div className="col-4">
                  <span className="text-gray template-label">SMS</span>
                  <div>{getValueBlock(item.value, "smsTemplateId")}</div>
                </div>
                <div className="col-4">
                  <span className="text-gray template-label">Mailer</span>
                  <div>{getValueBlock(item.value, "mailerTemplateId")}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TemplatePanelBlock;
