import React, { useState, useEffect } from "react";
import "./table.scss";

const classPrefix = "moovsoon";

export const Table = ({
	columns,
	data,
	onSort = null,
	sortBy = null,
	sortDir = "asc",
}) => {
	const [sort, setSort] = useState({
		by: sortBy,
		order: sortDir,
	});

	useEffect(() => {
		sort && sort.by && sort.order && onSort && onSort(sort);
	}, [sort, onSort]);

	const renderTableColumnGroup = () => {
		return (
			<div className={`${classPrefix}-table-col-group`}>
				{columns.map((column, columnIndex) =>
					column.hide ? null : (
						<div
							key={columnIndex}
							className={`${classPrefix}-table-col ${
								column.className ? column.className : ""
							}`}
						/>
					)
				)}
			</div>
		);
	};

	const toggleSort = (event) => {
		if (event.accessor === sort.by) {
			setSort({ ...sort, order: sort.order === "asc" ? "desc" : "asc" });
		} else {
			setSort({
				by: event.sortField || event.accessor,
				order: "asc",
			});
		}
	};

	const renderSortIcon = (column) => {
		const accessor = column.accessor;
		if (accessor === sort.by) {
			return <i className={`icon icon-sort-${sort.order}`} />;
		}
		return <i className="icon icon-sort" />;
	};

	const renderTableHead = () => {
		return (
			<div className={`${classPrefix}-table-thead`}>
				<div className={`${classPrefix}-table-tr`}>
					{columns.map((column, columnIndex) => {
						const columnItem = { sortable: true, ...column };
						return column.hide ? null : (
							<div
								key={columnIndex}
								className={`${classPrefix}-table-th ${
									columnItem.className
										? columnItem.className
										: ""
								} ${
									columnItem.headerClassName
										? columnItem.headerClassName
										: ""
								} ${columnItem.sortable ? "sortable" : ""}`}
								onClick={() =>
									!columnItem.sortable
										? null
										: toggleSort(columnItem)
								}>
								{renderSortIcon(columnItem)} {columnItem.name}
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const renderTableBody = () => {
		return (
			<div className={`${classPrefix}-table-tbody`}>
				{data.map((row, rowIndex) => (
					<div key={rowIndex} className={`${classPrefix}-table-tr`}>
						{columns.map((column, columnIndex) =>
							column.hide ? null : (
								<div
									key={columnIndex}
									className={`${classPrefix}-table-td ${
										column.className ? column.className : ""
									}`}>
									{column.render ? (
										column.render(
											row,
											column,
											rowIndex,
											columnIndex
										)
									) : row[column.accessor] ||
									  row[column.accessor] === 0 ? (
										row[column.accessor]
									) : (
										<span
											className={`${classPrefix}-table-td-placeholder`}
										/>
									)}
								</div>
							)
						)}
					</div>
				))}
			</div>
		);
	};

	return (
		<div className={`${classPrefix}-table`}>
			{columns && columns.length ? renderTableColumnGroup() : null}
			{columns && columns.length ? renderTableHead() : null}
			{data && data.length ? renderTableBody() : null}
		</div>
	);
};

export const Pagination = ({ total, count, page, onChange }) => {
	const maxPages = Math.ceil(total / count);
	const currentPage = page > maxPages ? maxPages : page;
	const startItem = (currentPage - 1) * count + 1;
	const maxEndItem = currentPage * count;
	const endItem = maxEndItem > total ? total : maxEndItem;
	const pagesArray = [...Array(maxPages).keys()];
	const lastPage = Number(...pagesArray.slice(-1)) + 1;
	const pagesList = [1];
	const fnExists = (value) => pagesList.includes(value);
	const fnPush = (value) => pagesList.push(value);
	if (currentPage === lastPage - 1) {
		currentPage - 2 < maxPages &&
			!fnExists(currentPage - 2) &&
			currentPage - 2 > 0 &&
			fnPush(currentPage - 2);
		currentPage - 1 < maxPages &&
			!fnExists(currentPage - 1) &&
			currentPage - 1 > 0 &&
			fnPush(currentPage - 1);
	}
	if (currentPage === lastPage) {
		currentPage - 3 < maxPages &&
			!fnExists(currentPage - 3) &&
			currentPage - 3 > 0 &&
			fnPush(currentPage - 3);
		currentPage - 2 < maxPages &&
			!fnExists(currentPage - 2) &&
			currentPage - 2 > 0 &&
			fnPush(currentPage - 2);
		currentPage - 1 < maxPages &&
			!fnExists(currentPage - 1) &&
			currentPage - 1 > 0 &&
			fnPush(currentPage - 1);
	}
	!fnExists(currentPage - 1) &&
		currentPage - 1 > 0 &&
		fnPush(currentPage - 1);
	!fnExists(currentPage) && fnPush(currentPage);
	!fnExists(currentPage + 1) &&
		currentPage + 1 < lastPage &&
		fnPush(currentPage + 1);
	if (currentPage === 1) {
		currentPage + 2 < maxPages &&
			!fnExists(currentPage + 2) &&
			fnPush(currentPage + 2);
		currentPage + 3 < maxPages &&
			!fnExists(currentPage + 3) &&
			fnPush(currentPage + 3);
	}
	if (currentPage === 2) {
		currentPage + 1 < maxPages &&
			!fnExists(currentPage + 1) &&
			fnPush(currentPage + 1);
		currentPage + 2 < maxPages &&
			!fnExists(currentPage + 2) &&
			fnPush(currentPage + 2);
	}
	!fnExists(lastPage) && fnPush(lastPage);

	return maxPages > 1 ? (
		<div className={`${classPrefix}-pagination`}>
			<div className={`${classPrefix}-pagination-one`}>
				Page {currentPage} of {maxPages} pages.
			</div>
			<div className={`${classPrefix}-pagination-two`}>
				Showing {startItem} to {endItem} of {total}.
			</div>
			<div className={`${classPrefix}-pagination-three`}>
				<div
					className={`pagination-btn pagination-prev ${
						currentPage > 1 ? "" : "disabled"
					}`}
					onClick={() => onChange(currentPage - 1)}>
					&lt;
				</div>
				{pagesList.map((page) => (
					<div
						key={page}
						className={`pagination-btn ${
							currentPage === page ? "current" : ""
						}`}
						onClick={() => onChange(page)}>
						{page}
					</div>
				))}
				<div
					className={`pagination-btn pagination-next ${
						currentPage >= maxPages ? "disabled" : ""
					}`}
					onClick={() => onChange(currentPage + 1)}>
					&gt;
				</div>
			</div>
		</div>
	) : null;
};
