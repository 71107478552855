import { COMMON } from "../types";

export default (state = { mainPreloader: true }, action) => {
	switch (action.type) {
		case COMMON.MAIN_PRELOADER:
			return {
				mainPreloader: action.payload,
			};
		default:
			return state;
	}
};
