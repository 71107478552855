import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";

import { HorizontalBar } from "react-chartjs-2";

import DashboardMarketList from "./dashboardMarketList";

import { getAdminChartData } from "../../store/actions";
import { colors } from "../../utils/helper";

const initialStates = {
	adminChartResult: null,
	adminChartData: null,
	company: null,
	marketRefId: null,
	tooltipData: null,
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const SuperAdminChart = ({ getAdminChartData }) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const { adminChartResult, adminChartData, company, tooltipData } = state;

	useEffect(() => {
		const successHandler = (e) => {
			const result = e.result;
			dispatch({
				adminChartResult: result,
				company: result[0],
			});
		};

		colors && getAdminChartData(successHandler);
	}, [getAdminChartData]);

	useEffect(() => {
		if (adminChartResult) {
			let maxBars = 0;
			const labels = adminChartResult.map((item) => {
				const { companyName, propertyCounts } = item;
				maxBars =
					propertyCounts.length > maxBars
						? propertyCounts.length
						: maxBars;
				return companyName;
			});

			const rowDatasets = adminChartResult.map((item) => {
				const { propertyCounts } = item;
				let newCounts = [];
				for (let i = 0; i < maxBars; i++) {
					newCounts = [
						...newCounts,
						propertyCounts[i] ? propertyCounts[i] : 0,
					];
				}
				return newCounts.sort((a, b) => b - a);
			});

			let datasets = [];
			for (let i = 0; i < maxBars; i++) {
				let barColor = null;
				const resultList = adminChartResult.map((item, index) => {
					barColor = colors[i] ? colors[i] : "#000";
					return rowDatasets[index][i];
				});
				datasets = [
					...datasets,
					{
						backgroundColor: barColor,
						borderWidth: 10,
						hoverBackgroundColor: barColor,
						data: resultList,
						barThickness: 20,
					},
				];
			}

			dispatch({
				adminChartData: { labels, datasets },
			});
		}
	}, [company, adminChartResult]);

	const datasetKeyProvider = () => {
		return Math.random();
	};

	const options = {
		legend: {
			display: false,
		},
		scales: {
			xAxes: [
				{
					label: "Companies",
					stacked: true,
				},
			],
			yAxes: [
				{
					label: "Listings",
					stacked: true,
					gridLines: {
						display: false,
					},
				},
			],
		},
		tooltips: {
			enabled: false,
			mode: "index",
			position: "nearest",
			custom: (tooltip) => {
				const company = adminChartResult[tooltip.dataPoints[0].index];
				const companyMarkets =
					company && company.marketName && company.propertyCounts
						? company.marketName.map((item, index) => {
								return {
									market: item,
									listings: company.propertyCounts[index],
								};
						  })
						: [];
				dispatch({
					tooltipData:
						tooltip.opacity === 0
							? null
							: {
									...company,
									x: tooltip.x,
									y: tooltip.y,
									companyMarkets,
							  },
				});
			},
		},
	};

	const onBarClick = (e) => {
		const company =
			adminChartResult && e && e.length
				? adminChartResult[e[0]._index]
				: null;
		company &&
			dispatch({
				company,
			});
	};

	const renderTooltip = () => {
		return tooltipData ? (
			<div
				className="graph-tooltip"
				style={{
					left: `${tooltipData.x}px`,
					top: `${tooltipData.y}px`,
				}}
			>
				<h3>{tooltipData.companyName}</h3>
				<div className="d-block">
					{tooltipData.companyMarkets.map((market, index) => (
						<div key={index} className="d-flex">
							<div className="no-wrap">
								<span className="pr-2">{market.market}</span>
								<strong>{market.listings}</strong>
							</div>
						</div>
					))}
				</div>
			</div>
		) : null;
	};

	return (
		<div className="card card-primary p-4">
			{adminChartData ? (
				<div className="chart-container">
					<h2 className="mb-4">Customer Overview</h2>
					<div className="relative">
						<HorizontalBar
							data={adminChartData}
							options={options}
							datasetKeyProvider={datasetKeyProvider}
							onElementsClick={onBarClick}
						/>
						{renderTooltip()}
						<div className="mb-2 text-center text-sm text-gray">
							Listings
						</div>
					</div>
				</div>
			) : null}
			<DashboardMarketList company={company} />
		</div>
	);
};

const mapDispatchToProps = {
	getAdminChartData,
};

export default connect(null, mapDispatchToProps)(SuperAdminChart);
