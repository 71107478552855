import { outboundCommunicationType } from "../../../constant/templateManagement";
import { get } from "lodash";

import { successMessage } from "../../../utils/successMessage";

//Mapper fucntion to create a array of selected template mappings objects
//usage save/update
export const getPropertyNotificationSettings = (
	values,
	ownerTemplateProspectingEvents,
	relatorTemplateProspectingEvents,
	createMode,
	selectedID,
	propertyNotificationSetting
) => {
	let selected = [];
	if (!createMode) {
		selected = propertyNotificationSetting
			.companyPropertyNotificationSettings.length
			? propertyNotificationSetting.companyPropertyNotificationSettings.filter(
					(item) => {
						return (
							item.outboundProspectingType ===
							outboundCommunicationType.homeOwner.value
						);
					}
			  )[0]
			: null;
	}
	const ownerObject = {
		companyPropNotifSettingId:
			!createMode && selected ? selected.companyPropNotifSettingId : "",
		outboundProspectingType: outboundCommunicationType.homeOwner.value,
		turnOffAutomaticProspecting:
			values.ownerTurnOffAutomaticProspecting || false,
		propertyId: selectedID,
		isActive: true,
		templateMappings: ownerTemplateProspectingEvents.map((item, index) => {
			let companyPropNotifTemplMapping =
				!createMode && selected
					? selected.templateMappings.filter((selectedItem) => {
							return (
								selectedItem.notificationEventTypeId ===
								item.value
							);
					  })
					: [];
			let companyPropNotifTemplMappingId = companyPropNotifTemplMapping.length
				? companyPropNotifTemplMapping[0][
						"companyPropNotifTemplMappingId"
				  ]
				: "";
			return {
				companyPropNotifTemplMappingId: companyPropNotifTemplMappingId,
				notificationEventTypeId: item.value,
				emailTemplateId:
					!values.ownerTurnOffAutomaticProspecting &&
					values.automaticTouchesEnabled
						? get(
								values,
								`select${outboundCommunicationType.homeOwner.value}emailTemplate${item.value}.value`,
								""
						  )
						: "",
				smsTemplateId:
					!values.ownerTurnOffAutomaticProspecting &&
					values.automaticTouchesEnabled
						? get(
								values,
								`select${outboundCommunicationType.homeOwner.value}smsTemplate${item.value}.value`,
								""
						  )
						: "",
				mailerTemplateId:
					!values.ownerTurnOffAutomaticProspecting &&
					values.automaticTouchesEnabled
						? get(
								values,
								`select${outboundCommunicationType.homeOwner.value}mailerTemplate${item.value}.value`,
								""
						  )
						: "",
			};
		}),
	};

	selected = [];
	if (!createMode) {
		selected = propertyNotificationSetting
			.companyPropertyNotificationSettings.length
			? propertyNotificationSetting.companyPropertyNotificationSettings.filter(
					(item) => {
						return (
							item.outboundProspectingType ===
							outboundCommunicationType.realtor.value
						);
					}
			  )[0]
			: null;
	}
	const relatorObject = {
		companyPropNotifSettingId:
			!createMode && selected ? selected.companyPropNotifSettingId : "",
		outboundProspectingType: outboundCommunicationType.realtor.value,
		turnOffAutomaticProspecting:
			values.realtorTurnOffAutomaticProspecting || false,
		propertyId: selectedID,
		isActive: true,
		templateMappings: relatorTemplateProspectingEvents.map(
			(item, index) => {
				let companyPropNotifTemplMapping =
					!createMode && selected
						? selected.templateMappings.filter((selectedItem) => {
								return (
									selectedItem.notificationEventTypeId ===
									item.value
								);
						  })
						: [];
				let companyPropNotifTemplMappingId = companyPropNotifTemplMapping.length
					? companyPropNotifTemplMapping[0][
							"companyPropNotifTemplMappingId"
					  ]
					: "";
				return {
					notificationEventTypeId: item.value,
					companyPropNotifTemplMappingId: companyPropNotifTemplMappingId,
					emailTemplateId:
						!values.realtorTurnOffAutomaticProspecting &&
						values.automaticTouchesEnabled
							? get(
									values,
									`select${outboundCommunicationType.realtor.value}emailTemplate${item.value}.value`,
									""
							  )
							: "",
					smsTemplateId:
						!values.realtorTurnOffAutomaticProspecting &&
						values.automaticTouchesEnabled
							? get(
									values,
									`select${outboundCommunicationType.realtor.value}smsTemplate${item.value}.value`,
									""
							  )
							: "",
					mailerTemplateId:
						!values.realtorTurnOffAutomaticProspecting &&
						values.automaticTouchesEnabled
							? get(
									values,
									`select${outboundCommunicationType.realtor.value}mailerTemplate${item.value}.value`,
									""
							  )
							: "",
				};
			}
		),
	};
	return [ownerObject, relatorObject];
};

export const getPropertyNotificationData = (propertyNotificationSetting) => {
	if (
		typeof propertyNotificationSetting.companyPropertyNotificationSettings ===
			"undefined" ||
		!propertyNotificationSetting.companyPropertyNotificationSettings.length
	)
		return { notificationData: true };
	let filentObject = { notificationData: true };
	filentObject.automaticTouchesEnabled =
		propertyNotificationSetting.automaticTouchesEnabled;

	//filentObject.automaticTouchesEnabled = true;
	const ownerObject = propertyNotificationSetting.companyPropertyNotificationSettings.filter(
		(item) =>
			item.outboundProspectingType ===
			outboundCommunicationType.homeOwner.value
	)[0];
	if (typeof ownerObject !== "undefined") {
		filentObject.ownerTurnOffAutomaticProspecting =
			ownerObject.turnOffAutomaticProspecting;

		ownerObject.templateMappings.forEach((element) => {
			filentObject[
				`select${2}emailTemplate${element.notificationEventTypeId}`
			] = { value: element.emailTemplateId };

			filentObject[
				`select${2}mailerTemplate${element.notificationEventTypeId}`
			] = { value: element.mailerTemplateId };

			filentObject[
				`select${2}smsTemplate${element.notificationEventTypeId}`
			] = { value: element.smsTemplateId };
		});
	}

	const relatorObject = propertyNotificationSetting.companyPropertyNotificationSettings.filter(
		(item) =>
			item.outboundProspectingType ===
			outboundCommunicationType.realtor.value
	)[0];
	if (typeof relatorObject !== "undefined") {
		filentObject.realtorTurnOffAutomaticProspecting =
			relatorObject.turnOffAutomaticProspecting;

		relatorObject.templateMappings.forEach((element) => {
			filentObject[
				`select${1}emailTemplate${element.notificationEventTypeId}`
			] = { value: element.emailTemplateId };

			filentObject[
				`select${1}mailerTemplate${element.notificationEventTypeId}`
			] = { value: element.mailerTemplateId };

			filentObject[
				`select${1}smsTemplate${element.notificationEventTypeId}`
			] = { value: element.smsTemplateId };
		});
	}

	return filentObject;
};

export const updateSettingsHelper = (
	type,
	propertySettings,
	selectedID,
	setShowLoader,
	dispatch,
	updatePropertySettings
) => {
	let msgCode;
	switch (type) {
		case "markAsFavourite":
			setShowLoader(true);
			msgCode = propertySettings.markAsFavourite ? 6011 : 6003;
			updatePropertySettings(
				{
					propertyId: selectedID,
					statusType: "markAsFavourite",
					value: !propertySettings.markAsFavourite,
				},
				() => {
					setShowLoader(false);
					dispatch({
						propertySettings: {
							...propertySettings,
							markAsFavourite: !propertySettings.markAsFavourite,
						},
					});
					successMessage(msgCode);
				},
				() => {
					setShowLoader(false);
				}
			);
			break;
		case "markAsDiscarded":
			setShowLoader(true);
			msgCode = propertySettings.markAsDiscarded ? 6010 : 6004;
			updatePropertySettings(
				{
					propertyId: selectedID,
					statusType: "markAsDiscarded",

					value: !propertySettings.markAsDiscarded,
				},
				() => {
					setShowLoader(false);
					dispatch({
						propertySettings: {
							...propertySettings,
							markAsDiscarded: !propertySettings.markAsDiscarded,
						},
					});
					successMessage(msgCode);
				},
				() => {
					setShowLoader(false);
				}
			);
			break;
		case "markAsInProgress":
			setShowLoader(true);
			msgCode = propertySettings.markAsInProgress ? 6009 : 6005;
			updatePropertySettings(
				{
					propertyId: selectedID,
					statusType: "markAsInProgress",
					value: !propertySettings.markAsInProgress,
				},
				() => {
					setShowLoader(false);
					dispatch({
						propertySettings: {
							...propertySettings,
							markAsInProgress: !propertySettings.markAsInProgress,
						},
					});
					successMessage(msgCode);
				},
				() => {
					setShowLoader(false);
				}
			);
			break;
		default:
	}
};
