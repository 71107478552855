export const required = (value) => {
	if (
		(typeof value === "number" && (value || value === 0)) ||
		(value && value instanceof Array && value.length) ||
		(value && typeof value === "object" && Object.keys(value).length) ||
		(value && typeof value === "string" && value && value !== "") ||
		(value && typeof value === "boolean")
	) {
		return undefined;
	}
	return "Required";
};

export const maxLength = (max) => (value) =>
	value && value.length > max
		? `Must be ${max} characters or less`
		: undefined;

export const maxEditorLength = (max) => (value = "") => {
	if (value === null || value === "" || typeof value === "undefined")
		return undefined;
	else value = value.toString();
	value = value.replace(/(<([^>]+)>)/gi, "");
	return value && value.length > max
		? `Must be ${max} characters or less`
		: undefined;
};

export const max5 = maxLength(5);
export const max25 = maxLength(25);
export const max64 = maxLength(64);
export const max100 = maxLength(100);
export const max160 = maxLength(160);
export const max250 = maxLength(250);
export const max1600 = maxLength(1600)

export const max160Editor = maxEditorLength(160);
export const max300Editor = maxEditorLength(300);

export const minLength = (min) => (value) =>
	value && value.length < min
		? `Must be ${min} characters or more`
		: undefined;

export const number = (value) =>
	value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const minValue = (min) => (value) =>
	value && value < min ? `Must be at least ${min}` : undefined;

export const email = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
		? "Invalid email address"
		: undefined;

export const tooYoung = (value) =>
	value && value < 13
		? "You do not meet the minimum age requirement!"
		: undefined;

export const aol = (value) =>
	value && /.+@aol\.com/.test(value)
		? "Really? You still use AOL for your email?"
		: undefined;

export const alphaNumeric = (value) =>
	value && /[^a-zA-Z0-9 ]/i.test(value)
		? "Only alphanumeric characters"
		: undefined;

export const phoneNumber = (value) =>
	value && !/^(0|[1-9][0-9]{5,9})$/i.test(value)
		? "Must be 6 to 10 digits"
		: undefined;

export const USPhoneNumber = (value) => {
	var pattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
	return value && !pattern.test(value) ? "Not a valid format" : undefined;
};

export const passwordMatch = (pass1, pass2) =>
	pass1 && pass2 && pass1 !== pass2 ? "Password does not match" : undefined;

export const passwordStrength = (value) => {
	// var pattern = new RegExp(
	// 	"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_])(?=.{8,})"
	// );
	var pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;

	return value && !pattern.test(value) ? "Too weak" : undefined;
};

export const time = (value) =>
	value && !/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(value)
		? "Invalid time format"
		: undefined;

export const max = (max) => (value) =>
	value && typeof value !== "number" && value > max
		? `Must be ${max} or less`
		: undefined;

export const maxInt = max(2147483647);

export const min = (min) => (value) => {
	const val = value
		? typeof value === "object"
			? value.value
			: value
		: undefined;
	return val && val < min ? `Must be ${min} or more` : undefined;
};

export const min0 = min(0);
export const min1 = min(1);

export const isInt = (value) =>
	value && !/^\d+$/.test(value)
		? "Please provide a valid integer"
		: undefined;

export const array = (value) =>
	value && value.length > 0 ? undefined : "Required";

export const url = (value) =>
	value &&
	!/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i.test(
		value
	)
		? "Invalid url"
		: undefined;

export const youtube_url = (value) =>
	value &&
	!/^(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.)?youtube\.com\/watch(?:\.php)?\?.*v=)([a-zA-Z0-9\-_]+)/gm.test(
		value
	)
		? "Not a YouTube Video Url"
		: undefined;

export const normalizePhone = (value, previousValue) => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, "");
	if (!previousValue || value.length > previousValue.length) {
		// typing forward
		if (onlyNums.length === 3) {
			return "(" + onlyNums + ") ";
		}
		if (onlyNums.length === 6) {
			return "(" + onlyNums.slice(0, 3) + ") " + onlyNums.slice(3) + "-";
		}
	}
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 6) {
		return "(" + onlyNums.slice(0, 3) + ") " + onlyNums.slice(3);
	}
	return (
		"(" +
		onlyNums.slice(0, 3) +
		") " +
		onlyNums.slice(3, 6) +
		"-" +
		onlyNums.slice(6, 10)
	);
};
