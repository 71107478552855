import React, { useReducer, useEffect } from "react";
import "./navTabs.scss";

const initialStates = {
	active: 0,
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const NavTabs = ({ data = [], activeIndex = 0, onSelect }) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const { active } = state;

	useEffect(() => {
		if (data && data.length) {
			dispatch({
				active: activeIndex,
			});
		}
	}, [activeIndex, data]);

	const onTabChange = (nav, index) => {
		onSelect && onSelect(nav, index, dispatch({ active: index }));
	};

	const renderNavs = () => {
		return data && data.length
			? data.map((nav, index) => {
					return (
						<div
							key={index}
							className={`nav-item ${
								active === index ? "active" : ""
							}`}
							disabled={active === index}
							onClick={() => onTabChange(nav, index)}
						>
							{nav.icon ? <i className={nav.icon} /> : null}
							<span>{nav.label}</span>
						</div>
					);
			  })
			: null;
	};

	return <div className="tab-navs">{renderNavs()}</div>;
};

export default NavTabs;
