export const facebookIcon =
	"https://moovsoonstaging.s3.amazonaws.com/mailtemplate/20200316_6d82775c-0f80-4239-a966-c72e40841f9e.png";
export const youtubeIcon =
	"https://moovsoonstaging.s3.amazonaws.com/mailtemplate/20200316_c52ef0e4-6a74-4147-a87f-200e6c9a56f6.png";
export const twitterIcon =
	"https://moovsoonstaging.s3.amazonaws.com/mailtemplate/20200316_7e3b4f6a-3619-4ba2-8f0c-8a7d7307683f.png";
export const linkedInIcon =
	"https://moovsoonstaging.s3.amazonaws.com/mailtemplate/20200316_0a695a1c-9d93-4f52-be07-b2caed00840c.png";

export const outboundCommunicationType = {
	homeOwner: {
		value: 2,
		label: "Home Owner",
	},
	realtor: {
		value: 1,
		label: "Realtor",
	},
};

export const templateTypeOptions = {
	email: {
		label: "Email",
		value: 1,
	},
	sms: {
		label: "SMS",
		value: 2,
	},
	mailer: {
		label: "Mailer",
		value: 3,
	},
};
