import StampImage from "../../../assets/mailer-stamp.JPG";
export const mailerTemplateHTML = ({
  data,
  initialValues,
  formValues,
  templateSize,
}) => {
  const allValues = {
    ...initialValues,
    ...formValues,
  };
  const logoImage =
    allValues && allValues.postCardFrontImage
      ? allValues.postCardFrontImage
      : null;

  //templateSize =1 => 4x6 in
  //templateSize =2 => 6x9 in
  //templateSize =3 => 6X11 in
  return [
    `<html>
        <head>
            <meta charset="UTF-8">
            <title>Lob.com Sample 4x6 Postcard Back</title>
            <style>
                *,
                *:before,
                *:after {
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }
                .body {
                    position: relative;
                    width: ${
                      templateSize === "1"
                        ? "6.25in"
                        : templateSize === "2"
                        ? "9.25in"
                        : "11.25in"
                    };
                    height: ${templateSize === "1" ? "4.25in" : "6.25in"};
                    margin: 0;
                    padding: 0;
                    /* If using an image, the background image should have dimensions of 1875x1275 pixels. */
                    background-repeat: no-repeat;
                }
                #safe-area {
                    position: absolute;
                    width: ${
                      templateSize === "1"
                        ? "5.875in"
                        : templateSize === "2"
                        ? "8.875in"
                        : "10.875in"
                    };
                    height: ${templateSize === "1" ? "3.875in" : "5.875in"};
                    // left: 0.1875in;
                    top: 0.1875in;
                    border: 2px dotted #1cc57b;
                }
                #safe-area-outer {
                    position: absolute;
                    width: ${
                      templateSize === "1"
                        ? "6in"
                        : templateSize === "2"
                        ? "9in"
                        : "11in"
                    };
                    height: ${templateSize === "1" ? "4in" : "6in"};
                    // left: 0.125in;
                    top: 0.125in;
                    border: 2px dotted #5342c7;
                }
                #ink-free {
                    position: absolute;
                    width: 3.2835in;
                    height: 2.375in;
                    right: ${templateSize === "1" ? "0.1in" : "0.8in"};
                    bottom: 0.1in;
                }
            </style>
        </head>
        <body class="body">
        <div style="position: relative;padding-bottom: 30px; display: flex;justify-content: center;">
        ${
          logoImage
            ? `<img style="height:${
                templateSize === "1" ? "4.25in" : "6.25in"
              }; width:${
                templateSize === "1"
                  ? "6.25in"
                  : templateSize === "2"
                  ? "9.25in"
                  : "11.25in"
              };" id="postCardBackImage" src="${logoImage}" alt="POST CARD BACK IMAGE"/>`
            : ""
        } 
        <div id="safe-area-outer">
        </div>
        <div id="safe-area">
            </div>
         </div>   
         ${
           allValues.postCardBackImage
             ? `<div style="position: relative; display: flex; justify-content: center;">
            <img style="height:${
              templateSize === "1" ? "4.25in" : "6.25in"
            }; width:${
                 templateSize === "1"
                   ? "6.25in"
                   : templateSize === "2"
                   ? "9.25in"
                   : "11.25in"
               };"" id="postCardBackImage" src="${
                 allValues.postCardBackImage
               }" alt="POST CARD BACK IMAGE"/>
            <div id="safe-area-outer">
        </div>
            <div id="safe-area">
            <div id="ink-free">
            <img src="${StampImage}" alt="STAMP IMAGE" style="position: absolute;width: ${
                 templateSize === "1" ? "3.2835in" : "4in"
               };height: 2.375in;" />
            </div>
            </div>
            </div>`
             : ""
         }
        </body>
    </html>`,
    [
      logoImage ? logoImage : null,
      allValues.postCardBackImage ? allValues.postCardBackImage : null,
    ],
  ];
};
