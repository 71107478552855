import React from "react";
import { connect } from "react-redux";
const PreloaderInner = ({ settingsPreloader }) => {
	return (
		<div className={`preloader-wrap  ${settingsPreloader ? "show" : ""}`}>
			<div className="preloader-container">
				<div className="preloader-cow">
					<div className="cow-ear-left" />
					<div className="cow-ear-right" />
					<div className="cow-face">
						<div className="cow-eye-shadow" />
						<div className="cow-eye-left" />
						<div className="cow-eye-right" />
						<div className="cow-nose-left" />
						<div className="cow-nose-right" />
					</div>
					<div className="cow-mouth" />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		settingsPreloader: state.Settings.settingsPreloader,
	};
};

export default connect(mapStateToProps, null)(PreloaderInner);
