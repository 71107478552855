import React from "react";

import { Currency, Date } from "../../utils/formatter";
import {
  getUtcDateFromDatePicker,
  getDatePickerDateFromtUtcDate,
} from "../../utils/helper";
import { get } from "lodash";
import { required, number, alphaNumeric } from "../../constant/formValidators";
import { dateFormat } from "../../constant/global";
import Tooltip from "../../utils/tooltip";
import openHouseIcon from "../../assets/home.png";
import { formatOpenHouseDate } from "../../utils/openHouseDate";

export const columns = ({
  onSelect,
  updatePropertySettings,
  toggleMainLoader,
  loggedInAs,
  dataReload,
  setDataReload,
}) => {
  return [
    {
      name: "Address",
      accessor: "address",
      className: "col-property-address",
      headerClassName: "col-property-address-header",
      sortable: false,
      render: (row) => (
        <div className="address-column-data">
          {/* settings change option for non Admin users */}
          {!loggedInAs("AD") ? (
            <span
              className="fav-block"
              onClick={() => {
                toggleMainLoader(true);
                const successHandler = (event) => {
                  //Reload the maian listing
                  toggleMainLoader(false);
                  setDataReload(!dataReload);
                };
                const errorHandler = (event) => {
                  toggleMainLoader(false);
                };
                updatePropertySettings(
                  {
                    propertyId: row.propertyId,
                    statusType: "markAsFavourite",
                    value: !row.markAsFavourite,
                  },
                  successHandler,
                  errorHandler
                );
              }}
            >
              {row.markAsFavourite ? (
                <Tooltip
                  message="Unmark As Favorite"
                  component={
                    <i className="icomoon icon-valentines-heart-1 icon-valentines-heart-red"></i>
                  }
                />
              ) : (
                <Tooltip
                  message="Mark As Favorite"
                  component={
                    <i className="icomoon icon-valentines-heart-1 icon-valentines-heart-white"></i>
                  }
                />
              )}
            </span>
          ) : null}
          <span
            className="text-primary pointer ellipsis address-data"
            onClick={() => onSelect(row)}
          >
            {row.address}
          </span>
        </div>
      ),
    },
    {
      name: "MLS#",
      accessor: "mlsNo",
      className: "col-property-mls-no",
      headerClassName: "col-property-mls-no-header",
      render: (row) => {
        const { formatDate, timeZone } = formatOpenHouseDate(row?.openHouse);
        return (
          <div className="">
            {/* settings change option for non Admin users */}
            {!loggedInAs("AD") ? (
              <Tooltip
                message={row.markAsInProgress ? "In Process" : "Not In Process"}
                component={
                  <span className="progress-block">
                    {row.markAsInProgress ? (
                      <span className="icon-inprocess text-lg">
                        <span className="icon-path path1"></span>
                        <span className="icon-path path2"></span>
                        <span className="icon-path path3"></span>
                      </span>
                    ) : (
                      <i className="icomoon icon-chats icon-inprocess-inactive"></i>
                    )}
                  </span>
                }
              />
            ) : null}
            {row.mlsNo}
            <span>
              {row?.openHouse && (
                <Tooltip
                  message={`Open House ${formatDate}${
                    timeZone ? `, ${timeZone}` : ""
                  }`}
                  component={
                    <span>
                      {" "}
                      <button
                        style={{
                          backgroundColor: "#EBF5EA",
                          border: "2px solid #C8E3C4",
                          height: "24px",
                          width: "24px",
                        }}
                        type="button"
                        onClick={() => {}}
                        className="btn btn-sm btn-cell ms-2"
                      >
                        <img
                          className="mb-2"
                          src={openHouseIcon}
                          alt="requested owner data"
                        />{" "}
                      </button>
                    </span>
                  }
                  placement="right"
                />
              )}
            </span>
          </div>
        );
      },
    },
    {
      name: "Zip Code",
      accessor: "zipCode",
      className: "col-property-zip-code",
      headerClassName: "col-property-zip-code-header",
      sortable: true,
      render: (row) => <div className="">{row.zipCode}</div>,
    },
    {
      name: "Asking Price",
      accessor: "askingPrice",
      className: "col-property-asking-price",
      headerClassName: "col-property-asking-price-header",
      sortable: false,
      render: (row) => (
        <div className="">
          <Currency amount={row.askingPrice} currency="USD" />
        </div>
      ),
    },
    {
      name: "Size",
      accessor: "size",
      className: "col-property-size",
      headerClassName: "col-property-size-header",
      sortable: true,
      render: (row) => (
        <div className="">
          {new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 3,
          }).format(row.size)}{" "}
          sqft
          <div>
            <span className="text-primary">
              {row.numOfBeds}
              Beds, {row.numOfBaths} Baths
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Date Added",
      accessor: "dateAdded",
      className: "col-property-date-added",
      headerClassName: "col-property-date-added-header",
      sortable: true,
      render: (row) => (
        <div className="">
          <Date utcTime={row.dateAdded} format={dateFormat} />
        </div>
      ),
    },
    {
      name: "Status",
      accessor: "propertyStatus",
      className: "col-property-property-status",
      headerClassName: "col-property-property-status-header",
      sortable: false,
      render: (row) => {
        const className = `badge property-badge-${row.propertyStatus}`;
        return (
          <div>
            <span className={className}>{row.propertyStatus}</span>
            {/* settings change option for non Admin users */}
            {!loggedInAs("AD") ? (
              <span
                className="archive-block"
                onClick={() => {
                  toggleMainLoader(true);
                  const successHandler = (event) => {
                    //Reload the maian listing
                    toggleMainLoader(false);
                    setDataReload(!dataReload);
                  };
                  const errorHandler = (event) => {
                    toggleMainLoader(false);
                  };
                  updatePropertySettings(
                    {
                      propertyId: row.propertyId,
                      statusType: "markAsDiscarded",
                      value: !row.markAsDiscarded,
                    },
                    successHandler,
                    errorHandler
                  );
                }}
              >
                {row.markAsDiscarded ? (
                  <Tooltip
                    message="Unmark As Discarded"
                    component={
                      <i className="icomoon icon-archive icon-archive-active"></i>
                    }
                  />
                ) : (
                  <Tooltip
                    message="Mark As Discarded"
                    component={
                      <i className="icomoon icon-archive icon-archive-inactive"></i>
                    }
                  />
                )}
              </span>
            ) : null}
          </div>
        );
      },
    },
  ];
};

export const filtersArray = ({
  propertyZipcode,
  propertyStatuses,
  propertyRealtors,
  propertyChildMarkets,
  filtersSelected,
  selectedDate,
  setSelectedDate,
  filterFormValues,
  loggedInAs,
  selectedPendingDate,
  setSelectedPendingDate,
}) => {
  const filterSettingsOptions = [
    { value: "", label: "Select" },
    { label: "Favorite", value: "markAsFavourite" },
    { label: "In Process", value: "markAsInProgress" },
    { label: "Opportunities", value: "opportunities" },
    { label: "Booked", value: "booked" },
    { label: "Discarded", value: "markAsDiscarded" },
  ];

  const nonSAfilterSettingsOptions = [
    { value: "", label: "Select" },
    { label: "Favorite", value: "markAsFavourite" },
    { label: "In Process", value: "markAsInProgress" },
    { label: "Opportunities", value: "opportunities" },
    { label: "Booked", value: "booked" },
    { label: "Discarded", value: "markAsDiscarded" },
  ];

  const openHouseStatus = [
    { value: "", label: "Select" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  //Common function to set the default/prev selected value for the dropdowns on filter panel mount event
  const getSelectedValue = (key, lookupArray = []) => {
    if (typeof filtersSelected == "undefined") return null;
    const selected = filtersSelected.filter((item) => item.key === key)[0];
    const selectedValue = selected ? selected.value : null;
    const selectedObject = lookupArray.filter(
      (item) => item.value === selectedValue
    )[0];
    return selectedObject && selectedObject.value && selectedObject.label
      ? {
          label: selectedObject.label,
          value: selectedObject.value,
        }
      : null;
  };

  return [
    {
      name: "zipcode",
      label: "Zip Code",
      key: "zipcode",
      type: "select",
      defaultValue: getSelectedValue("zipcode", propertyZipcode),
      multiple: false,
      options: propertyZipcode,
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "status",
      label: "Status",
      key: "status",
      type: "select",
      multiple: false,
      defaultValue: getSelectedValue("status", propertyStatuses),
      options: propertyStatuses,
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "sqftmin",
      label: "Listing Min Size",
      key: "sqftmin",
      type: "text",
      validate: [number],
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "sqftmax",
      label: "Listing Max Size",
      key: "sqftmax",
      type: "text",
      validate: [number],
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "pricemin",
      label: "Listing Min Price",
      key: "pricemin",
      type: "text",
      validate: [number],
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "pricemax",
      label: "Listing Max Price",
      key: "pricemax",
      type: "text",
      validate: [number],
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "dateaddedfrom",
      label: "Date Added From",
      key: "dateaddedfrom",
      type: "datepicker",
      selectedDate: selectedDate && selectedDate.from ? selectedDate.from : "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          from: value,
        });
      },
      maxDate: selectedDate && selectedDate.to ? selectedDate.to : "",
      formGroupClass: "col-6 pr-2",
      className: "form-control",
    },
    {
      name: "dateaddedto",
      label: "Date Added To",
      key: "dateaddedto",
      type: "datepicker",
      selectedDate: selectedDate && selectedDate.to ? selectedDate.to : "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          to: value,
        });
      },
      minDate: selectedDate && selectedDate.from ? selectedDate.from : "",
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "statusdateaddedfrom",
      label: "Date Pending Status From",
      key: "statusdateaddedfrom",
      type: "datepicker",
      selectedDate:
        selectedPendingDate && selectedPendingDate.from
          ? selectedPendingDate.from
          : "",
      onChange: (value) => {
        setSelectedPendingDate({
          ...selectedPendingDate,
          from: value,
        });
      },
      maxDate:
        selectedPendingDate && selectedPendingDate.to
          ? selectedPendingDate.to
          : "",
      formGroupClass: "col-6 pr-2",
      className: "form-control",
    },
    {
      name: "statusdateaddedto",
      label: "Date Pending Status To",
      key: "statusdateaddedto",
      type: "datepicker",
      selectedDate:
        selectedPendingDate && selectedPendingDate.to
          ? selectedPendingDate.to
          : "",
      onChange: (value) => {
        setSelectedPendingDate({
          ...selectedPendingDate,
          to: value,
        });
      },
      minDate:
        selectedPendingDate && selectedPendingDate.from
          ? selectedPendingDate.from
          : "",
      formGroupClass: "col-6 pr-2",
      className: "form-control",
    },
    {
      name: "openhouse",
      label: "Open House",
      key: "openhouse",
      type: "select",
      multiple: false,
      defaultValue: getSelectedValue("openhouse", openHouseStatus),
      options: openHouseStatus,
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "realtor",
      label: "Realtor",
      key: "realtor",
      type: "select",
      multiple: false,
      options: propertyRealtors,
      defaultValue: getSelectedValue("realtor", propertyRealtors),
      formGroupClass: "col-6 pr-2",
    },
    //KAN-52: additional filteter
    {
      name: "childmarket",
      label: "Child Market",
      key: "childmarket",
      type: "select",
      multiple: false,
      defaultValue: getSelectedValue("childmarket", propertyChildMarkets),
      options: propertyChildMarkets,
      formGroupClass: "col-6 pr-2",
    },

    {
      name: "settings",
      label: "Listing Settings",
      key: "settings",
      type: "select",
      multiple: false,
      hide: typeof loggedInAs === "function" && loggedInAs("AD"),
      options:
        typeof loggedInAs === "function" && !loggedInAs("AD")
          ? nonSAfilterSettingsOptions
          : filterSettingsOptions,
      defaultValue: getSelectedValue(
        "settings",
        typeof loggedInAs === "function" && !loggedInAs("AD")
          ? nonSAfilterSettingsOptions
          : filterSettingsOptions
      ),
      formGroupClass: "col-6 pr-2",
    },
    {
      name: "saveFilter",
      label: "Save and apply filters",
      type: "checkbox",
      formGroupClass: "col-12  flex-row save-filter",
    },
    {
      name: "filterName",
      label: "Filter Name",
      maxlength: 150,
      type: "text",
      hide:
        typeof filterFormValues === "undefined" || !filterFormValues.saveFilter,
      formGroupClass: "col-8",
      validate:
        typeof filterFormValues !== "undefined" && filterFormValues.saveFilter
          ? [required, alphaNumeric]
          : [],
    },
  ];
};

/* Middleware for creating the filter array for webservice from the form values
 * Since baxxkend is accepting the filter as a key value object array , we need to loop with
 * the ffilter form values and generate the array
 */
export const mapfilterValues = (formValues) => {
  return filtersArray({})
    .filter(
      (item) =>
        ["select", "datepicker", "text"].includes(item.type) &&
        item.name !== "filterName" &&
        formValues.hasOwnProperty(item.key) &&
        (item.type === "select" && get(formValues, `${item.key}.value`) === ""
          ? false
          : true) &&
        ((item.type === "text" || item.type === "datepicker") &&
        (get(formValues, `${item.key}`) === "" ||
          get(formValues, `${item.key}`) === null)
          ? false
          : true)
    )
    .map((o) => {
      return {
        key: o.key,
        value:
          o.type === "select"
            ? get(formValues, `${o.key}.value`, null) === ""
              ? null
              : get(formValues, `${o.key}.value`, null)
            : o.type === "datepicker"
            ? getUtcDateFromDatePicker(get(formValues, `${o.key}`, null))
            : get(formValues, `${o.key}`, null),
        label: o.label,
      };
    });
};

//Generating filter obkect from the saved filter options
export const setFilterFromSavedList = (values) => {
  return values.map((o) => {
    return {
      key: o.key,
      value: o.value,
      label: filtersArray({}).filter((item) => item.key === o.key)[0].label,
    };
  });
};

export const getinitValuesFromRedux = (reduxFilter) => {
  const returnObject = {};
  if (reduxFilter && typeof reduxFilter !== "undefined" && reduxFilter.length) {
    reduxFilter.forEach((element) => {
      if (
        element.key === "zipcode" ||
        element.key === "status" ||
        element.key === "realtor" ||
        element.key === "settings" ||
        element.key === "openhouse"
      ) {
        returnObject[element.key] = {
          value: element.value,
          label: element.label,
        };
      } else if (element.key === "dateaddedfrom") {
        returnObject[element.key] = getDatePickerDateFromtUtcDate(
          element.value
        );
      } else {
        returnObject[element.key] = element.value;
      }
    });
  }
  return returnObject;
};
