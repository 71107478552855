import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { get } from "lodash";
import FormGroup from "../formGroup";
import {
  getAllmarketTemplates,
  getTemplateDetails,
  toggleMainLoader,
  settingsToggleMainLoader,
  sendManualProspecting,
} from "../../store/actions";
import "../../components/templates/templates.scss";
import { Button } from "../uiElements";
import { successMessage } from "../../utils/successMessage";

const initialStates = {
  previewData: null,
  postCardFrontImage: null,
  postCardBackImage: null,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const SMSTemplateFromList = ({
  handleSubmit,
  parentDispatch,
  getAllmarketTemplates,
  templateList,
  getTemplateDetails,
  toggleMainLoader,
  settingsToggleMainLoader,
  sendManualProspecting,
  propertyId,
  prospectingType,
  prospectingUserId,
  prospectingSource,
  realtorId,
  propertyDetails,
}) => {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const { previewData, templateListLookup, templateData } = state;

  useEffect(() => {
    const cb = () => {
      const dataSet = templateList.filter((item) => {
        return item.templateType === 2;
      });
      const dataSetLookUp = dataSet.map((item, index) => {
        return {
          ...item,
          label: item.templateName,
          value: item.templateId,
        };
      });
      dispatch({
        templateListLookup: dataSetLookUp,
      });
    };
    getAllmarketTemplates(cb);
  }, [getAllmarketTemplates, templateList]);
  const handlePreview = (value) => {
    settingsToggleMainLoader(true)
    const cb = (result) => {
      settingsToggleMainLoader(false)
      dispatch({
        previewData: createTemplatePreview(result.result),
      });
      dispatch({
        templateData: result.result,
      });
    };
    getTemplateDetails({ templateId: value.value }, cb);
  };
  const createTemplatePreview = (templateDetails) => {
    const { templateBody } = templateDetails;
    return templateBody;
  };
  const handleSubmitAction = (data) => {
    toggleMainLoader(true);
    const postData = {
      notificationData: templateData.templateBody,
      notificationDataBack: templateData.templateBodyBack,
      notificationTitle: "",
      prospectingSource: prospectingSource,
      prospectingType: prospectingType,
      prospectingUserId: prospectingUserId,
      templateType: "sms",
      propertyId: propertyId,
      realtorId: realtorId,
    };
    const cb = () => {
      toggleMainLoader(false);
      successMessage(6007);
      parentDispatch({
        showSMSPanel: false,
      });
    };
    sendManualProspecting(postData, cb);
  };
  return (
    <div className="create-template-wrap">
      <div className="d-flex align-items-center justify-content-between px-4 py-3 bb-1">
        <div className="d-flex flex-row align-items-center">
          <h2 className="color-1 m-0">Compose SMS</h2>
          {propertyDetails?.address && <h3 className="color-1 m-0 flex-fill ml-2">-</h3>}
          <h3 className="color-1 m-0 flex-fill ml-1">
            {propertyDetails?.address}
          </h3>
        </div>
        <button
          type="button"
          className="btn btn-default btn-sm"
          onClick={() =>
            parentDispatch({
              showSMSPanel: false,
            })
          }
        >
          Close
        </button>
      </div>

      <form
        className="flex-fill d-flex"
        onSubmit={handleSubmit(handleSubmitAction)}
      >
        <div className="create-template-right">
          <div className="create-template-box h-100">
            <div className="create-template-box-inner h-100">
              <div
                className={` create-template-box-inner-box create-template-form show`}
              >
                <div className="row w-100  align-items-start padding-left-30">
                  {templateListLookup ? (
                    <Field
                      {...{
                        name: "templateList",
                        label: "Choose Template",
                        type: "select",
                        options: templateListLookup,
                        onChange: (value) => {
                          handlePreview(value);
                        },
                        formGroupClass: "col-8",
                      }}
                      component={FormGroup}
                    />
                  ) : null}
                  {previewData && (
                    <div className="col-4 form-footer text-right py-3">
                      <Button
                        className="btn btn-primary mt-2"
                        type="submit"
                        value="Send"
                      />
                    </div>
                  )}
                </div>
                {previewData ? (
                  <>
                    <h3 className="color-1 text-uppercase padding-left-30 mt-2 mb-4">
                      Preview
                    </h3>
                    <div
                      className="row w-100 padding-left-30  m-0"
                      dangerouslySetInnerHTML={{
                        __html: previewData,
                      }}
                    ></div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = {
  getAllmarketTemplates,
  getTemplateDetails,
  toggleMainLoader,
  settingsToggleMainLoader,
  sendManualProspecting,
};
const mapStateToProps = (state) => {
  return {
    templateList: get(state, "Property.templateList", []),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "mailerTemplateFormFRomList",
    enableReinitialize: true,
  })(SMSTemplateFromList)
);
