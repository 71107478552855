export const errorCodes = [
  // custom messages
  {
    code: "FETCH_FAILED",
    reason: "Failed to fetch",
    message: "Failed to fetch data!",
  },

  {
    code:"Empty_Field",
    reason:"empty field",
    message:"Please choose any field"
  },
  {
    code: "NO_CHANGE",
    reason: "No changes found!",
    message: "No changes found!",
  },
  {
    code: "PASSWORD_NOT_MATCH",
    reason: "Passwords not matching!",
    message: "Passwords not matching!",
  },
  {
    code: "PLEASE_FILL_THE_FORM",
    reason: "Please fill the form!",
    message: "Please fill the form!",
  },
  {
    code: 0,
    reason: "LoginFailed",
    message: "Login failed! Either the username or password is incorrect.",
  },
  {
    code: 1,
    reason: "DefaultException",
    message: "Default exception!",
  },
  {
    code: 2,
    reason: "InsufficientPermission",
    message: "Insufficient permission!",
  },
  {
    code: 3,
    reason: "LoginFailed",
    message: "Login failed!",
  },
  {
    code: 4,
    reason: "InvalidToken",
    message: "Link not valid anymore. Request another one!",
  },
  {
    code: 5,
    reason: "UserLockedOut",
    message: "User Locked out! Please try after two minutes.",
  },
  {
    code: 6,
    reason: "InvalidPassword",
    message: "Invalid Password",
  },
  {
    code: 7,
    reason: "InvalidEmailAddress",
    message: "Invalid Email Address",
  },
  {
    code: 1001,
    reason: "UserAlreadyExist",
    message: "User already exists! Enter another email Id.",
  },
  {
    code: 1002,
    reason: "InvalidUser",
    message: "User does not exist!",
  },
  {
    code: 1003,
    reason: "InvalidUserType",
    message: "User type does not exist!",
  },
  {
    code: 1004,
    reason: "FailedToCreatUser",
    message: "User creation failed!",
  },
  {
    code: 1005,
    reason: "FailedToUpdateUser",
    message: "Update failed!",
  },
  {
    code: 1006,
    reason: "FailedToUpdatePassword",
    message: "Update failed!",
  },
  {
    code: 1007,
    reason: "PasswordPatternMismatch",
    message: "Password does not match!",
  },
  {
    code: 1008,
    reason: "IncorrectOldPassword",
    message: "Incorrect password!",
  },
  {
    code: 1009,
    reason: "FailedToChangePassword",
    message: "Change password failed!",
  },
  {
    code: 1010,
    reason: "ForgetPasswordRequestFailed",
    message: "Request for forgot password failed!",
  },
  {
    code: 1011,
    reason: "FailedToFetchUserList",
    message: "User list not available!",
  },
  {
    code: 1012,
    reason: "FailedToGetUser",
    message: "User data could not be fetched!",
  },
  {
    code: 1013,
    reason: "FailedToFetchUserType",
    message: "User data could not be fetched!",
  },
  {
    code: 1014,
    reason: "InvalidInput",
    message: "Invalid input!",
  },
  {
    code: 1015,
    reason: "FailedToDeleteUser",
    message: "User deletion failed!",
  },
  {
    code: 1016,
    reason: "EmailIdAlreadyExist",
    message: "User already exists! Enter another email Id.",
  },
  {
    code: 1017,
    reason: "UserNameAlreadyExist",
    message: "User already exists! Enter another email Id.",
  },
  {
    code: 1018,
    reason: "InvalidManagerId",
    message: "Invalid manager!",
  },
  {
    code: 1019,
    reason: "MarketNameAlreadyExists",
    message: "Market name already exists! Enter another one.",
  },
  {
    code: 1020,
    reason: "UserNotFound",
    message: "User could not be found!",
  },
  {
    code: 1021,
    reason: "NoOfSeatsExceeded",
    message: "Number of users exceeded!",
  },
  {
    code: 1022,
    reason: "MoreNumberUsersExistInCompany",
    message: "Please enter more seats than what was assigned previously.",
  },
  {
    code: 1023,
    reason: "AccountInactive",
    message:
      "Your account is inactive. Please contact the admin for more details!",
  },
  {
    code: 2001,
    reason: "FailedToCreateMarket",
    message: "Market creation failed!",
  },
  {
    code: 2002,
    reason: "FailedToFetchZipcodes",
    message: "Zip codes could not be fetched. Kindly refresh!",
  },
  {
    code: 2003,
    reason: "SubMarketNameAlreadyExists",
    message: "Submarket name already exists! Enter another one.",
  },
  {
    code: 2004,
    reason: "FailedToCreateSubMarket",
    message: "Submarket creation failed!",
  },
  {
    code: 2005,
    reason: "FailedToFetchCompanyUsers",
    message: "Failed to fetch user list!",
  },
  {
    code: 2006,
    reason: "FailedToFetchMarketList",
    message: "Failed to fetch market list!",
  },
  {
    code: 2007,
    reason: "FailedToFetchSubMarketList",
    message: "Failed to fetch submarket list!",
  },
  {
    code: 2008,
    reason: "FailedToDeleteMarket",
    message: "Market deletion failed!",
  },
  {
    code: 2009,
    reason: "InvalidMarket",
    message: "Market not valid!",
  },
  {
    code: 2010,
    reason: "FailedToGetMarket",
    message: "Market data could not be fetched!",
  },
  {
    code: 2011,
    reason: "SubMarketOrBucketExists",
    message:
      "Overlapping submarket or bucket. Please check availability of a bucket before saving.",
  },
  {
    code: 2012,
    reason: "InvalidParentSubMarket",
    message: "Parent submarket is invalid.",
  },
  {
    code: 2013,
    reason: "InvalidSubMarket",
    message: "Submarket is invaild.",
  },
  {
    code: 2014,
    reason: "SubMarketProValuesNotMatchWithMarketProValues",
    message: "Submarket size should fall within the chosen parent market!",
  },
  {
    code: 2015,
    reason: "SubMarketProSizeNotMatchWithMarketProSize",
    message: "Submarket size should fall within the chosen parent market!",
  },
  {
    code: 2016,
    reason: "InvalidSubMarketZipCode",
    message: "This zipcode is not a part of the submarket.",
  },
  {
    code: 2017,
    reason: "ParentMarketZipCodesNotFound",
    message: "Parent market details not found.",
  },
  {
    code: 2018,
    reason: "FailedToDeleteSubMarket",
    message: "Submarket deletion failed!",
  },
  {
    code: 2019,
    reason: "FailedToDeleteBucket",
    message: "Bucket deletion failed!",
  },
  {
    code: 2020,
    reason: "MarketNotFound",
    message: "Market not found!",
  },
  {
    code: 2021,
    reason: "SubMarketNotFound",
    message: "Submarket not found!",
  },
  {
    code: 2022,
    reason: "BucketNotFound",
    message: "Bucket not found!",
  },
  {
    code: 2023,
    reason: "MarketOverlapping",
    message: "Market overlaps with existing market.",
  },
  {
    code: 2024,
    reason: "AtleastOneZipcodeAssignedToMarket",
    message: "Atleast one zipcode should be assigned to a market!",
  },
  {
    code: 2025,
    reason: "FailedToRemoveZipCode",
    message: "Failed to remove zipcode!",
  },
  {
    code: 2026,
    reason: "FailedToUpdateMarket",
    message: "Market update failed!",
  },
  {
    code: 2027,
    reason: "FailedToRemoveCompanyUser",
    message: "User could not be removed!",
  },
  {
    code: 3001,
    reason: "FailedToCreateCompanyApiSettings",
    message: "Settings could not be saved!",
  },
  {
    code: 3002,
    reason: "FailedToCreateUserNotificationSettings",
    message: "User notifications could not be saved!",
  },
  {
    code: 3003,
    reason: "FailedToUpdateUserNotificationSettings",
    message: "User notifications could not be saved!",
  },
  {
    code: 3004,
    reason: "FailedToUpdateCompanyApiSettings",
    message: "Company API usage could not be fetched!",
  },
  {
    code: 3005,
    reason: "FailedToUpdateCompanyTemplateSettings",
    message: "Company template settings could not be saved!",
  },
  {
    code: 3006,
    reason: "FailedToUpdateUserEmailSettings",
    message: "Email settings could not be saved!",
  },
  {
    code: 3007,
    reason: "FailedToFetchUserSettings",
    message: "User settings could not be fetched!",
  },
  {
    code: 3008,
    reason: "FailedToFetchNotificationEventTypes",
    message: "Notification events could not be fetched!",
  },
  {
    code: 3009,
    reason: "FailedToFetchEmailAccountTypes",
    message: "Email account types could not be fetched!",
  },
  {
    code: 3010,
    reason: "FailedToFetchApiProviders",
    message: "Failed to fetch API list!",
  },
  {
    code: 3011,
    reason: "CompanyNameAlreadyExists",
    message: "Company name already exists! Enter another one.",
  },
  {
    code: 3013,
    reason: "FailedToCheckSubMarketAvailability",
    message: "Failed to check availability! Please try again.",
  },
  {
    code: 3015,
    reason: "TemplateNameAlreadyExists",
    message: "Template name already exists! Enter another one.",
  },
  {
    code: 3023,
    reason: "BucketProValuesNotMatchWithSubMarketProValues",
    message: "Bucket price should fall within the chosen parent submarket!",
  },
  {
    code: 3024,
    reason: "BucketProSizeNotMatchWithSubMarketProSize",
    message: "Bucket size should fall within the chosen parent submarket!",
  },
  {
    code: 1024,
    reason: "HomeOwnerDataAccessLimitExceeded",
    message: "Data limit over! Contact admin to increase the limit.",
  },
  {
    code: 4001,
    reason: "APIUsageError",
    message: "Please Select API and Company.",
  },
  {
    code: 5005,
    reason: "NotEnoughPasswordLength",
    message: "Password should be atlest 8 characters.",
  },
  {
    code: 5006,
    reason: "InvalidHomeOwnerDataAccessLimit",
    message: "Home owner data access should be one or more.",
  },
  {
    code: 1025,
    reason: "Owner details not available from source!",
    message: "Owner details not available from source!",
  },
  {
    code: 5009,
    reason: "Either the username or app password is incorrect!",
    message: "Either the username or app password is incorrect!",
  },
  {
    code: 5010,
    reason: "Invalid Image!",
    message: "Image dimension does not meet the criteria!",
  },
  {
    code: 5011,
    reason: "Invalid Post Card!",
    message: "Postcard front side and back side is mandatory!",
  },
  {
    code: 5012,
    reason: "Invalid Post Card!",
    message: "Please upload files of the following format  JPEG/PNG!",
  },
  {
    code: 5018,
    reason: "ExceedReportDataLimit",
    message: "Records exceed 50,000 limit. Adjust filter options and try again.",
  },
  {
    code: 6013,
    reason: "Invalid Post Card!",
    message: "File size should not exceed 5Mb.",
  },
  {
    code: 6001,
    reason: "Zip code count limit",
    message: "You are only allowed to select maximum of 5 zip codes.",
  },
  {
    code: 5013,
    reason: "Realtor data could not be fetched. Please try again!",
    message: "Realtor data could not be fetched. Please try again!",
  },
];
