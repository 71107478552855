import React from "react";
import { Modal } from "react-bootstrap";

const Confirm = ({ show, title = null, message, onYes, onNo }) => {
    return (
        <Modal show={show ? true : false} onHide={onNo}>
            {title ? (
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            ) : null}
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-default"
                    onClick={onNo}
                >
                    No
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onYes}
                >
                    Yes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default Confirm;
