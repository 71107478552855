import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";

import Tooltip from "../../utils/tooltip";
import { changePassword, toggleMainLoader } from "../../store/actions";
import FormGroup from "../../utils/formGroup";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { required, passwordStrength } from "../../constant/formValidators";

const ChangePasswordForm = ({
	changePassword,
	handleSubmit,
	formValues,
	logout,
	onCancel,
	toggleMainLoader,
}) => {
	const [error, setError] = useState(null);

	const submitForm = (values) => {
		if (values && values.newPassword === values.confirmPassword) {
			toggleMainLoader(true);
			const successHandler = () => {
				successMessage(1003);
				logout && logout();
				toggleMainLoader(false);
			};
			const errorHandler = (event) => {
				errorMessage(event);
				toggleMainLoader(false);
			};
			return values && values.currentPassword && values.newPassword
				? changePassword(values, successHandler, errorHandler)
				: null;
		} else {
			errorMessage("PASSWORD_NOT_MATCH");
		}
	};

	return (
		<form
			className="change-password-form"
			onSubmit={handleSubmit(submitForm)}>
			<div className="d-flex align-items-center mb-3">
				<h3 className="mb-0 mr-2 flex-fill color-1 text-uppercase">
					Change password
				</h3>
				<Tooltip
					component={
						<span className="btn btn-cell btn-xs btn-info">
							<i className="icon icon-info" />
						</span>
					}
					message="Password should contain a number, a special character, an upper & lower case alphabet and should be minimum 8 characters long. Blank space is not accepted"
				/>
			</div>
			<Field
				name="currentPassword"
				label="Old Password"
				type="password"
				onChange={() => setError(null)}
				component={FormGroup}
				validate={[required]}
			/>
			<Field
				name="newPassword"
				label="New Password"
				type="password"
				onChange={() => setError(null)}
				component={FormGroup}
				validate={[required, passwordStrength]}
			/>
			<Field
				name="confirmPassword"
				label="Confirm Password"
				type="password"
				matchWith={{ formValues, field: "newPassword" }}
				showPassword={false}
				onChange={() => setError(null)}
				component={FormGroup}
				validate={[required]}
			/>
			<div className="d-flex flex-row-reverse align-items-center mt-4">
				<button className="btn btn-sm btn-primary" type="submit">
					Submit
				</button>
				<button
					className="btn btn-sm btn-default mr-2"
					type="button"
					onClick={onCancel}>
					Cancel
				</button>
			</div>
			{error && (
				<div className="error small text-danger text-center mt-3">
					{error}
				</div>
			)}
		</form>
	);
};

const mapStateToProps = (state) => ({
	formValues: getFormValues("ChangePasswordForm")(state),
});

const mapDispatchToProps = {
	changePassword,
	toggleMainLoader,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "ChangePasswordForm",
	})(ChangePasswordForm)
);
