import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Field, reduxForm } from "redux-form";
import FormGroup from "../../utils/formGroup";
import { successMessage } from "../../utils/successMessage";
import { processReport, getReportTimeFrameList } from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { downloadFile } from "./helper";

const ConversionReport = (props) => {
	const {
		userDetails,
		processReport,
		handleSubmit,
		toggleMainLoader,
		getReportTimeFrameList,
		change,
	} = props;

	const [timeFrameList, settimeFrameList] = useState({});

	useEffect(() => {
		if (userDetails && userDetails.userId) {
			getReportTimeFrameList((data) => {
				change("timeFrame", data.result[0]);
				settimeFrameList(data.result);
			});
		}
	}, [userDetails, getReportTimeFrameList, change]);
	const submitForm = (values = null) => {
		toggleMainLoader(true);

		const successHandler = (data) => {
			toggleMainLoader(false);
			if (values.actionType === "download") {
				downloadFile(data.result, `Moovsoon_conversionreport`);
			} else {
				successMessage(7001);
			}
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};

		const postData = {};
		postData.timeFrame = values.timeFrame
			? Number(values.timeFrame.value)
			: 0;
		postData.responseType = values.actionType === "download" ? 1 : 2;
		postData.reportType = "conversion";
		processReport(postData, successHandler, errorHandler);
	};

	const renderSubmitButton = (type, label) => {
		return (
			<button
				type="button"
				onClick={handleSubmit((values) =>
					submitForm({
						...values,
						actionType: type,
					})
				)}
				className="btn btn-primary btn-md action-button mr-4">
				<i className={`pr-2 icon icon-${type}`}></i>
				<span>{label}</span>
			</button>
		);
	};

	const filterOption = [
		{
			name: "timeFrame",
			label: "Time Frame",
			key: "status",
			type: "select",
			options: timeFrameList,
			defaultValue: timeFrameList[0],
			formGroupClass: "col-4 pr-2",
		},
	];

	return (
		<>
			<form className="report-form" onSubmit={handleSubmit(submitForm)}>
				<div className="card card-primary px-4 pt-2 pb-1 mb-3 p-4">
					<div className="panel-switch  active">
						<h3 className="color-1 text-uppercase">Filter</h3>
						<div className="form-container">
							<div className="row">
								{filterOption.map((item, index) => (
									<Field
										key={index}
										{...item}
										component={FormGroup}
									/>
								))}
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex flex-row justify-content-end">
					{renderSubmitButton("email", "Email")}
					{renderSubmitButton("download", "Download")}
				</div>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userDetails: get(state, "Auth.userDetails", {}),
	};
};

const mapDispatchToProps = {
	processReport,
	getReportTimeFrameList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "ConversionReportForm",
		enableReinitialize: true,
	})(ConversionReport)
);
