import React, { useState } from "react";
import SMSTemplate from "../../../utils/manualProspecting/smsTemplate";
import SMSTemplateFromList from "../../../utils/manualProspecting/smsTemplateFromList";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

const SMSPanel = (props) => {
	const {
		realtorId,
		sendManualProspecting,
		dispatch,
		reloadCommunicationFeed,
		toggleMainLoader,
	} = props;

	const onSubmit = (values) => {
		toggleMainLoader(true);
		const postData = {
			templateType: "sms",
			prospectingSource: 2,
			realtorId: realtorId,
			prospectingType: 1,
			notificationTitle: "",
			notificationData: values.templateSMSBody,
			prospectingUserId: realtorId,
		};
		sendManualProspecting(
			postData,
			() => {
				toggleMainLoader(false);
				successMessage(6007);
				dispatch({ showSMSPanel: false });
				dispatch({ reloadCommunicationFeed: !reloadCommunicationFeed });
			},
			(data) => {
				toggleMainLoader(false);
				dispatch({ showSMSPanel: false });
				errorMessage(data);
			}
		);
	};
	const [prospectingType, setProspectingType] = useState(false);
	return prospectingType === 1 ? (
		<SMSTemplate
			{...{
				onSubmit,
				parentDispatch: dispatch,
			}}
		/>
	) : prospectingType === 2 ? (
		<SMSTemplateFromList
			{...{
				onSubmit,
				parentDispatch: dispatch,
				prospectingSource: 2,
				realtorId: realtorId,
				prospectingType: 1,
				prospectingUserId: realtorId,
			}}
		/>
	) : (
		<div className="create-template-wrap">
			<div className="d-flex align-items-center px-4 py-3 bb-1">
				<h2 className="color-1 m-0 flex-fill">Compose SMS</h2>
				<button
					type="button"
					className="btn btn-default btn-sm"
					onClick={() =>
						dispatch({
							showSMSPanel: false,
						})
					}>
					Close
				</button>
			</div>
			<div className="row manual-option-wrap mr-2 ml-2">
				<div className="col">What would you like to do ?</div>
			</div>
			<div className="row manual-option-wrap mr-2 ml-2">
				<div
					className="col md-6 manual-option-wrap-option text-center ml-3 hand"
					onClick={() => setProspectingType(1)}>
					Compose Now
				</div>
				<div
					className="col md-6 manual-option-wrap-option text-center hand"
					onClick={() => setProspectingType(2)}>
					Select From Templates
				</div>
			</div>
		</div>
	);
};
export default SMSPanel;
