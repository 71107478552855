import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Link } from "react-router-dom";

import {
	sendLoginCredentials,
	updateLoginCredentials,
} from "../../store/actions";

import FormGroup from "../../utils/formGroup";
import { required } from "../../constant/formValidators";

const ChangePassword = ({ handleSubmit, formValues }) => {
	const [error] = useState(null);

	const submitForm = (values) => {};

	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container">
				<form
					className="reset-password-form"
					onSubmit={handleSubmit(submitForm)}
				>
					<h3>Change Password</h3>
					<Field
						name="oldPassword"
						label="Old Password"
						type="password"
						validate={[required]}
						showPassword={false}
						component={FormGroup}
					/>
					<Field
						name="newPassword"
						label="New Password"
						type="password"
						validate={[required]}
						component={FormGroup}
					/>
					<Field
						name="confirmPassword"
						label="Confirm Password"
						validate={[required]}
						matchWith={{ formValues, field: "newPassword" }}
						type="password"
						showPassword={false}
						component={FormGroup}
					/>
					<button type="submit">Submit</button>
					{error && (
						<div className="error small text-danger text-center mt-3">
							{error}
						</div>
					)}
					<Link to="/signin">Login</Link>
					<Link to="/forgot-password">Forgot password?</Link>
				</form>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	formValues: getFormValues("ChangePasswordForm")(state),
});

const mapDispatchToProps = {
	sendLoginCredentials,
	updateLoginCredentials,
};

export default reduxForm({
	form: "ChangePasswordForm",
})(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
