import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import { getUsers, toggleMainLoader, resetField } from "../../../store/actions";

import { Table, Pagination } from "../../../partials/table/table";
import { columns as columnsFn } from "../listing/helper";
import TableLoader from "../../../utils/tableLoader";
import { errorMessage } from "../../../utils/errorMessage";
import SearchField from "../../../utils/searchTable";

const UserDetails = ({
    data,
    history,
    listOf,
    getUsers,
    toggleMainLoader,
    userList,
    recordsTotal,
    resetField,
    userDetails
}) => {
    const onSelectUser = event => {
        resetField("User.userList", null);
        resetField("User.recordsTotal", null);
        resetField("User.selectedUserDetails", null);
        history.push(`/users/${event.userId}/view`);
    };

    const columns = columnsFn({
        onSelectUser,
        userDetails,
        detailsView: true,
        listOf
    });

    const [filters, setFilters] = useState({
        pageIndex: 1,
        pageSize: 10,
        sortColumn: columns[0].accessor,
        sortDirection: "asc",
        userId: data.userId,
        userType: data.userType,
        searchKey: ""
    });

    const [queryObj, setQueryObj] = useState(null);

    useEffect(() => {
        setQueryObj(filters);
    }, [filters]);

    useEffect(() => {
        const successHandler = event => {
            toggleMainLoader(false);
        };
        const errorHandler = event => {
            errorMessage(event);
            toggleMainLoader(false);
        };
        queryObj &&
            queryObj.userId &&
            getUsers(queryObj, successHandler, errorHandler);
    }, [queryObj, getUsers, toggleMainLoader]);

    return (
        <div className="list-inner mt-3">
            <div className="d-flex align-items-center mb-2">
                <h2 className="mr-3 mb-0">User Listing</h2>
                <SearchField
                    initialValue={filters.searchKey}
                    onSearch={searchKey =>
                        setFilters({
                            ...filters,
                            searchKey,
                            pageIndex: 1
                        })
                    }
                    className="flex-fill mr-3 form-group mb-0"
                />
                {/* {renderAddUserBtn()} */}
            </div>
            {userList && userList.length ? (
                <div>
                    <Table
                        columns={columns}
                        data={userList}
                        onSort={e =>
                            e.by !== filters.sortColumn ||
                            e.order !== filters.sortDirection
                                ? setFilters({
                                      ...filters,
                                      sortColumn: e.by,
                                      sortDirection: e.order
                                  })
                                : null
                        }
                    />
                    <Pagination
                        total={recordsTotal}
                        count={filters.pageSize}
                        page={filters.pageIndex}
                        onChange={e => {
                            toggleMainLoader(true);
                            setFilters({
                                ...filters,
                                pageIndex: e
                            });
                        }}
                    />
                </div>
            ) : null}
            <TableLoader list={userList} />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userDetails: get(state, "Auth.userDetails", {}),
        userList: get(state, "User.userList.data", null),
        recordsTotal: get(state, "User.userList.recordsTotal", 0)
    };
};

const mapDispatchToProps = {
    getUsers,
    toggleMainLoader,
    resetField
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
