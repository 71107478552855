import fetchHandler from "../../utils/fetchHandler";
import { MARKET } from "../types";

export const createMarket = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: values.marketId ? `market/create-submarket` : `market/create`,
		method: "POST",
		secure: true,
		actionType: MARKET.CREATE_MARKET,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateMarket = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: values.subMarketId
			? `market/update-submarket`
			: `market/update-market`,
		method: "POST",
		secure: true,
		actionType: MARKET.UPDATE_MARKET,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getMarkets = (values, successHandler, errorHandler) => {
	const url =
		(values.marketId && values.userType === "Admin") ||
		values.userType !== "Admin"
			? `market/submarket-search-list`
			: `market/market-search-list`;
	const fetchOptions = {
		url,
		method: "POST",
		secure: true,
		actionType: MARKET.GET_MARKETS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getMarket = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: values.subMarketId
			? `market/get-sub-market?subMarketId=${values.subMarketId}`
			: `market/get-market?marketId=${values.marketId}`,
		method: "GET",
		secure: true,
		actionType: MARKET.GET_MARKET_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteMarket = (values, successHandler, errorHandler) => {
	const query = values.subMarketId
		? `subMarketId=${values.subMarketId}&subMarketType=${values.subMarketType}`
		: `marketId=${values.marketId}`;
	const fetchOptions = {
		url: `market/delete-market?${query}`,
		method: "DELETE",
		secure: true,
		actionType: MARKET.DELETE_MARKET,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getMarketZipcodes = (
	values = {},
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `market/zipcodes`,
		method: "POST",
		secure: true,
		actionType: MARKET.GET_MARKET_ZIPCODES,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getMarketCompanies = (
	values = {},
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: values.marketType && values?.subMarketId
		 ?
		 `market/company-users${
			values.marketType ? `?marketType=${values.marketType}&subMarketId=${values.subMarketId}`:''
		}`
		:
		`market/company-users${values.marketType ? `?marketType=${values.marketType}` :''}`
		,
		method: "POST",
		secure: true,
		actionType: MARKET.GET_MARKET_COMPANIES,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCompanyMarkets = (values, successHandler, errorHandler) => {
	const companyUserId = values.companyUserId
	const fetchOptions = {
		url:
			values.userType && values.userType === "SalesManager"
				? `market/submarket-list`
				: `market/markets?${companyUserId ? `companyUserId=${companyUserId}`:""}`,
		method: "POST",
		secure: true,
		actionType: MARKET.GET_COMPANY_MARKETS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const clearCompanyMarkets = () => {
	return {
		type: MARKET.CLEAR_MARKET_COMPANIES,
		payload:null
	}
}

export const getCompanySubMarkets = (
	marketId,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `market/submarket-list?marketId=${marketId}`,
		method: "POST",
		secure: true,
		actionType: MARKET.GET_COMPANY_SUB_MARKETS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const checkAvailability = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `market/submarket-check-availability`,
		method: "POST",
		secure: true,
		actionType: MARKET.SUB_MARKET_CHECK_AVAILABILITY,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getmarketTemplateProspectingEvents = (
	templateType,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `market/get-prospecting-events-list?outboundCommunicationType=${templateType}`,
		method: "GET",
		secure: true,
		actionType: MARKET.GET_TEMPLATE_PROSPECTING_EVENTS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getAllmarketTemplates = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `market/get-all-prospecting-templates-list-of-company`,
		method: "GET",
		secure: true,
		actionType: MARKET.GET_ALL_MARKET_TEMPLATES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setAutoProspectingInitial = (data) => {
	return {
		type: MARKET.SET_SUB_MARKET_PROSPECTING,
		payload: data,
	};
};
