import fetchHandler from "../../utils/fetchHandler";
import { NOTIFICATION } from "../types";

export const getInAppNotifications = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `user/get-all-user-inapp-notifications`,
		method: "GET",
		secure: true,
		actionType: NOTIFICATION.GET_IN_APP_NOTIFICATIONS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getInAppNotificationDetails = (
	values,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `user/get-notification-details-by-id?userNotificationLogId=${values.userNotificationLogId}`,
		method: "GET",
		secure: true,
		actionType: NOTIFICATION.GET_IN_APP_NOTIFICATION_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
