import React from "react";
import { Currency, Date } from "../../../utils/formatter";
import InprogressBlock from "./InprogressBlock";
import { dateFormat } from "../../../constant/global";
import ownerOccupied from "../../../assets/owner-occupied.svg"
import ownerNotOccupied from "../../../assets/owner-not.svg"
import Tooltip from "../../../utils/tooltip";

const PropertyInfoBlock = ({
	propertyDetails,
	updateSettings,
	loggedInAs,
	propertySettings,
	propertyOwnerInfo,
	
}) => {
	return (
		<>
			<div
				className={`d-flex flex-column ml-4 bb-1 pt-0 mb-1 ${
					typeof loggedInAs === "function" && loggedInAs("AD")
						? "h-240"
						: "h-180"
				}`}
			>
				<div className="mb-2">
					{propertyOwnerInfo?.ownerInfo?.isOwnerOccupied &&
					<Tooltip
					message = "Owner Occupied"
					component={
						<img src={ownerOccupied}/>
					}
					placement="right"
					/> }
					{propertyOwnerInfo?.ownerInfo?.isOwnerOccupied === false && <Tooltip
					message = "Not Owner Occupied"
					component={
						<img src={ownerNotOccupied}/>
					}
					placement="right"
					/>

				}
					
				</div>
				<div className="d-flex flex-row">
					<h2 className="w-60 pr-2">{propertyDetails?.address}</h2>

		{typeof loggedInAs === "function" && !loggedInAs("AD") ? (
						<InprogressBlock
							initialValues={propertyDetails}
							updateSettings={updateSettings}
							loggedInAs={loggedInAs}
							propertySettings={propertySettings}
						/>
					) : null}
				</div>
				<div className="d-flex flex-row">
					<div className="w-100 d-flex flex-row">
						<div className="d-flex flex-column-reverse">
							<h1>
								<Currency
									amount={propertyDetails.price}
									currency="USD"
								/>
							</h1>
							<span className="label-text">Listing Price</span>
						</div>
						<div className="d-flex flex-column-reverse">
							<span className="label-text mb-2">
								<span className="pl-1">
									(
									<Currency
										amount={propertyDetails.pricePerSqft}
										currency="USD"
									/>
									<span className="pl-1">Per Sqft</span>)
								</span>
							</span>
							<span className="label-text"></span>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row">
					<div className="d-flex flex-column-reverse w-25">
						<div>
							{propertyDetails.nummberOfBaths}
							<span className="pl-1 font-weight-normal">
								Baths
							</span>
						</div>
						<div className="mt-2">
							<i className="icomoon icomoon icon-bath"></i>
						</div>
					</div>
					<div className="d-flex flex-column-reverse w-25">
						<div>
							{propertyDetails.numberOfBeds}
							<span className="pl-1 font-weight-normal">
								Beds
							</span>
						</div>
						<div className="mt-2">
							<i className="icomoon icomoon icon-bedroom"></i>
						</div>
					</div>
					<div className="d-flex flex-column-reverse w-25">
						<div>
							{new Intl.NumberFormat("en-IN", {
								maximumSignificantDigits: 3,
							}).format(propertyDetails.squareFeet)}
							<span className="pl-1 font-weight-normal">
								Sqft
							</span>
						</div>
						<div className="mt-2">
							<i className="icomoon icomoon icon-area"></i>
						</div>
					</div>
					<div className="d-flex flex-column-reverse w-25">
						<div>
							{new Intl.NumberFormat("en-IN", {
								maximumSignificantDigits: 3,
								maximumFractionDigits: 3,
								minimumFractionDigits: 3,
							}).format(propertyDetails.plotArea)}
						</div>
						<div className="mt-2">
							<i className="icomoon icomoon icon-fence"></i>
						</div>
					</div>
				</div>
			</div>
			<div className="ml-4 bb-1 pt-0 mb-1 h-60 d-flex flex-column">
				<div className="d-flex flex-row">
					<div className="w-50 d-flex flex-column">
						<span className="label-text">Property Type</span>
						<span>{propertyDetails.propertyType}</span>
					</div>

					<div className="w-50 d-flex flex-row">
						<span className="bl-1"></span>
						<div className="d-flex flex-column pl-4">
							<span className="label-text">
								Source's property ID
							</span>
							<span>{propertyDetails.dataSourcePropertyId}</span>
						</div>
					</div>
				</div>
			</div>
			<div className="ml-4 bb-1 pt-0 mb-1 h-60 d-flex flex-colum">
				<div className="w-60 d-flex flex-column">
					<span className="label-text">Last Sold Year</span>

					<span className="break-word">
						{propertyDetails.lastSoldDate ? (
							<>
								<Currency
									amount={propertyDetails.lastSoldPrice}
									currency="USD"
								/>
								<span className="pl-1 pr-1">in</span>

								<Date
									utcTime={propertyDetails.lastSoldDate}
									format={dateFormat}
								/>
							</>
						) : (
							`No Data`
						)}
					</span>
				</div>
				<div className="w-40 d-flex flex-column">
					<span className="label-text">Year Built</span>
					<span>{propertyDetails.yearBuilt}</span>
				</div>
			</div>
			<div className="pt-0 h-48 ml-4 d-flex flex-row align-items-center">
				<div className="w-50 d-flex flex-row align-items-center">
					<span className="label-text">Days on Market</span>
					<span className="ml-2">
						{propertyDetails.daysOnrealtor}
					</span>
				</div>

				<div className="w-50 d-flex flex-row">
					<span className="label-text">Date Added</span>
					<span className="ml-2 mt-1">
						{
							<Date
								utcTime={propertyDetails.dateAdded}
								format={dateFormat}
							/>
						}
					</span>
				</div>
			</div>
		</>
	);
};
export default PropertyInfoBlock;
