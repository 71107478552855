import React, { useReducer } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import FormGroup from "../formGroup";
import { uploadPostCardImage } from "../../store/actions";
import { mailerTemplateHTML } from "../../components/templates/create/mailerTemplateHTML";
import "../../components/templates/templates.scss";
import { Button } from "../uiElements";
import { errorMessage } from "../errorMessage";
const initialStates = {
  mailerBodyImage: null,
  mailerFooterText: null,

  messageType: 2,
  footerType: 0,

  previewData: null,
  postCardFrontImage: null,
  templateMailerFooterText: null,
  postCardBackImage: null,
  templateMailerFooterImage: null,
  imageUploading: {},
  templateSize: "1",
  selectedSize: { label: '4"x6"', value: "1" },
  mailerMinHeight: 1275,
  mailerMinWidth: 1875,
  isPreview: false,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const MailerTemplate = ({
  data,
  change,
  uploadPostCardImage,
  formValues,
  handleSubmit,
  onSubmit,
  parentDispatch,
  mailerSizeOptions,
  propertyDetails,
}) => {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const {
    previewData,
    postCardFrontImage,
    postCardBackImage,
    imageUploading,
    selectedSize,
    templateSize,
    mailerMinHeight,
    mailerMinWidth,
    isPreview,
  } = state;

  const onPostCardImageChange = (e, field) => {
    //1875, 1275
    if (
      (e[0].height && e[0].height < mailerMinHeight) ||
      (e[0].width && e[0].width < mailerMinWidth)
    ) {
      setTimeout(function () {
        change(field, null);
      }, 2000);

      errorMessage(5010);
      return;
    }
    dispatch({ imageUploading: { ...imageUploading, [field]: true } });
    const data = {
      base64Image: e[0].base64.replace("data:image/jpeg;base64,", ""),
      fileExtension: ".jpeg",
      contentType: "image/jpeg",
    };
    const successHandler = (e) => {
      change(field, e.result);
    };
    uploadPostCardImage(data, successHandler);
  };

  const formFields = {
    postCardFrontImage: {
      name: "postCardFrontImage",
      type: "file",
      multiple: false,
      resize: false,
      resizeImage: [mailerMinWidth, mailerMinHeight],
      minWidth: mailerMinWidth,
      minHeight: mailerMinHeight,
      disableThumbnail: true,
      onChange: (e) => onPostCardImageChange(e, "postCardFrontImage"),
      onFileError: (e) => {
        if (
          (e[0].height && e[0].height < mailerMinHeight) ||
          (e[0].width && e[0].width < mailerMinWidth)
        ) {
          errorMessage(5010);
        }
        if (e[0].invalidType) {
          errorMessage(5012);
        }
        if (e[0].invalidSize) {
          errorMessage(6013);
        }
      },
      imageOnly: true,
      maxSize: 6 * 1024 * 1024,
      defaultValue: postCardFrontImage,
      formGroupClass: `post-card-front-image-dropzone ${
        imageUploading["postCardFrontImage"] ? "hide" : ""
      }`,
      placeholder: (
        <>
          <div className="text-md mb-1">Drop file here.</div>
          <div className="text-gray">
            <i>
              {`Images should be 300 dpi or higher. For Example: ${
                templateSize === "1"
                  ? "1275px x 1875px"
                  : templateSize === "2"
                  ? "1875px x 2775px"
                  : "1875px x 3375px"
              }
              image used to create a ${
                templateSize === "1"
                  ? '4.25" x 6.25"'
                  : templateSize === "2"
                  ? '6.25" x 9.25"'
                  : '6.25" x 11.25"'
              } postcard has a dpi of 300.
              Your artwork should include a 1/8" border around the final trim
              size. This means your final file size will be a total of 0.25"
              larger than your expected printed piece (ie, a ${
                templateSize === "1"
                  ? '4" x 6"'
                  : templateSize === "2"
                  ? '6" x 9"'
                  : '6" x 11"'
              } postcard
              should be submitted as ${
                templateSize === "1"
                  ? '4.25" x 6.25"'
                  : templateSize === "2"
                  ? '6.25" x 9.25"'
                  : '6.25" x 11.25"'
              }). Also, ensure that the file
              size is not more than 5Mb.`}
            </i>
          </div>
        </>
      ),
    },
    postCardBackImage: {
      name: "postCardBackImage",
      type: "file",
      multiple: false,
      formGroupClass: `post-card-back-image-dropzone m-0 ${
        imageUploading["postCardBackImage"] ? "hide" : ""
      }`,
      resize: false,
      resizeImage: [mailerMinWidth, mailerMinHeight],
      minWidth: mailerMinWidth,
      minHeight: mailerMinHeight,
      disableThumbnail: true,
      defaultValue: postCardBackImage,
      onChange: (e) => onPostCardImageChange(e, "postCardBackImage"),
      onFileError: (e) => {
        if (
          (e[0].height && e[0].height < mailerMinHeight) ||
          (e[0].width && e[0].width < mailerMinWidth)
        ) {
          errorMessage(5010);
        }
        if (e[0].invalidType) {
          errorMessage(5012);
        }
        if (e[0].invalidSize) {
          errorMessage(6013);
        }
      },
      imageOnly: true,
      maxSize: 6 * 1024 * 1024,
      placeholder: (
        <>
          <div className="text-md mb-1">Drop file here.</div>
          <div className="text-gray">
            <i>
              {`Images should be 300 dpi or higher. For Example: ${
                templateSize === "1"
                  ? "1275px x 1875px"
                  : templateSize === "2"
                  ? "1875px x 2775px"
                  : "1875px x 3375px"
              }
              image used to create a ${
                templateSize === "1"
                  ? '4.25" x 6.25"'
                  : templateSize === "2"
                  ? '6.25" x 9.25"'
                  : '6.25" x 11.25"'
              } postcard has a dpi of 300.
              Your artwork should include a 1/8" border around the final trim
              size. This means your final file size will be a total of 0.25"
              larger than your expected printed piece (ie, a ${
                templateSize === "1"
                  ? '4" x 6"'
                  : templateSize === "2"
                  ? '6" x 9"'
                  : '6" x 11"'
              } postcard
              should be submitted as ${
                templateSize === "1"
                  ? '4.25" x 6.25"'
                  : templateSize === "2"
                  ? '6.25" x 9.25"'
                  : '6.25" x 11.25"'
              }). Also, ensure that the file
              size is not more than 5Mb.`}
            </i>
          </div>
        </>
      ),
    },
  };

  const onPreview = () => {
    const templateBodyFull = mailerTemplateHTML({
      formValues,
      data,
      templateSize,
    })[0];
    dispatch({
      previewData: previewData
        ? null
        : { templateBodyFull, templateName: data.templateName },
      isPreview: previewData ? false : true,
    });
  };

  const loadingImage = (field) => (
    <div className={`is-uploading ${imageUploading[field] ? "show" : ""}`}>
      <div className="loader">Uploading</div>
    </div>
  );
  const isSelectDisabled =
    imageUploading["postCardFrontImage"] ||
    imageUploading["postCardBackImage"] ||
    isPreview
      ? true
      : false;
  const frontImage =
    formValues && formValues.postCardFrontImage
      ? formValues.postCardFrontImage
      : postCardFrontImage
      ? postCardFrontImage
      : null;

  const onSizeChange = (id) => {
    //id =1 => 4x6in => 1275px X 1875px
    //id =2 => 6x9in => 1875px X 2775px
    //id =3 => 6x11in => 1875px X 3375px
    const minHeight = id === "1" ? 1275 : 1875;
    const minWidth = id === "1" ? 1875 : id === "2" ? 2775 : 3375;
    change("postCardFrontImage", null);
    change("postCardBackImage", null);
    dispatch({
      templateSize: id,
      mailerMinHeight: minHeight,
      mailerMinWidth: minWidth,
    });
    parentDispatch({ templateSizeId: parseInt(id) });
  };
  return (
    <div className="create-template-wrap">
      <div className="d-flex align-items-center justify-content-between px-4 py-3 bb-1">
      <div className="d-flex flex-row align-items-center">
          <h2 className="color-1 m-0">Compose Mailer</h2>
          {propertyDetails?.address && <h3 className="color-1 m-0 flex-fill ml-2">-</h3>}
          <h3 className="color-1 m-0 flex-fill ml-1">{propertyDetails?.address}</h3>
        </div>
        <button
          type="button"
          className="btn btn-default btn-sm"
          onClick={() =>
            parentDispatch({
              showPostCardPanel: false,
            })
          }
        >
          Close
        </button>
      </div>

      <form className="flex-fill d-flex" onSubmit={handleSubmit(onSubmit)}>
        <div className="create-template-right">
          <div className="create-template-box">
            <div className="row mailer-template-select">
              {mailerSizeOptions ? (
                <Field
                  {...{
                    name: "templateSizeList",
                    label: "Choose Template Size",
                    type: "select",
                    options: mailerSizeOptions,
                    defaultValue: selectedSize,
                    disable: isSelectDisabled,
                    onChange: (value) => {
                      onSizeChange(value.value);
                    },
                    formGroupClass: "col-8",
                  }}
                  component={FormGroup}
                />
              ) : null}
              {frontImage || (formValues && formValues.postCardBackImage) ? (
                <button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick={onPreview}
                >
                  {previewData ? "Close " : ""}Preview
                </button>
              ) : null}
            </div>
            <div className="create-template-box-inner">
              <div
                className={`create-template-box-inner-box create-template-form ${
                  previewData ? "" : "show"
                }`}
              >
                <div className="d-flex flex-column align-items-center">
                  <div className="flex-fill mb-2">
                    <h3 className="color-1 text-uppercase">
                      Front View
                      <div className="text-gray template-tooltip">
                        <i>
                          <span
                            onClick={() => {
                              window.open(
                                templateSize === "1"
                                  ? "https://s3-us-west-2.amazonaws.com/public.lob.com/assets/templates/4x6_pc_template.pdf"
                                  : templateSize === "2"
                                  ? "https://s3-us-west-2.amazonaws.com/public.lob.com/assets/templates/postcards/6x9_postcard.pdf"
                                  : "https://s3-us-west-2.amazonaws.com/public.lob.com/assets/templates/postcards/6x11_postcard.pdf",
                                "_blank"
                              );
                            }}
                          >
                            Click here for guidelines
                          </span>
                        </i>
                      </div>
                    </h3>
                    <div className="post-card front-view">
                      {frontImage && (
                        <img
                          className="company-logo"
                          src={frontImage}
                          alt="Company Logo"
                          onLoad={(e) => {
                            dispatch({
                              imageUploading: {
                                ...imageUploading,
                                postCardFrontImage: false,
                              },
                            });
                          }}
                        />
                      )}
                      <Field
                        component={FormGroup}
                        {...formFields.postCardFrontImage}
                      />
                      {loadingImage("postCardFrontImage")}
                    </div>
                  </div>
                  <div className="flex-fill mt-2">
                    <h3 className="color-1 text-uppercase">Back View</h3>

                    <div className="post-card front-view">
                      {formValues && formValues.postCardBackImage ? (
                        <img
                          className="company-logo"
                          src={formValues.postCardBackImage}
                          alt="Post card back"
                          onLoad={(e) => {
                            dispatch({
                              imageUploading: {
                                ...imageUploading,
                                postCardBackImage: false,
                              },
                            });
                          }}
                        />
                      ) : null}
                      <Field
                        component={FormGroup}
                        {...formFields.postCardBackImage}
                      />
                      {loadingImage("postCardBackImage")}
                    </div>
                  </div>
                </div>
                <div className="form-footer text-right py-3">
                  <Button
                    className="btn btn-primary mt-2"
                    type="submit"
                    value="Send"
                  />
                </div>
              </div>
              <div
                className={`create-template-box-inner-box template-preview-panel ${
                  previewData ? "show" : ""
                }`}
              >
                <div className="text-gray mb-2 padding-left-30">
                  <i>
                    The green line indicates the safe area that will be printed
                    on the mailer.
                  </i>
                </div>
                {previewData ? (
                  <div
                    className="position-relative padding-left-30"
                    dangerouslySetInnerHTML={{
                      __html: previewData.templateBodyFull,
                    }}
                  ></div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = {
  uploadPostCardImage,
};

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: "mailerTemplateForm",
    enableReinitialize: true,
  })(MailerTemplate)
);
