import {
	facebookIcon,
	youtubeIcon,
	twitterIcon,
	linkedInIcon,
} from "../../constant/templateManagement";

export const emailTemplateHTML = ({ values, data }) => {
	const htmlLogo =
		((data && data.companyLogoURL) || (values && values.companyLogoURL)) &&
		values.templateEmailShowLogo
			? `<tr id="htmlTemplateEmailLogo">
            <td style="text-align: center;border-bottom: 1px solid #DCDCDC;">
                <div style="padding: 25px 25px 15px 25px;"><img src="${
					(values && values.companyLogoURL) ||
					(data && data.companyLogoURL)
				}" alt="Company Logo"></div>
            </td>
        </tr>`
			: "";

	const htmlTemplateEmailSubject =
		values && values.templateEmailSubject
			? `<tr>
            <td style="padding: 10px 45px;">
                <div style="font-weight: 400;font-size: 13px;" id="htmlTemplateEmailSubjectContainer">${
					values && values.templateEmailSubject
				}</div>
            </td>
        </tr>`
			: "";

	const htmlTemplateEmailBody =
		values && values.templateEmailBody
			? `<tr>
            <td style="padding: 10px 45px;">
                <div style="font-weight: 400;font-size: 13px;" id="htmlTemplateEmailBodyContainer">${
					values && values.templateEmailBody
				}</div>
            </td>
        </tr>`
			: "";
	const htmlKnowMore =
		data && data.website && values.templateEmailShowKnowMoreBtn
			? `<tr id="htmlTemplateEmailKnowMore">
            <td style="text-align: center;padding: 10px 45px;">
                <a href="${
					data && data.website
				}" target="_blank" style="display: block;text-decoration: none;margin: 0 auto;width: 170px;font-size: 13px;letter-spacing: 5.5px;background-color: #6E6D6F;color: #ffff;border:#6E6D6F;padding: 15px;border-radius:3px;">KNOW MORE</a>
            </td>
        </tr>`
			: "";
	const htmlTemplateEmailSignature =
		values && values.templateEmailSignature
			? `<tr>
            <td style="padding: 10px 45px;color: #000;float: left;font-size: 13px;">
                <label style="margin:0px;" id="htmlTemplateEmailSignatureContainer">${
					values && values.templateEmailSignature
				}</label>
            </td>
        </tr>`
			: "";
	const htmlTagline = `<tr>
            <td style="border-top: 1px solid #DCDCDC;color:#3C3F47;font-size: 14px;text-align: center;font-weight: 400;">
                <div style="padding: 10px 45px;">Stay up to date with our latest news &amp; features.</div>
            </td>
        </tr>`;
	const htmlFacebook =
		data && data.faceBookUrl && values.templateEmailShowFacebook
			? `<td id="htmlTemplateEmailFaceBookUrl" style="width: 140px;color: #899696;font-size: 14px;font-weight: 100;">
                    <img src="${facebookIcon}" style="margin-bottom: -2px;margin-right: 6px;" />&nbsp;
                    <a href="${data.faceBookUrl}">Facebook </a>
                </td>`
			: "";
	const htmlYoutube =
		data && data.youtubeUrl && values.templateEmailShowYoutube
			? `<td id="htmlTemplateEmailYoutubeUrl" style="width: 140px;color: #899696;font-size: 14px;font-weight: 100;">
                    <img src="${youtubeIcon}" style="margin-bottom: -2px;margin-right: 6px;" />&nbsp;
                    <a href="${data.youtubeUrl}">Youtube </a>
                </td>`
			: "";
	const htmlLinkedIn =
		data && data.linkedInUrl && values.templateEmailShowLinkedIn
			? `<td id="htmlTemplateEmailLinkedInUrl" style="width: 140px;color: #899696;font-size: 14px;font-weight: 100;">
                    <img src="${linkedInIcon}" style="margin-bottom: -2px;margin-right: 6px;" />&nbsp;
                    <a href="${data.linkedInUrl}">LinkedIn </a>
                </td>`
			: "";
	const htmlTwitter =
		data && data.twitterUrl && values.templateEmailShowTwitter
			? `<td id="htmlTemplateEmailTwitterUrl" style="width: 140px;color: #899696;font-size: 14px;font-weight: 100;">
                        <img src="${twitterIcon}" style="margin-bottom: -2px;margin-right: 6px;" />&nbsp;
                        <a href="${data.twitterUrl}">Twitter </a>
                    </td>`
			: "";

	const htmlSocialMedias =
		values.templateEmailShowSocialMedias &&
		data &&
		(data.faceBookUrl ||
			data.youtubeUrl ||
			data.linkedInUrl ||
			data.twitterUrl) &&
		(values.templateEmailShowFacebook ||
			values.templateEmailShowYoutube ||
			values.templateEmailShowLinkedIn ||
			values.templateEmailShowTwitter)
			? `${htmlTagline}<tr id="htmlTemplateEmailSocialMedias">
		<td style="padding: 10px 45px; text-align: center;">
        <table style="margin: 24px 0px;width: 100%;text-align: center">
        <tbody><tr>
                ${htmlFacebook + htmlYoutube + htmlLinkedIn + htmlTwitter}
                </tr>
                </tbody></table>
        </td>
    </tr>`
			: "";
	const createAddress =
		data && (data.companyName || data.address1 || data.address2)
			? data.companyName +
			  (data.address1 ? `, ${data.address1}` : "") +
			  (data.address2 ? `, ${data.address2}` : "")
			: "";
	const websiteUrlRow =
		data && data.website
			? `<tr>
	<td style="text-align: center;font-size: 13px;padding:5px 45px;">
		Interested to know more, visit <a href="${data.website}" style="text-decoration:none;" target="_blank">${data.website}</a>
	</td>
</tr>`
			: "";
	const htmlFooter = values.templateEmailShowFooter
		? `<tr  id="htmlTemplateEmailFooter">
                <td style="background:#eaeaea;padding: 10px 45px;">
                    <table style="width: 100%;">
                        <tbody><tr>
        <td style="text-align: center;font-size: 13px;padding:5px 45px;">
            <label style="color: #898989;margin:0;">${createAddress}
		  <br/>
		  Tel: ${data && data.officePhoneNo}</label>
        </td>
    </tr>
	${websiteUrlRow}
	</tbody>
                    </table>
                </td>
            </tr>`
		: "";

	return `<html lang="en">
    <head>
        <meta charset="utf-8" />
        <meta name="viewport content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link href="//fonts.googleapis.com/css2?family=Chilanka&family=Sriracha&display=swap" rel="stylesheet" />
        <link href="//fonts.googleapis.com/css?family=Indie+Flower" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins" rel="stylesheet">
        <title>Email Templates</title>
    </head>
    <body style="margin: 0;padding: 0;font-family: 'Poppins', sans-serif;"><table style="margin: 0;background: #ffff;width: 600px;">
        <tbody>
            ${
				htmlLogo +
				// htmlRecipientName +
				htmlTemplateEmailSubject +
				htmlTemplateEmailBody +
				htmlKnowMore +
				htmlTemplateEmailSignature +
				htmlSocialMedias +
				htmlFooter
			}
        </tbody>
    </table></body>
    </html>`;
};
