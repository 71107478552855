import React from "react";
import { Modal } from "react-bootstrap";

const InfoModal = ({
  show,
  title = null,
  message = null,
  onYes,
  className = "",
}) => {
  return (
    <Modal show={show ? true : false} onHide={onYes} className={className}>
      {title ? (
        <Modal.Header closeButton>
          <Modal.Title className="wordBreak">{title}</Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body className="wordBreak">{message}</Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onYes}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
