import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import FormGroup from "../../utils/formGroup";
import { successMessage } from "../../utils/successMessage";
import { processReport } from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { downloadFile } from "./helper";
import { getUtcDateFromDatePicker } from "../../utils/helper";

const OperationReport = (props) => {
	const { processReport, handleSubmit, toggleMainLoader } = props;

	const [selectedDate, setSelectedDate] = useState({});

	const submitForm = (values = null) => {
		toggleMainLoader(true);

		const successHandler = (data) => {
			toggleMainLoader(false);
			if (values.actionType === "download") {
				downloadFile(data.result, `Moovsoon_operationsreport`);
			} else {
				successMessage(7001);
			}
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};

		const postData = {};
		postData.fromDate = values.operationsdatefrom
			? getUtcDateFromDatePicker(values.operationsdatefrom)
			: "";
		postData.toDate = values.operationsdateto
			? getUtcDateFromDatePicker(values.operationsdateto)
			: "";
		postData.includeOffMarketProperties = values.includeOffMarket
			? true
			: false;
		postData.responseType = values.actionType === "download" ? 1 : 2;
		postData.reportType = "operation";
		processReport(postData, successHandler, errorHandler);
	};

	const renderSubmitButton = (type, label) => {
		return (
			<button
				type="button"
				disabled={
					!selectedDate ||
					!selectedDate.dateaddedfrom ||
					!selectedDate ||
					!selectedDate.dateaddedto
				}
				onClick={handleSubmit((values) =>
					submitForm({
						...values,
						actionType: type,
					})
				)}
				className="btn btn-primary btn-md action-button mr-4">
				<i className={`pr-2 icon icon-${type}`}></i>
				<span>{label}</span>
			</button>
		);
	};

	const filterOption = [
		{
			name: "operationsdatefrom",
			label: "Time Frame",
			placeholderText: "From Date",
			key: "operationsdatefrom",
			type: "datepicker",
			selectedDate:
				selectedDate && selectedDate.dateaddedfrom
					? selectedDate.dateaddedfrom
					: "",
			onChange: (value) => {
				setSelectedDate({
					...selectedDate,
					dateaddedfrom: value,
				});
			},
			maxDate:
				selectedDate && selectedDate.dateaddedto
					? selectedDate.dateaddedto
					: new Date(),
			formGroupClass: "col-2 pr-2",
			className: "form-control",
		},
		{
			name: "operationsdateto",
			key: "operationsdateto",
			label: "",
			placeholderText: "To Date",
			type: "datepicker",
			selectedDate:
				selectedDate && selectedDate.dateaddedto
					? selectedDate.dateaddedto
					: "",
			onChange: (value) => {
				setSelectedDate({
					...selectedDate,
					dateaddedto: value,
				});
			},
			minDate:
				selectedDate && selectedDate.dateaddedfrom
					? selectedDate.dateaddedfrom
					: "",
			maxDate: new Date(),
			formGroupClass: "col-2 pr-2",
		},
		{
			name: "includeOffMarket",
			label: "Include Off Market Properties",
			type: "boolean",
			formGroupClass: "w-100 ml-2",
		},
	];

	return (
		<>
			<form className="report-form" onSubmit={handleSubmit(submitForm)}>
				<div className="card card-primary px-4 pt-2 pb-1 mb-3 p-4">
					<div className="panel-switch  active">
						<h3 className="color-1 text-uppercase">Filter</h3>
						<div className="form-container">
							<div className="row">
								{filterOption.map((item, index) => (
									<Field
										key={index}
										{...item}
										component={FormGroup}
									/>
								))}
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex flex-row justify-content-end">
					{renderSubmitButton("email", "Email")}
					{renderSubmitButton("download", "Download")}
				</div>
			</form>
		</>
	);
};

const mapDispatchToProps = {
	processReport,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "OperationReportForm",
		enableReinitialize: true,
	})(OperationReport)
);
