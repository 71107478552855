import React from "react";
import { Field } from "redux-form";
import FormGroup from "../../utils/formGroup";

const Notifications = ({ userSettings, fields }) => {
  return userSettings ? (
    <>
      <div className="row mb-3 mt-4">
        <h3 className="color-1 text-uppercase col-4 m-0">Notifications</h3>
        <div className="col-2 text-center">InApp</div>
        <div className="col-2 text-center">SMS</div>
        <div className="col-2 text-center">Email</div>
      </div>
      {fields && fields.length ? (
        fields.map((item) => (
          <div key={item.id} className="row">
            <div className="col-4">{item.eventName}</div>
            <Field component={FormGroup} {...item.notificationEventTypeId} />
            {item.checkBoxes.map((checkbox, index) => (
              <Field key={index} component={FormGroup} {...checkbox} />
            ))}
          </div>
        ))
      ) : (
        <div className="alert alert-info">No settings available!</div>
      )}
    </>
  ) : null;
};

export default Notifications;
