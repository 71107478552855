import React from "react";

const Preloader = ({ show = false, className = "" }) => {
    return <div className={`preloader-wrap ${className} ${show ? 'show' : ''}`}>
        <div className="preloader-container">
            <div className="preloader-cow">
                <div className="cow-ear-left" />
                <div className="cow-ear-right" />
                <div className="cow-face">
                    <div className="cow-eye-shadow" />
                    <div className="cow-eye-left" />
                    <div className="cow-eye-right" />
                    <div className="cow-nose-left" />
                    <div className="cow-nose-right" />
                </div>
                <div className="cow-mouth" />
            </div>
        </div>
    </div>
}

export default Preloader;