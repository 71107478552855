import React from "react";

import SearchField from "../../utils/searchTable";
import { setFilterFromSavedList } from "./helper";
import { IconButton } from "../../utils/uiElements";
import Select from "react-select";

const TopHeadBar = (props) => {
	const {
		filterObject,
		showFilterPanel,
		toggleMainLoader,
		setFilters,
		propertySavedFilters,
		setFilterSelectedValues,
		setSelectedDate,
		realtorId,
		selectedSavedValue,
	} = props;

	const onGlobalSearch = (searchKey) => {
		toggleMainLoader(true);
		setFilters({
			...filterObject,
			searchKey,
			pageIndex: 1,
		});
	};
	//Mapping the saved filter list which recived from the web service , since it not in a key value pair
	const savedFilterOption = propertySavedFilters.map((item) => {
		return { value: item.propertyFilterId, label: item.filterName };
	});
	//On the user selection we are identifing the filter properties from the props and set it as api filter
	const setSavedFilter = (selected) => {
		//setSelectedValued("-");
		if (selected.value !== "" && selected.value !== "-") {
			const filterOptions = propertySavedFilters.filter(
				(item) => item.propertyFilterId === selected.value
			)[0];
			setFilters({
				...filterObject,
				pageIndex: 1,
				filters: setFilterFromSavedList(filterOptions.filters),
			});
			//Clear the filter form if there is any pre values
			setFilterSelectedValues({});
			//Reset the date picker
			setSelectedDate({});
		}
	};

	return (
		<div className="d-flex align-items-center mb-3">
			<h2 className="mr-3 mb-0">Listings</h2>
			<SearchField
				initialValue={filterObject.searchKey}
				onSearch={onGlobalSearch}
				className="flex-fill mr-3 form-group mb-0"
				placeholder="Enter your query"
			/>

			{!realtorId ? (
				<>
					<form className="saved-filter-form">
						<div className="form-container filter-dropdown pr-4">
							<div className="row">
								<div className="form-group select col-12">
									<Select
										className="custom-select-box"
										name="filter-dropdown"
										value={selectedSavedValue}
										onChange={(value) =>
											setSavedFilter(value)
										}
										options={[
											{ value: "-", label: "Select" },
											...savedFilterOption,
										]}
										placeholder="Select view"
									/>
								</div>
								{/* <Field
									name="filter-dropdown"
									type="select"
									options={[
										{ value: "-", label: "Select" },
										...savedFilterOption,
									]}
									component={FormGroup}
									onChange={setSavedFilter}
									formGroupClass="col-12"
									placeholder="Select view"
								/> */}
							</div>
						</div>
					</form>
					<IconButton
						value="Filter"
						onClick={showFilterPanel}
						functionParams={true}
						icon={"filter"}
					/>
				</>
			) : null}
		</div>
	);
};
export default TopHeadBar;
