import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import MainLogo from "../assets/Movsoon logo.png";

import SignIn from "../components/session/SignIn";
import ForgotPassword from "../components/session/forgotPassword";
import ResetPassword from "../components/session/resetPassword";
import ChangePassword from "../components/session/changePassword";

export const SessionRouter = ({ location }) => (
    <div className="session-content-loader">
        <div className="session-bg-gradient"></div>
        <div className="session-logo">
            <img src={MainLogo} alt="MoovSoon" />
        </div>
        <Switch location={location}>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
                exact
                path="/set-password/:token"
                component={props => <ResetPassword {...props} set={true} />}
            />
            <Route
                exact
                path="/reset-password/:token"
                component={props => <ResetPassword {...props} set={false} />}
            />
            <Route exact path="/change-password" component={ChangePassword} />
            <Redirect to="/signin" />
        </Switch>
    </div>
);
