import fetchHandler from "../../utils/fetchHandler";
import { SESSION, COMMON } from "../types";

export const sendLoginCredentials = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `account/login`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_LOGIN_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export function updateLoginCredentials(credentials, successHandler) {
	successHandler && successHandler();
	return {
		type: SESSION.UPDATE_LOGIN_CREDENTIALS,
		payload: credentials,
	};
}

export function refreshToken(values, successHandler) {
	const fetchOptions = {
		url: `api/token-refresh`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_LOGIN_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler);
}

export function verifyToken(values, successHandler, errorHandler) {
	const fetchOptions = {
		url: `api/token-verify`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_LOGIN_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
}

export const sendForgotPasswordCredentials = (
	values,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `user/forgot-password/${values.emailid}`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_FORGOT_PASSWORD_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const sendResetPasswordCredentials = (
	values,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `user/update-password`,
		method: "POST",
		secure: false,
		body: JSON.stringify(values),
		actionType: SESSION.SEND_RESET_PASSWORD_CREDENTIALS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const sendRegisterNowCredentials = (
	values,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `api/members`,
		method: "POST",
		secure: false,
		actionType: SESSION.SEND_REGISTER_NOW_CREDENTIALS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const logoutApp = (successHandler) => {
	successHandler && successHandler();
	return {
		type: COMMON.LOGOUT_APP,
		payload: null,
	};
};
