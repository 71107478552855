import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import FormGroup from "../../../utils/formGroup";
import Tooltip from "../../../utils/tooltip";
import SidePanel from "../../../utils/sidePanel";
import TemplatePanel from "../../../utils/templatesPanel";

import { getPropertyNotificationSettings } from "./helper";

const AutomaticProspectingBlock = ({
	loggedInAs,
	ownerTemplateProspectingEvents,
	relatorTemplateProspectingEvents,
	templateList,
	formValues,
	selectedID,
	propertyNotificationSetting,
	savePropertyNotification,
	setShowLoader,
	successMessage,
	setPropertyNotificationSetting,
}) => {
	const [filterPanelDisplay, showFilterPanel] = useState(false);

	const savePropertySettings = (
		forceUpdate = false,
		offAutomaticTouchesEnabled = false
	) => {
		setShowLoader(true);

		const data = getPropertyNotificationSettings(
			formValues,
			ownerTemplateProspectingEvents,
			relatorTemplateProspectingEvents,
			false,
			selectedID,
			propertyNotificationSetting
		);
		savePropertyNotification(
			{
				propertyId: selectedID,
				automaticTouchesEnabled: forceUpdate
					? offAutomaticTouchesEnabled
					: formValues && formValues.automaticTouchesEnabled,
				companyPropertyNotificationSettings: data,
			},
			(e) => {
				if (!forceUpdate) showFilterPanel(false);
				setShowLoader(false);
				successMessage(6001);
				setPropertyNotificationSetting(e.result);
			}
		);
	};

	return (
		<>
			<form className="create-user-form mr-3">
				<div className="d-flex flex-row  p-1 w-100 ">
					<Field
						{...{
							name: "automaticTouchesEnabled",
							label: "Automated Touches",
							type: "boolean",

							hide: loggedInAs("AD"),
							formGroupClass: "col-8 ml-auto ",
							onChange: (e) => {
								showFilterPanel(e);
								savePropertySettings(true, e);
							},
							disable: !templateList.length,
							viewMode: !templateList.length,
							tooltip: !templateList.length
								? "Create email, SMS and mailer templates in the templates section to turn on"
								: "Switch it on and set templates to automatically prospect to the realtor and homeowner as the listing status changes",
						}}
						component={FormGroup}
					/>
					{templateList.length ? (
						formValues && formValues.automaticTouchesEnabled ? (
							<Tooltip
								message="Set template"
								component={
									<button
										type="button"
										onClick={(e) => {
											showFilterPanel(true);
										}}
										className="btn btn-default btn-cell"
									>
										<i className="icon icon-template" />
									</button>
								}
							/>
						) : (
							<Tooltip
								message="Switch Automated Touches on and set templates to automatically prospect to the realtor and homeowner as the listing status changes"
								component={
									<button
										type="button"
										className="btn btn-default btn-cell icon-disable"
									>
										<i className="icon icon-template" />
									</button>
								}
							/>
						)
					) : (
						<Tooltip
							message="Create email, SMS and mailer templates in the templates section to turn on"
							component={
								<button
									type="button"
									className="btn btn-default btn-cell icon-disable"
								>
									<i className="icon icon-template" />
								</button>
							}
						/>
					)}
				</div>

				<SidePanel
					show={filterPanelDisplay}
					onHide={() => showFilterPanel(false)}
					component={TemplatePanel}
					componentProps={{
						ownerTemplateProspectingEvents,
						relatorTemplateProspectingEvents,
						templateList,
						formValues,
						createMode: 1,
						property: 1,
						initialValues: propertyNotificationSetting,
						showFilterPanel,
						savePropertySettings,
					}}
				/>
			</form>
		</>
	);
};
export default reduxForm({
	form: "PropertyautomaticProspectingForm",
	enableReinitialize: true,
})(AutomaticProspectingBlock);
