import React, { useState } from "react";
import MailerTemplate from "../../../utils/manualProspecting/mailerTemplate";
import MailerTemplateFromList from "../../../utils/manualProspecting/mailerTemplateFromList";
import { mailerTemplateHTML } from "../../../utils/manualProspecting/mailerTemplateHTML";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

const MailerPanel = (props) => {
  const {
    selectedID,
    data,
    formValues,
    change,
    sendManualProspecting,
    propertyDetails,
    propertyOwnerInfo,
    dispatch,
    manualProspectingType,
    toggleMainLoader,
    mailerSizeOptions,
    templateSizeId,
  } = props;

  const onSubmit = (values) => {
    toggleMainLoader(true);
    const html = mailerTemplateHTML({ data, formValues: values });
    //return;
    if (html[1][0] && html[1][1] && html[1][0] !== "" && html[1][1] !== "") {
      const postData = {
        templateType: "mailer",
        prospectingSource: 1,
        propertyId: selectedID,
        templateSizeId: templateSizeId,
        prospectingType: manualProspectingType,
        notificationTitle: "",
        notificationData: html[1][0],
        notificationDataBack: html[1][1],
        prospectingUserId:
          manualProspectingType === 1
            ? propertyDetails.realtorId
            : propertyOwnerInfo.ownerInfo.propertyOwnerId,
      };
      sendManualProspecting(
        postData,
        () => {
          successMessage(6006);
          toggleMainLoader(false);
          dispatch({ showPostCardPanel: false });
        },
        (data) => {
          toggleMainLoader(false);
          dispatch({ showPostCardPanel: false });
          errorMessage(data.error.code);
        }
      );
    } else {
      errorMessage(5011);
      toggleMainLoader(false);
    }
  };
  const [prospectingType, setProspectingType] = useState(false);
  return prospectingType === 1 ? (
    <MailerTemplate
      {...{
        data,
        formValues,
        change,
        onSubmit,
        parentDispatch: dispatch,
        mailerSizeOptions,
        propertyDetails,
      }}
    />
  ) : prospectingType === 2 ? (
    <MailerTemplateFromList
      {...{
        change,
        onSubmit,
        parentDispatch: dispatch,
        prospectingSource: 1,
        propertyId: selectedID,
        prospectingType: manualProspectingType,
        propertyDetails,
        prospectingUserId:
          manualProspectingType === 1
            ? propertyDetails.realtorId
            : propertyOwnerInfo.ownerInfo.propertyOwnerId,
      }}
    />
  ) : (
    <div className="create-template-wrap">
      <div className="d-flex align-items-center justify-content-between px-4 py-3 bb-1">
        <div className="d-flex flex-row align-items-center">
          <h2 className="color-1 m-0">Compose Mailer</h2>
          <h3 className="color-1 m-0 flex-fill ml-2">-</h3>
          <h3 className="color-1 m-0 flex-fill ml-1">{propertyDetails?.address}</h3>
        </div>
        <button
          type="button"
          className="btn btn-default btn-sm"
          onClick={() =>
            dispatch({
              showPostCardPanel: false,
            })
          }
        >
          Close
        </button>
      </div>
      <div className="row manual-option-wrap mr-2 ml-2">
        <div className="col">What would you like to do ?</div>
      </div>
      <div className="row manual-option-wrap mr-2 ml-2">
        <div
          className="col md-6 manual-option-wrap-option text-center ml-3 hand"
          onClick={() => setProspectingType(1)}
        >
          Compose Now
        </div>
        <div
          className="col md-6 manual-option-wrap-option text-center hand"
          onClick={() => setProspectingType(2)}
        >
          Select From Templates
        </div>
      </div>
    </div>
  );
};
export default MailerPanel;
