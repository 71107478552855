import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";

import { getMarketList } from "../../store/actions";
import Preloader from "../../utils/preloader";
import DashboardMarketDetails from "./dashboardMarketDetails";

const initialStates = {
	marketList: null,
	marketRefId: null,
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const DashboardMarketList = ({ company, getMarketList }) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const { marketList, marketRefId } = state;

	useEffect(() => {
		const successHandler = (e) => {
			const marketList = e.result.map((item) => ({
				...item,
				label: item.marketName,
				value: item.marketId,
			}));
			dispatch({
				marketList,
				marketRefId:
					marketList && marketList.length
						? marketList[0].value
						: null,
			});
		};
		dispatch({ marketList: null, marketRefId: null });
		if (company && company.companyId) {
			getMarketList({ compRefId: company.companyId }, successHandler);
		}
	}, [company, getMarketList]);

	const renderMarketsDropdown = () => {
		return (
			<div className="market-dropdown form-group">
				<select
					className="form-control"
					value={marketRefId}
					onChange={(e) =>
						dispatch({ marketRefId: e.nativeEvent.target.value })
					}
				>
					{marketList.map((item) => (
						<option key={item.value} value={item.value}>
							{item.label}
						</option>
					))}
				</select>
			</div>
		);
	};

	return (
		<>
			{!marketList && company ? (
				<div className="market-list-wrap">
					<Preloader show={true} className="inner" />
				</div>
			) : company && marketList && marketList.length ? (
				<div className="markets-wrap">
					<div className="markets-head">
						<h2 className="mb-1">{company.companyName}</h2>
						<div>
							<span className="text-sm text-gray">
								Total Number of Users :{" "}
							</span>
							<span className="pl-1">{company.usersCount}</span>
							<span className="text-primary px-2">|</span>
							<span className="text-sm text-gray">
								Total Live Listings :{" "}
							</span>
							<span className="pl-1">
								{company.totalLiveListing}
							</span>
						</div>
					</div>
					{renderMarketsDropdown()}
				</div>
			) : (
				<div className="alert alert-info p-3 mb-3 text-center">
					No markets allocated
				</div>
			)}
			<DashboardMarketDetails
				marketRefId={marketRefId}
				marketType={0}
				showHead={true}
			/>
		</>
	);
};

const mapDispatchToProps = {
	getMarketList,
};

export default connect(null, mapDispatchToProps)(DashboardMarketList);
