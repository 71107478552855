import React from "react";
import Tooltip from "../../../utils/tooltip";
import { Button } from "../../../utils/uiElements";
import Home from "../../../assets/req_owner.png";
import RequestedOwnerIcon from "../../../assets/requested-data.png";
import Confirm from "../../../utils/confirmDelete";
//import { errorMessage } from "../../../utils/errorMessage";

const OwnerBlock = ({
  selectedID,
  propertyOwnerInfo,
  fetchPropertyOwnerInfo,
  dispatch,
  loggedInAs,
  setCommunicationFeedType,
  setOwnerInfoRequests,
  confirmRequest,
  setConfirmRequest,
  requestOwnerData,
}) => {
  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-row flex-fill">
        <div className="pl-3 d-flex align-items-start w-60">
          <div>
            <div className="d-flex flex-row mb-4 align-items-center">
              <i className="relator-icon icon icon-home text-gray" />
              <h3 className="color-1 flex-fill ml-2 mb-0">HOME OWNER</h3>
            </div>

            {propertyOwnerInfo &&
            propertyOwnerInfo.ownerInfo &&
            (propertyOwnerInfo.ownerInfo.firstName ||
              propertyOwnerInfo.ownerInfo.name ||
              propertyOwnerInfo.ownerInfo.email) ? (
              <div>
                <div className="d-flex">
                  <h2 className="ellipsis text-primary mt-1">
                    {propertyOwnerInfo.ownerInfo.firstName}{" "}
                    {propertyOwnerInfo.ownerInfo.name}
                  </h2>
                  {!loggedInAs("AD") &&
                    propertyOwnerInfo.ownerInfo?.isOwnerInfoRequested && (
                      <Tooltip
                        message={"Requested Owner Data"}
                        placement="right"
                        component={
                          <button
                            style={{ backgroundColor: "#3B9A2D" }}
                            type="button"
                            onClick={() => {}}
                            className="ml-1 btn btn-sm btn-cell"
                          >
                            <img
                              src={RequestedOwnerIcon}
                              alt="requested owner data icon"
                            />
                          </button>
                        }
                      />
                    )}
                </div>

                <div className="d-flex align-items-center mb-2">
                  <span>{propertyOwnerInfo.ownerInfo.address}</span>
                </div>
                <div className="d-flex">
                  <div className="flex-fill mr-3 d-flex align-items-center">
                    <span>{propertyOwnerInfo.ownerInfo.phone}</span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {propertyOwnerInfo.ownerInfo && !loggedInAs("AD") ? (
          <div className="w-40 d-flex flex-column align-items-end">
            <div>
              {propertyOwnerInfo.ownerInfo.email ? (
                <Button
                  className="mb-auto btn btn-cell btn-light ml-2"
                  type="button"
                  onClick={() => {
                    dispatch({ manualProspectingType: 2 });
                    dispatch({ showMailPanel: true });
                  }}
                  value={<i className="icon icon-inbox text-gray" />}
                  tooltip="Send Email"
                />
              ) : null}
              {propertyOwnerInfo.ownerInfo.phone ? (
                <Button
                  className="mb-auto btn btn-cell btn-light ml-2"
                  type="button"
                  onClick={() => {
                    dispatch({ showSMSPanel: true });
                    dispatch({ manualProspectingType: 2 });
                  }}
                  value={<i className="icon icon-sms text-gray" />}
                  tooltip="Send SMS"
                />
              ) : null}
              {propertyOwnerInfo.ownerInfo.address ? (
                <Button
                  className="mb-auto btn btn-cell btn-light ml-2"
                  type="button"
                  onClick={() => {
                    dispatch({ showPostCardPanel: true });
                    dispatch({ manualProspectingType: 2 });
                  }}
                  value={<i className="icon icon-wallet text-gray" />}
                  tooltip="Send Mailer"
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {propertyOwnerInfo.ownerInfo &&
      (propertyOwnerInfo.ownerInfo.address ||
        propertyOwnerInfo.ownerInfo.email ||
        propertyOwnerInfo.ownerInfo.phone) ? (
        <div className="d-flex justify-content-between">
          <div
            onClick={() => {
              setCommunicationFeedType(2);
            }}
            className="pl-3 pt-4 text-primary pointer ellipsis"
          >
            View Communication Feed
          </div>

          {!loggedInAs("AD") &&
            !propertyOwnerInfo?.ownerInfo?.isOwnerInfoRequested && (
              <div className="pt-3">
                <button
                  type="button"
                  onClick={()=>{
                    setConfirmRequest(true);
                  }}
                  className="btn btn-primary btn-md"
                >
                  {/* <i className={`pr-2 icon icon-user`}></i> */}
                  <img className="mr-2" src={Home} alt="request owner data" />
                  {<span>Request Owner Data</span>}
                </button>
              </div>
            )}
        </div>
      ) : null}
      <Confirm
        message={`Are you sure you want to request data? This may incur additional costs.`}
        onYes={() => {requestOwnerData()}}
        onNo={(e) => {setConfirmRequest(false)}}
        show={confirmRequest}
      />
    </div>
  );
};
export default OwnerBlock;
