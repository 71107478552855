import React from "react";
import Tooltip from "./tooltip";

const ToggleElementWrapper = ({
	children,
	value = true,
	onToggle,
	className = "",
}) => {
	return (
		<div
			className={`toggle-element-wrap ${className} ${
				value ? "on" : "off"
			}`}
		>
			<Tooltip
				message={`${value ? "Hide" : "Show"} this`}
				component={
					<div
						className="toggle-element-toggler"
						onClick={() => onToggle(!value)}
					>
						+
					</div>
				}
			/>
			<div className="toggle-element-container">{children}</div>
		</div>
	);
};

export default ToggleElementWrapper;
