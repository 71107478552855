import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import "./UserListing.scss";

import {
	getUsers,
	getUser,
	toggleMainLoader,
	resetField,
	deleteUser,
} from "../../../store/actions";
import { Table, Pagination } from "../../../partials/table/table";
import CreateUser from "../create/UserCreate";
import SidePanel from "../../../utils/sidePanel";
import { columns as columnsFn } from "./helper";
import TableLoader from "../../../utils/tableLoader";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";
import SearchField from "../../../utils/searchTable";
import Confirm from "../../../utils/confirmDelete";
import { userTypes } from "../../../constant/userManagement";
import Tooltip from "../../../utils/tooltip";
import emptyImageUser from "../../../assets/users.png";

const UserListing = ({
	history,
	getUsers,
	getUser,
	userList,
	recordsTotal,
	userDetails,
	toggleMainLoader,
	selectedUser,
	editingUser,
	resetField,
	deleteUser,
}) => {
	const toggleAddEditUserPanel = (row = null, show = true) => {
		row && getUser({ userId: row.userId, type: "EDITING" });
		setShowCreateUserPanel(show);
	};

	const loggedInAs = (type) => {
		return userDetails.userType === userTypes[type];
	};

	const onSelectUser = (event) => {
		resetField("User.userList", null);
		resetField("User.recordsTotal", null);
		resetField("User.selectedUserDetails", null);
		resetField("User.editingUserDetails", null);
		history.push(`/users/${event.userId}/view`);
	};

	const [deleteUserData, setDeleteUserData] = useState(null);
	const onDeleteUser = (event) => {
		setDeleteUserData(event);
	};

	const renderReason = (e) => {
		const { referenceDetails } = e;
		return (
			<div>
				<h3>User deletion failed!</h3>
				<div>Markets listed below are linked with the user!</div>
				<ul className="list-bullet">
					{referenceDetails.map((item, index) => (
						<li key={index}>
							{item.subMarketName
								? item.subMarketName
								: item.marketName}
						</li>
					))}
				</ul>
			</div>
		);
	};

	const confirmDelete = (event) => {
		toggleMainLoader(true);
		const successHandler = (e) => {
			e && e.result && !e.result.responseValue
				? errorMessage(e, renderReason)
				: successMessage(2003);

			setDeleteUserData(null);
			const onDeleteSuccessHandler = (event) => {
				toggleMainLoader(false);
			};
			const onDeleteErrorHandler = (event) => {
				errorMessage(event);
				toggleMainLoader(false);
			};
			let newFilters = { ...filters };
			if (
				filters.pageIndex > 1 &&
				recordsTotal - 1 <= (filters.pageIndex - 1) * filters.pageSize
			) {
				newFilters = { ...filters, pageIndex: filters.pageIndex - 1 };
				setFilters(newFilters);
			}
			toggleAddEditUserPanel(null, false);
			getUsers(newFilters, onDeleteSuccessHandler, onDeleteErrorHandler);
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};
		deleteUser(deleteUserData.userId, successHandler, errorHandler);
	};

	const columns = columnsFn({
		onSelectUser,
		toggleAddEditUserPanel,
		onDeleteUser,
		userDetails,
		selectedUser,
	});

	const [filters, setFilters] = useState({
		pageIndex: 1,
		pageSize: 10,
		sortColumn: columns[0].accessor,
		sortDirection: "asc",
		userId: null,
		userType: null,
		searchKey: "",
	});
	const [queryObj, setQueryObj] = useState(null);
	const [showCreateUserPanel, setShowCreateUserPanel] = useState(false);

	useEffect(() => {
		const successHandler = (event) => {
			toggleMainLoader(false);
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};
		queryObj &&
			queryObj.userId &&
			getUsers(queryObj, successHandler, errorHandler);
	}, [queryObj, getUsers, toggleMainLoader]);

	useEffect(() => {
		setQueryObj(filters);
	}, [filters]);

	useEffect(() => {
		const newFilters =
			userDetails &&
			userDetails.userId &&
			userDetails.userId !== filters.userId
				? {
						...filters,
						userId: userDetails.userId,
						userType: userDetails.userType,
				  }
				: null;

		newFilters && newFilters.userId && setFilters(newFilters);
	}, [filters, userDetails]);

	const onCreate = () => {
		const successHandler = (event) => {
			toggleMainLoader(false);
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};
		toggleAddEditUserPanel(null, false);
		getUsers(filters, successHandler, errorHandler);
	};

	const onEdit = () => {
		resetField("User.editingUserDetails", null);
		// selectedUser && onSelectUser({ userId: selectedUser.userId });
	};

	const onCancel = () => {
		toggleMainLoader(false);
		toggleAddEditUserPanel(null, false);
		resetField("User.editingUserDetails", null);
	};

	const renderAddUserBtn = () => {
		return loggedInAs("SM") ? null : (
			<Tooltip
				message={
					loggedInAs("AD")
						? "Add company and set permissions"
						: "Add subuser and set permission"
				}
				component={
					<button
						type="button"
						className="btn btn-primary btn-md"
						onClick={() => toggleAddEditUserPanel(null)}
					>
						{loggedInAs("AD") ? "Add User" : "Add Subuser"}
					</button>
				}
			/>
		);
	};

	const emptyComponent = () => {
		return (
			<div className="empty-component">
				<img
					src={emptyImageUser}
					alt="emptyImageUser"
					className="empty-component-icon"
				/>
				<h3 className="color-1 text-uppercase">
					Adding user to moovsoon
				</h3>
				<p>
					<span className="text-primary">Step 1: </span>Add User.
				</p>
				<p>
					<span className="text-primary">Step 2: </span>Set permission
					to create and manage templates.
				</p>
			</div>
		);
	};

	return (
		<section className="moovsoon-page-container">
			<div className="moovsoon-page-body">
				<div className="d-flex align-items-center mb-3">
					<h2 className="mr-3 mb-0 ">Users</h2>
					<SearchField
						initialValue={filters.searchKey}
						onSearch={(searchKey) =>
							setFilters({
								...filters,
								searchKey,
								pageIndex: 1,
							})
						}
						className={`flex-fill mr-3 form-group mb-0`}
						placeholder="Enter your query"
					/>
					{renderAddUserBtn()}
				</div>
				{userList && userList.length ? (
					<div>
						<Table
							columns={columns}
							data={userList}
							onSort={(e) =>
								e.by !== filters.sortColumn ||
								e.order !== filters.sortDirection
									? setFilters({
											...filters,
											sortColumn: e.by,
											sortDirection: e.order,
											pageIndex: 1,
									  })
									: null
							}
						/>
						<Pagination
							total={recordsTotal}
							count={filters.pageSize}
							page={filters.pageIndex}
							onChange={(e) => {
								toggleMainLoader(true);
								setFilters({ ...filters, pageIndex: e });
							}}
						/>
					</div>
				) : null}
				<TableLoader list={userList} emptyComponent={emptyComponent} />
			</div>
			<SidePanel
				show={showCreateUserPanel}
				onHide={onCancel}
				component={CreateUser}
				componentProps={{
					initialValues: editingUser || { fullService: true },
					selectedUser,
					onCreate,
					onEdit,
					onCancel,
				}}
			/>
			<Confirm
				message={`${
					deleteUserData && deleteUserData.userType === userTypes.CA
						? "Deleting this user will delete all other users under this user."
						: ""
				} Are you sure you want to delete this user?`}
				onYes={confirmDelete}
				onNo={(e) => setDeleteUserData(null)}
				show={deleteUserData ? true : false}
			/>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		userList: get(state, "User.userList.data", null),
		recordsTotal: get(state, "User.userList.recordsTotal", 0),
		userDetails: get(state, "Auth.userDetails", {}),
		selectedUser: get(state, "User.selectedUserDetails", null),
		editingUser: get(state, "User.editingUserDetails", null),
	};
};

const mapDispatchToProps = {
	getUsers,
	getUser,
	toggleMainLoader,
	resetField,
	deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListing);
