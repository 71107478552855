import {
  priceMin,
  priceMax,
  sizeMin,
  sizeMax,
} from "../../constant/userManagement";
import moment from "moment";

import { getUtcDateFromDatePicker } from "../../utils/helper";

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  var ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const downloadFile = (data, fileName = "report") => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  var url = window.URL.createObjectURL(dataURItoBlob(data));
  a.href = url;
  a.download = `${fileName}_${moment(new Date()).format(
    "MM/DD/YYYYTHH:mm:ss"
  )}.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
};

const userSettingsOptions = [
  { label: "Favorite", value: "markAsFavourite" },
  { label: "In Process", value: "markAsInProgress" },
  { label: "Discarded", value: "markAsDiscarded" },
];

const convstatusOptions = [
  { value: "", label: "Select" },
  { label: "Opportunities", value: "opportunities" },
  { label: "Booked", value: "booked" },
];

export const doughnutStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#000",
      backgroundColor: isSelected ? "#cbdaef" : "transparent",
    };
  },
};

export const filtersArray = ({
  zipCodes,
  users,
  status,
  realtor,
  movetype,
  convsource,
  selectedDate,
  setSelectedDate,
}) => {
  return [
    {
      name: "zipCodes",
      label: "Zip Code",
      key: "zipCodes",
      type: "select",
      multiple: true,
      options: zipCodes,
      formGroupClass: "col-4 pr-2",
    },
    {
      name: "sqftmin",
      label: "Listing Min Size",
      type: "select",
      options: sizeMin,
      formGroupClass: "col-2 pr-2",
    },
    {
      name: "sqftmax",
      label: "Listing Max Size",
      type: "select",
      options: sizeMax,
      formGroupClass: "col-2 pl-2",
    },
    {
      name: "pricemin",
      label: "Listing Min Price",
      type: "select",
      options: priceMin,
      formGroupClass: "col-2 pr-2",
    },
    {
      name: "pricemax",
      label: "Listing Max Price",
      type: "select",
      options: priceMax,
      formGroupClass: "col-2 pl-2",
    },
    {
      name: "status",
      label: "Listing Status",
      key: "status",
      type: "select",
      options: status,
      formGroupClass: "col-4 pr-2",
    },
    {
      name: "dateaddedfrom",
      label: "Date Added",
      placeholderText: "Date From",
      key: "dateaddedfrom",
      type: "datepicker",
      selectedDate: selectedDate && selectedDate.from ? selectedDate.from : "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          from: value,
        });
      },
      maxDate: selectedDate && selectedDate.to ? selectedDate.to : "",
      formGroupClass: "col-2 pr-2",
      className: "form-control",
    },
    {
      name: "dateaddedto",
      key: "dateaddedto",
      label: "",
      placeholderText: "Date To",
      type: "datepicker",
      selectedDate: selectedDate && selectedDate.to ? selectedDate.to : "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          to: value,
        });
      },
      minDate: selectedDate && selectedDate.from ? selectedDate.from : "",
      formGroupClass: "col-2 pr-2",
    },
    {
      name: "pendingstatusfrom",
      key: "pendingstatusfrom",
      label: "Date Pending Status",
      placeholderText: "Date From",
      type: "datepicker",
      selectedDate:
        selectedDate && selectedDate.pendingstatusfrom
          ? selectedDate.pendingstatusfrom
          : "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          pendingstatusfrom: value,
        });
      },
      maxDate:
        selectedDate && selectedDate.pendingstatusto
          ? selectedDate.pendingstatusto
          : "",
      formGroupClass: "col-2 pr-2",
    },
    {
      name: "pendingstatusto",
      key: "pendingstatusto",
      label: "",
      placeholderText: "Date To",
      type: "datepicker",
      selectedDate:
        selectedDate && selectedDate.pendingstatusto
          ? selectedDate.pendingstatusto
          : "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          pendingstatusto: value,
        });
      },
      minDate:
        selectedDate && selectedDate.pendingstatusfrom
          ? selectedDate.pendingstatusfrom
          : "",
      formGroupClass: "col-2 pr-2",
    },
    {
      name: "realtor",
      label: "Realtor",
      type: "select",
      options: realtor,
      formGroupClass: "col-4 pr-2",
    },
    {
      name: "usersettings",
      label: "Listing Settings",
      type: "select",
      multiple: true,
      options: userSettingsOptions,
      formGroupClass: "col-4 pr-2",
    },
    {
      name: "users",
      label: "Users",
      type: "select",
      options: users,
      formGroupClass: "col-4 pr-2",
    },
    // {
    //   name: "div",
    //   label: "",
    //   type: "div",
    //   className: "col-4 pr-2",
    //   formGroupClass: "col-4 pr-2",
    // },
    {
      name: "convsource",
      label: "Conversion Source",
      type: "select",
      options: convsource,
      formGroupClass: "col-4 pr-2",
    },
    {
      name: "convstatus",
      label: "Conversion Status",
      type: "select",
      options: convstatusOptions,
      formGroupClass: "col-4 pr-2",
    },
    {
      name: "movetype",
      label: "Moving Type",
      type: "select",
      options: movetype,
      formGroupClass: "col-4 pr-2",
    },
    {
      name: "meetdatefrom",
      label: "Meeting Date",
      placeholderText: "Date From",
      key: "meetdatefrom",
      type: "datepicker",
      selectedDate:
        selectedDate && selectedDate.meetdatefrom
          ? selectedDate.meetdatefrom
          : "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          meetdatefrom: value,
        });
      },
      maxDate:
        selectedDate && selectedDate.meetdateto ? selectedDate.meetdateto : "",
      formGroupClass: "col-2 pr-2",
      className: "form-control",
    },
    {
      name: "meetdateto",
      key: "meetdateto",
      label: "",
      placeholderText: "Date To",
      type: "datepicker",
      selectedDate:
        selectedDate && selectedDate.meetdateto ? selectedDate.meetdateto : "",
      onChange: (value) => {
        setSelectedDate({
          ...selectedDate,
          meetdateto: value,
        });
      },
      minDate:
        selectedDate && selectedDate.meetdatefrom
          ? selectedDate.meetdatefrom
          : "",
      formGroupClass: "col-2 pr-2",
    },
  ];
};

export const getPostData = (values, columnList = []) => {
  const postData = {};
  postData.responseType = values.actionType === "download" ? 1 : 2;
  postData.zipCodes = values.zipCodes
    ? values.zipCodes.map((item) => {
        return item.value;
      })
    : [];

  postData.userSettings = values.usersettings
    ? values.usersettings.map((item) => {
        return item.value;
      })
    : [];
  const otherFilters = [
    { key: "realtor", type: "string" },
    { key: "status", type: "integer" },
    { key: "sqftmin", type: "integer" },
    { key: "sqftmax", type: "integer" },
    { key: "pricemin", type: "integer" },
    { key: "pricemax", type: "integer" },
    { key: "dateaddedfrom", type: "date" },
    { key: "dateaddedto", type: "date" },
    { key: "users", type: "string" },
    { key: "convsource", type: "integer" },
    { key: "convstatus", type: "string" },
    { key: "movetype", type: "integer" },
    { key: "meetdatefrom", type: "date" },
    { key: "meetdateto", type: "date" },
    { key: "pendingstatusfrom", type: "date" },
    { key: "pendingstatusto", type: "date" },
  ];

  postData.otherFilters = otherFilters
    .filter(function (obj) {
      return values[obj.key];
    })
    .map(function (obj) {
      return {
        key: obj.key,
        value:
          obj.type === "string"
            ? values[obj.key]["value"]
            : obj.type === "date"
            ? getUtcDateFromDatePicker(values[obj.key])
            : Number(values[obj.key]["value"]),
      };
    });

  postData.columnToDisplay = columnList
    .filter(function (obj) {
      return values[`columnList_${obj.value}`];
    })
    .map(function (obj) {
      return Number(obj.value);
    });
  postData.reportType = "listing";
  return postData;
};
