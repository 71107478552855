import fetchHandler from "../../utils/fetchHandler";
import { DASHBOARD } from "../types";

export const getAdminChartData = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard/get-admin-stackedchartdata`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.GET_ADMIN_CHART_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCompanyChartData = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: values
			? `dashboard/get-company-chartdata?marketType=${values.marketType}${
					values.marketId ? `&marketId=${values.marketId}` : ""
			  }`
			: `dashboard/get-company-chartdata`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.GET_COMPANY_CHART_DATA,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPerformanceHistory = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard/performance-history?timeframe=${values.timeframe}`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.GET_PERFORMANCE_HISTORY,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getMarketList = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url:
			values && values.compRefId
				? `dashboard/get-market-list?compRefId=${values.compRefId}`
				: `dashboard/get-market-list`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.GET_MARKET_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getMarketDetails = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard/get-market-details?marketRefId=${values.marketRefId}&marketType=${values.marketType}`,
		method: "GET",
		secure: true,
		actionType: DASHBOARD.GET_MARKET_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getDashboardProperties = (
	values,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `dashboard/get-property-list`,
		method: "POST",
		secure: true,
		actionType: DASHBOARD.GET_PROPERTIES,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getDashboardRealtors = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `dashboard/get-realtor-list`,
		method: "POST",
		secure: true,
		actionType: DASHBOARD.GET_REALTORS,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
