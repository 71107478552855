import React from "react";
import { Field, reduxForm } from "redux-form";
import FormGroup from "../../../utils/formGroup";

const InprogressBlock = ({ loggedInAs, updateSettings, propertySettings }) => {
	return (
		<>
			<form className="create-user-form d-flex flex-column align-items-end mr-4">
				<Field
					{...{
						name: "markAsInProgress",
						label: "In Process",
						type: "boolean",
						// hide: loggedInAs("AD"),
						tooltip: !propertySettings.markAsInProgress
							? "Home or Realtor not yet contacted !"
							: "Home owner or realtor has been contacted! ",
						formGroupClass: "w-80",
						onChange: (e) => {
							updateSettings("markAsInProgress");
						},
					}}
					component={FormGroup}
				/>
			</form>
		</>
	);
};
export default reduxForm({
	form: "PropertyInProgressForm",
	enableReinitialize: true,
})(InprogressBlock);
