import { userTypes } from "../../constant/userManagement";

export const navigationLinks = ({ userData }) => {
  const loggedInAs = (type) => {
    return userData && userData.userType === userTypes[type];
  };

  return [
    {
      to: "/dashboard",
      label: "Dashboard",
      icon: "icon-dashboard",
    },
    {
      to: "/users",
      label: "Users",
      icon: "icon-user",
      hide: loggedInAs("BO"),
    },
    {
      to: "/markets",
      label: "Markets",
      icon: "icon-price-tag",
      hide: loggedInAs("BO"),
    },
    {
      to: "/reports",
      label: "Reports",
      icon: "icon-graph-up",
      hide: loggedInAs("AD"),
    },
    {
      to: "/realtor-reports",
      label: "Reports",
      icon: "icon-graph-up",
      hide: !loggedInAs("AD"),
    },
    {
      to: "/listings",
      label: "Listings",
      icon: "icon-home",
    },
    {
      to: "/realtors",
      label: "Realtors",
      icon: "icon-users-check",
    },
    {
      to: "/settings",
      label: "Settings",
      icon: "icon-settings",
    },
    {
      to: "/templates",
      label: "Templates",
      icon: "icon-template",
    },
  ];
};
