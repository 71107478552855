import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import "./session.scss";

import {
	sendLoginCredentials,
	updateLoginCredentials,
	toggleMainLoader,
	resetField,
} from "../../store/actions";

import FormGroup from "../../utils/formGroup";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";
import { required, email } from "../../constant/formValidators";

import { SignedOut, SignInButton, useUser, useClerk } from '@clerk/clerk-react';

const LSVariable = process.env.REACT_APP_LS_VAR;

export const SignIn = ({
	history,
	handleSubmit,
	sendLoginCredentials,
	updateLoginCredentials,
	toggleMainLoader,
	resetField,
}) => {
	const [error, setError] = useState(null);
	const { isSignedIn, user } = useUser();
	const { signOut } = useClerk();

	const submitLogin = (values) => {
		toggleMainLoader(true);
		const successHandler = (event) => {
			event &&
				event.result &&
				resetField("Auth.accessToken", event.result);
				console.log("***result", JSON.stringify(event.result));
			localStorage.setItem(LSVariable, JSON.stringify(event.result));
			const updateSuccessHandler = () => {
				successMessage(1001);
				toggleMainLoader(false);
				history.push("/");
			};
			updateLoginCredentials(event.result, updateSuccessHandler);
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};

		sendLoginCredentials(
			{ ...values, rememberClient: true },
			successHandler,
			errorHandler
		);
	};

	//we have an sso user object
	if (isSignedIn && user) {
		var sso_email = user.primaryEmailAddress.emailAddress;
		var sso_strategy = user.primaryEmailAddress.verification.strategy;
		var sso_status = user.primaryEmailAddress.verification.status;

		console.log("signin-sso-object:", sso_email, sso_strategy, sso_status);

		const successHandlerExt = (event) => {

			event && event.result && resetField("Auth.accessToken", event.result);

			console.log("***result", JSON.stringify(event.result));
			localStorage.setItem(LSVariable, JSON.stringify(event.result));

			const updateSuccessHandler = () => {
				successMessage(1001);
				toggleMainLoader(false);
				history.push("/");
			};

			updateLoginCredentials(event.result, updateSuccessHandler);
		};

		const errorHandlerExt = (event) => {
			//toggleMainLoader(true);
			console.log("event", event);

			if (event.error && event.error.message === 'InvalidEmailAddress') {
				errorMessage(event);
				toggleMainLoader(false);
				signOut();
			}

		};

		toggleMainLoader(true);
		sendLoginCredentials(
			{ userNameOrEmailAddress: sso_email, password: '1234!Qwe', sso_strategy: sso_strategy, sso_status: sso_status, sso: true, rememberClient: true },
			successHandlerExt,
			errorHandlerExt
		);

	}



	return (
		<div className="sign-in-wrap">
			<div className="sign-in-container">
				<form
					className="sign-in-form"
					onSubmit={handleSubmit(submitLogin)}>
					<h4 className="mb-3">Sign In</h4>
					<Field
						name="userNameOrEmailAddress"
						label="Username"
						type="text"
						onChange={() => setError(null)}
						component={FormGroup}
						validate={[required, email]}
					/>
					<Field
						name="password"
						label="Password"
						type="password"
						onChange={() => setError(null)}
						component={FormGroup}
						validate={[required]}
					/>
					<div className="d-flex flex-row-reverse align-items-center mt-3">
						<Link className="mr-3" to="/forgot-password">
							Forgot password?
						</Link>
					</div>
					<div className="d-flex flex-row-reverse align-items-center mt-3">
						<button className="btn btn-primary" type="submit">
							Submit
						</button>
						&nbsp;&nbsp;
						<SignedOut>
						  <SignInButton fallbackRedirectUrl="/signin"  mode='modal'>
							<button className="btn btn-secondary">
								SSO SignIn
							</button>
						  </SignInButton>
						</SignedOut>
					</div>

					{error && (
						<div className="error small text-danger text-center mt-3">
							{error}
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	sendLoginCredentials,
	updateLoginCredentials,
	toggleMainLoader,
	resetField,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "SignInForm",
	})(SignIn)
);
