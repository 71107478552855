import React, { useState, useCallback, useEffect } from "react";

const SearchField = ({
	initialValue,
	onSearch,
	className = "",
	placeholder = "Search",
}) => {
	const [timeout, settimeout] = useState(null);
	const [searchKey, setSearchKey] = useState(
		initialValue ? initialValue : ""
	);

	useEffect(() => {
		return () => {
			clearTimeout(timeout);
		};
	});

	const onSearchFn = useCallback(
		(event) => {
			const value = event.target.value;
			setSearchKey(value);
			value !== initialValue &&
				onSearch &&
				settimeout(
					setTimeout(() => {
						onSearch(value);
					}, 300)
				);
		},
		[initialValue, onSearch]
	);

	return (
		<div className={`keyword-search ${className}`}>
			<div className="keyword-search-container">
				<input
					value={searchKey}
					placeholder={placeholder}
					type="text"
					className="form-control"
					onChange={onSearchFn}
				/>
				<i className="icon icon-search" />
			</div>
		</div>
	);
};
export default SearchField;
