import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues, change, isDirty } from "redux-form";
import html2canvas from "html2canvas";

import {
  toggleMainLoader,
  createTemplate,
  updateTemplate,
  getTemplateCompanyDetails,
  getTemplateProspectingEvents,
  getMailerSizeOptions,
} from "../../../store/actions";
import BasicDetailsForm from "./basicDetailsForm";
import ChooseTemplate from "./chooseTemplate";
import EmailTemplate from "./emailTemplate";
import SMSTemplate from "./smsTemplate";
import MailerTemplate from "./mailerTemplate";
import { emailTemplateHTML } from "./ematilTemplateHTML";
import { mailerTemplateHTML } from "./mailerTemplateHTML";

import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

const initialStates = {
  basicDetails: null,
  creating: false,
  templateCompanyDetails: null,
  submitting: false,
  showConfirmModal: false,
  mailerSizeOptions: [],
  templateSizeId: 1,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const CreateTemplate = ({
  onCancel,
  onCreate,
  createTemplate,
  updateTemplate,
  getTemplateCompanyDetails,
  getTemplateProspectingEvents,
  getMailerSizeOptions,
  isEditing,
  initialValues = null,
  handleSubmit,
  formValues,
  change,
  dirtyForm,
  disableDetailsForm,
  setDisableDetailsForm,
}) => {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const {
    basicDetails,
    creating,
    templateCompanyDetails,
    submitting,
    showConfirmModal,
    mailerSizeOptions,
    templateSizeId,
  } = state;

  useEffect(() => {
    const successHandler = (event) => {
      event &&
        event.result &&
        dispatch({ templateCompanyDetails: event.result });
    };
    getTemplateCompanyDetails(successHandler);
  }, [getTemplateCompanyDetails]);

  useEffect(() => {
    const successHandler = (event) => {
      event && event.result && dispatch({ mailerSizeOptions: event.result });
    };
    getMailerSizeOptions(successHandler);
  }, [getMailerSizeOptions]);
  const setCreating = (e) => dispatch({ creating: true });

  const onCancelCreation = () => {
    onCancel && onCancel();
    dispatch({
      basicDetails: null,
      creating: false,
    });
  };
  const onChangeMailerSize = (id) => {
    dispatch({ templateSizeId: id });
  };

  const renderTemplateBoard = () => {
    const templateType =
      initialValues && initialValues.templateType
        ? initialValues.templateType.value
        : basicDetails && basicDetails.templateType
        ? basicDetails.templateType.value
        : 0;

    const templateProps = {
      onTouch: setCreating,
      onCancel: onCancelCreation,
      data: templateCompanyDetails,
      initialValues,
      formValues: formValues,
      change: change,
      submitting,
      mailerSizeOptions,
      onChangeMailerSize,
      setDisableDetailsForm,
    };

    switch (templateType) {
      case 1:
        return <EmailTemplate {...templateProps} />;
      case 2:
        return <SMSTemplate {...templateProps} />;
      case 3:
        return <MailerTemplate {...templateProps} />;
      default:
        return <ChooseTemplate />;
    }
  };

  const onCreateBasicDetails = () => {
    if (formValues) {
      const { templateName, templateType, prospectingEvent } = formValues;
      if (templateName && templateType && prospectingEvent) {
        dispatch({
          basicDetails: {
            templateName,
            templateType,
            prospectingEvent,
          },
        });
      } else {
        errorMessage("PLEASE_FILL_THE_FORM");
      }
    } else {
      errorMessage("PLEASE_FILL_THE_FORM");
    }
  };

  const onSubmit = (values) => {
    dispatch({ submitting: true });

    const { templateId, templateName, templateType, prospectingEvent } = values;
    let mailerTemplateName =
      templateType.value === 3
        ? templateSizeId === 1
          ? templateName + ' (4"x6")'
          : templateSizeId === 2
          ? templateName + ' (6"x9")'
          : templateName + ' (6"x11")'
        : templateName;

    const newValues = {
      templateId,
      templateName,
      templateType: templateType.value,
      prospectingEvent,
    };
    let templateBody = null;
    let templateBodyFront = null;
    let templateBodyBack = null;
    switch (templateType.value) {
      case 1:
        templateBody = emailTemplateHTML({
          values: formValues,
          data: templateCompanyDetails,
        });
        templateBodyFront = null;
        templateBodyBack = null;
        break;
      case 2:
        templateBody = values.templateSMSBody;
        templateBodyFront = null;
        templateBodyBack = null;
        break;
      case 3:
        const html = mailerTemplateHTML({
          data: templateCompanyDetails,
          formValues,
          initialValues,
        });
        templateBody = html[0];
        templateBodyFront = html[1][0];
        templateBodyBack = html[1][1];
        break;
      default:
        templateBody = null;
        templateBodyFront = null;
        templateBodyBack = null;
        break;
    }
    if (templateType.value !== 3 || (templateBodyFront && templateBodyBack)) {
      const successHandler = (e) => {
        dispatch({ submitting: false });
        successMessage(initialValues && initialValues.templateId ? 4002 : 4001);
        onCreate && onCreate(e);
      };
      const errorHandler = (e) => {
        dispatch({ submitting: false });
        errorMessage(e);
      };

      const div = document.createElement("div");
      div.className = "template-email-thumbnail";
      div.innerHTML = templateBody;
      document.body.appendChild(div);
      html2canvas(div).then((canvas) => {
        const dataURL = canvas.toDataURL("image/jpeg");
        const thumbNailData = {
          base64Image: dataURL.replace("data:image/jpeg;base64,", ""),
          fileExtension: ".jpeg",
          contentType: "image/jpeg",
        };
        document.body.removeChild(div);

        initialValues && initialValues.templateId
          ? updateTemplate(
              {
                ...newValues,
                templateBody:
                  templateType.value === 3 ? templateBodyFront : templateBody,
                templateBodyBack,
                templateSizeId:
                  templateType.value === 3 ? templateSizeId : null,
                ...thumbNailData,
              },
              successHandler,
              errorHandler
            )
          : createTemplate(
              {
                ...newValues,
                templateName: mailerTemplateName,
                templateBody:
                  templateType.value === 3 ? templateBodyFront : templateBody,
                templateSizeId:
                  templateType.value === 3 ? templateSizeId : null,
                templateBodyBack,
                ...thumbNailData,
              },
              successHandler,
              errorHandler
            );
      });
    } else {
      errorMessage(5011);
      dispatch({ submitting: false });
    }
  };

  const onClose = () => {
    if (dirtyForm) {
      dispatch({ showConfirmModal: true });
      setDisableDetailsForm(false);
    } else {
      onCancel();
    }
  };

  return (isEditing && initialValues && initialValues.templateId) ||
    !isEditing ? (
    <div className="create-template-wrap">
      <div className="d-flex align-items-center px-4 py-3 bb-1">
        {showConfirmModal ? (
          <div className="d-flex align-items-center justify-content-end flex-fill">
            <div className="mr-3">
              Are you sure you want to cancel template creation?
            </div>
            <button
              type="button"
              className="btn btn-default mr-3 btn-sm"
              onClick={() => dispatch({ showConfirmModal: false })}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={onCancel}
            >
              Yes
            </button>
          </div>
        ) : (
          <>
            <h2 className="color-1 m-0 flex-fill">
              {initialValues && initialValues.templateId ? "Edit" : "Create"}{" "}
              Template
            </h2>
            <button
              type="button"
              className="btn btn-default btn-sm"
              onClick={onClose}
            >
              Close
            </button>
          </>
        )}
      </div>
      <form className="flex-fill d-flex" onSubmit={handleSubmit(onSubmit)}>
        {!disableDetailsForm && (
          <div className="create-template-left py-4 br-1">
            <BasicDetailsForm
              onCreate={onCreateBasicDetails}
              getTemplateProspectingEvents={getTemplateProspectingEvents}
              creating={creating}
              initialValues={initialValues}
              formValues={formValues}
            />
          </div>
        )}
        <div className="create-template-right">
          {!basicDetails && !initialValues ? (
            <ChooseTemplate />
          ) : (
            renderTemplateBoard()
          )}
        </div>
      </form>
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues("CreateTemplateForm")(state),
    dirtyForm: isDirty("CreateTemplateForm")(state),
  };
};

const mapDispatchToProps = {
  toggleMainLoader,
  createTemplate,
  updateTemplate,
  getTemplateCompanyDetails,
  getTemplateProspectingEvents,
  getMailerSizeOptions,
  change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "CreateTemplateForm",
    enableReinitialize: true,
  })(CreateTemplate)
);
