import React, { useReducer, useEffect } from "react";
import { Field } from "redux-form";

import FormGroup from "../../../utils/formGroup";
import { basicDetailsFormFields } from "./helper";

const initialStates = {
	error: null,
	prospectingEventOptions: [],
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const BasicDetailsForm = ({
	onCreate,
	creating,
	getTemplateProspectingEvents,
	initialValues,
	formValues,
}) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const { error, prospectingEventOptions } = state;

	const formFields = basicDetailsFormFields({
		dispatch,
		getTemplateProspectingEvents,
		prospectingEventOptions,
		initialValues,
		creating,
		formValues,
	});

	useEffect(() => {
		const successHandler = (event) => {
			event &&
				event.result &&
				dispatch({
					prospectingEventOptions: event.result,
					error: null,
				});
		};
		initialValues &&
			initialValues.templateType &&
			getTemplateProspectingEvents(
				{ templateType: initialValues.templateType.value },
				successHandler
			);
	}, [initialValues, getTemplateProspectingEvents]);

	return (
		<div className="create-template-form px-4">
			<div className="form-container">
				<h3 className="color-1 text-uppercase">Select Type</h3>
				<div className="row">
					<Field component={FormGroup} {...formFields.templateName} />
					<Field component={FormGroup} {...formFields.templateType} />
					<Field
						component={FormGroup}
						{...formFields.prospectingEvent}
					/>
				</div>
			</div>
			<div className="form-footer">
				{initialValues && initialValues.templateId ? null : (
					<button
						className="btn btn-primary mt-2"
						type="button"
						disabled={creating}
						onClick={onCreate}
					>
						Create
					</button>
				)}
				{error && (
					<div className="error small text-danger text-center mt-3">
						{error}
					</div>
				)}
			</div>
		</div>
	);
};

export default BasicDetailsForm;
