import React from "react";
import { Modal } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";

const SidePanel = ({
	show,
	onHide = null,
	component: Component,
	componentProps = null,
	className = "",
	enforceFocus = true,
}) => {
	const handleOnHide = (event) => {
		onHide && onHide(event);
	};

	return (
		<Modal
			show={show}
			enforceFocus={enforceFocus}
			className={`sidepanel-wrap ${className}`}
			onHide={handleOnHide}
			backdrop="static">
			<div className="sidepanel-container">
				<Scrollbars autoHide={true}>
					<div className="scrollbar-container">
						<Component {...componentProps} />
					</div>
				</Scrollbars>
			</div>
		</Modal>
	);
};
export default SidePanel;
