import { COMMON } from "../types";

export const resetField = (key, value, successHandler = null) => {
	successHandler && successHandler(value);
	return {
		type: COMMON.RESET_FIELD,
		key,
		value
	};
};

export const toggleMainLoader = (value) => {
	return {
		type: COMMON.MAIN_PRELOADER,
		payload: value
	};
};