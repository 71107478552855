import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";

import { getMarketDetails } from "../../store/actions";
import Preloader from "../../utils/preloader";
import {
	priceMin,
	priceMax,
	sizeMin,
	sizeMax,
} from "../../constant/userManagement";

const initialStates = {
	marketDetails: null,
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const DashboardMarketDetails = ({
	marketRefId,
	marketType,
	getMarketDetails,
	className = "",
	showHead = false,
}) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const { marketDetails } = state;

	useEffect(() => {
		const successHandler = (e) => {
			dispatch({ marketDetails: e.result ? e.result : {} });
		};
		if (marketRefId) {
			dispatch({ marketDetails: null });
			getMarketDetails({ marketRefId, marketType }, successHandler);
		}
	}, [marketRefId, marketType, getMarketDetails]);

	const renderMarketDetails = () => {
		const {
			activeListings,
			booked,
			inProcess,
			newListings,
			opportunities,
			pendingListings,
			propMaxSize,
			propMaxVal,
			propMinSize,
			propMinVal,
			zipCodes,
		} = marketDetails;

		const priceMinItem = priceMin.find((item) => propMinVal === item.value);
		const priceMaxItem = priceMax.find((item) => propMaxVal === item.value);
		const sizeMinItem = sizeMin.find((item) => propMinSize === item.value);
		const sizeMaxItem = sizeMax.find((item) => propMaxSize === item.value);

		return marketRefId ? (
			<>
				{showHead ? (
					<div className="my-3">
						<h3 className="color-1 text-uppercase">
							{marketDetails.marketName}
						</h3>
						<div className="text-sm text-gray">
							Listings:{" "}
							{newListings + activeListings + pendingListings}
						</div>
					</div>
				) : null}
				<div className={`market-details-block d-flex ${className}`}>
					<div className="card card-primary p-3 flex-1 mr-3 flex-column">
						<div className="d-flex mb-1">
							<div className="w-50 pr-3 text-sm text-gray">
								Zip Codes
							</div>
							<div className="flex-fill width-100">
								{zipCodes}
							</div>
						</div>
						<div className="d-flex mb-1">
							<div className="w-50 pr-3 text-sm text-gray">
								Price Range
							</div>
							<div className="flex-fill">
								{priceMinItem ? priceMinItem.label : ""} -
								{priceMaxItem ? priceMaxItem.label : ""}
							</div>
						</div>
						<div className="d-flex mb-1">
							<div className="w-50 pr-3 text-sm text-gray">
								Property Size
							</div>
							<div className="flex-fill">
								{sizeMinItem ? sizeMinItem.label : ""} -
								{sizeMaxItem ? sizeMaxItem.label : ""}
							</div>
						</div>
					</div>
					<div className="card card-primary p-3 flex-1 mr-3 flex-row align-items-center">
						<div className="mr-2 flex-fill text-center br-1">
							<div>
								<i className="text-lg icon icon-price-tag" />
							</div>
							<div className="text-sm text-gray">
								New Listings
							</div>
							<div>
								<b>{newListings}</b>
							</div>
						</div>
						<div className="mr-2 flex-fill text-center br-1">
							<div>
								<span className="text-lg icon icon-sale">
									<span className="path1"></span>
									<span className="path2"></span>
								</span>
							</div>
							<div className="text-sm text-gray">Active</div>
							<div>
								<b>{activeListings}</b>
							</div>
						</div>
						<div className="flex-fill text-center">
							<div>
								<i className="text-lg icon icon-status-pending-gray" />
							</div>
							<div className="text-sm text-gray">Pending</div>
							<div>
								<b>{pendingListings}</b>
							</div>
						</div>
					</div>
					<div className="card card-primary p-3 flex-1 flex-column">
						<div className="d-flex flex-fill">
							<div className="flex-1 mr-2 text-sm text-gray">
								In Process
							</div>
							<div className="flex-1">{inProcess}</div>
						</div>
						<div className="d-flex flex-fill">
							<div className="flex-1 mr-2 text-sm text-gray">
								Opportunities
							</div>
							<div className="flex-1">
								{opportunities}{" "}
								<i className="text-primary">(R12)</i>
							</div>
						</div>
						<div className="d-flex flex-fill">
							<div className="flex-1 mr-2 text-sm text-gray">
								Booked
							</div>
							<div className="flex-1">
								{booked} <i className="text-primary">(R12)</i>
							</div>
						</div>
					</div>
				</div>
			</>
		) : null;
	};

	return !marketDetails && marketRefId ? (
		<div className="market-details-preloader">
			<Preloader show={true} className="inner" />
		</div>
	) : marketDetails && marketDetails.marketName && marketRefId ? (
		renderMarketDetails()
	) : (
		<div className="alert alert-info p-3 text-center">
			No market details found!
		</div>
	);
};

const mapDispatchToProps = {
	getMarketDetails,
};

export default connect(null, mapDispatchToProps)(DashboardMarketDetails);
