import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { getCommunicationFeedDetails } from "../../../store/actions";
import { errorMessage } from "../../../utils/errorMessage";

const CommunicationFeed = ({
	getCommunicationFeed,
	getCommunicationFeedDetails,
	realtor = null,
	isPropertyPage = 0,
	propertyOwnerId = null,
	communicationFeedType,
	propertyId = "",
	reloadCommunicationFeed,
}) => {
	const communicationTypes = {
		1: "Email",
		2: "SMS",
		3: "Mailer",
	};
	const [list, setList] = useState(null);
	const [selected, setSelected] = useState(null);

	const alertInfo = (message) => (
		<div className="alert alert-info p-3 text-center m-0">{message}</div>
	);

	useEffect(() => {
		const successHandler = (e) => {
			setList(e.result);
		};
		const errorHandler = (e) => {
			setList([]);
			errorMessage(e);
		};
		((realtor && realtor.realtorId) || propertyOwnerId) &&
			getCommunicationFeed(
				{
					realtorId: realtor.realtorId,
					isPropertyPage,
					propertyOwnerId,
					communicationFeedType,
					propertyId,
				},
				successHandler,
				errorHandler
			);
	}, [
		realtor,
		getCommunicationFeed,
		isPropertyPage,
		propertyOwnerId,
		communicationFeedType,
		propertyId,
		reloadCommunicationFeed,
	]);

	const getDetails = (item) => {
		const { prospectingLogId } = item;
		if (!selected || selected !== prospectingLogId) {
			const successHandler = (e) => {
				setList(
					list.map((data) =>
						data.prospectingLogId === prospectingLogId
							? {
									...data,
									details: e.result,
							  }
							: data
					)
				);
			};
			setSelected(prospectingLogId);
			prospectingLogId &&
				getCommunicationFeedDetails(
					{ prospectingLogId, communicationFeedType },
					successHandler,
					successHandler
				);
		} else {
			setSelected(null);
		}
	};

	const renderCommunicationBody = (details, messageType = 0) => {
		console.log(details);
		return (
			<div className="communication-body">
				{details && details.length ? (
					details.reverse().map((data, index) => {
						const {
							notificationData,
							communicationDirection,
							sender,
							notificationTime,
							prospectingType,
						} = data;
						return (
							<div
								key={index}
								className="communication-body-item">
								<div className="d-flex py-2">
									<div className="sender">
										{sender ? sender : "--"}
									</div>
									<div className="date">
										{moment(notificationTime).format(
											"MM/DD/YYYY hh:mm A"
										)}
									</div>
									<div className="direction">
										<i
											className={`icon icon-${
												communicationDirection === 1
													? "forward"
													: "reply"
											}`}
										/>
									</div>
								</div>
								<div
									className={`data prospecting-type-${prospectingType}`}
									dangerouslySetInnerHTML={{
										__html:
											messageType === 3 &&
											!notificationData.includes("html")
												? `<img style="height:4.25in; width:6.25in;" id="postCardBackImage" src="${notificationData}"/>`
												: notificationData,
									}}
								/>
							</div>
						);
					})
				) : (
					<div className="alert alert-info p-3">Loading</div>
				)}
			</div>
		);
	};

	const renderList = () =>
		list.map((item, key) => {
			const {
				prospectingLogId,
				notificationTitle,
				messageType,
				sender,
				notificationTime,
				isViewed,
				details,
				eventName,
			} = item;
			return (
				<div
					key={key}
					className={`communication-item ${
						selected && selected === prospectingLogId
							? "active"
							: ""
					}`}>
					<div className="communication-head">
						{isViewed ? (
							<div className="communication-item-read">
								<i className="icon icon-check" />
							</div>
						) : null}
						<div className="ellipsis communication-item-sender">
							{sender}
						</div>
						<div
							className={`communication-item-type type-${messageType}`}>
							{communicationTypes[messageType]}
						</div>
						<div className="ellipsis communication-item-data">
							{eventName ? (
								<span className="mx-2">[{eventName}]</span>
							) : null}
							{notificationTitle}
						</div>
						<div className="communication-item-time">
							{moment(notificationTime).format(
								"MM/DD/YYYY hh:mm A"
							)}
						</div>
						<div
							className="communication-item-more"
							onClick={() => getDetails(item)}>
							<i className="icon icon-arrow-bottom" />
						</div>
					</div>
					{renderCommunicationBody(details, messageType)}
				</div>
			);
		});

	return (
		<>
			{!isPropertyPage ? (
				<h2 className="mb-3">Communication Feed</h2>
			) : null}
			{list && list.length ? (
				<div
					className={`card card-primary p-4 mb-4 h-100 communication-list-wrap`}>
					{isPropertyPage ? (
						<h3 className="color-1 flex-fill text-uppercase ml-2 mb-3">
							Communication Feed -
							{communicationFeedType === 2
								? ` Home Owner`
								: ` Realtor`}
						</h3>
					) : null}
					{renderList()}
				</div>
			) : list && list.length === 0 ? (
				alertInfo(`No items found!`)
			) : (
				alertInfo(`Loading`)
			)}
		</>
	);
};

const mapDispatchToProps = {
	getCommunicationFeedDetails,
};

export default connect(null, mapDispatchToProps)(CommunicationFeed);
