import React from "react";

const TableLoader = ({ list, emptyComponent = null }) => {
	return (
		<>
			{list && list.length === 0 ? (
				emptyComponent ? (
					emptyComponent()
				) : (
					<div className="mt-3 alert alert-info p-2 text-center">
						No items found
					</div>
				)
			) : null}
			{!list ? (
				<div className="mt-3 alert alert-info p-2 text-center">
					Loading
				</div>
			) : null}
		</>
	);
};

export default TableLoader;
