import StampImage from "../../assets/mailer-stamp.JPG";
export const mailerTemplateHTML = ({ data, formValues }) => {
	const allValues = {
		...formValues,
	};
	const logoImage =
		allValues && allValues.postCardFrontImage
			? allValues.postCardFrontImage
			: null;

	return [
		`<html>
                <head>
                    <meta charset="UTF-8">
                    <title>Lob.com Sample 4x6 Postcard Back</title>
                    <style>
                        *,
                        *:before,
                        *:after {
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                        }
                        .body {
                            position: relative;
                            width: 6.25in;
                            height: 4.25in;
                            margin: 0;
                            padding: 0;
                            /* If using an image, the background image should have dimensions of 1875x1275 pixels. */
                            background-repeat: no-repeat;
                        }
                        #safe-area {
                            position: absolute;
                            width: 5.875in;
                            height: 3.875in;
                            left: 0.1875in;
                            top: 0.1875in;
                            border: 2px dotted #1cc57b;
                        }
                        #safe-area-outer {
                            position: absolute;
                            width: 6in;
                            height: 4in;
                            left: 0.125in;
                            top: 0.125in;
                            border: 2px dotted #5342c7;
                        }
                        #ink-free {
                            position: absolute;
                            width: 3.2835in;
                            height: 2.375in;
                            right: 0.1in;
                            bottom: 0.1in;
                        }
                    </style>
                </head>
                <body class="body">
                <div style="position: relative;padding-bottom: 30px;">
                ${
					logoImage
						? `<img style="height:4.25in; width:6.25in;" id="postCardBackImage" src="${logoImage}" alt="POST CARD BACK IMAGE"/>`
						: ""
				} 
                <div id="safe-area-outer">
                </div>
                    <div id="safe-area">
                    </div>
                 </div>   
                 ${
						allValues.postCardBackImage
							? `<div style="position: relative;">
                    <img style="height:4.25in; width:6.25in;" id="postCardBackImage" src="${allValues.postCardBackImage}" alt="POST CARD BACK IMAGE"/>
                    <div id="safe-area-outer">
                    </div>
                    <div id="safe-area">
                    <div id="ink-free">
                    <img src="${StampImage}" alt="STAMP IMAGE" style="position: absolute;width: 3.2835in;height: 2.375in;" />
                    </div>
                    </div>
                    </div>`
							: ""
					}
                </body>
            </html>`,
		[
			logoImage ? logoImage : null,
			allValues.postCardBackImage ? allValues.postCardBackImage : null,
		],
	];
};
