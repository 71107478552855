import { TEMPLATE } from "../types";

export default (state = {}, action) => {
    switch (action.type) {
        case TEMPLATE.GET_TEMPLATE_LIST:
            return {
                ...state,
                templateList: action.payload ? action.payload.result : []
            };
        default:
            return state;
    }
};
