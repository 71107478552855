import React, { useState } from "react";
import MailerTemplate from "../../../utils/manualProspecting/mailerTemplate";
import { mailerTemplateHTML } from "../../../utils/manualProspecting/mailerTemplateHTML";
import MailerTemplateFromList from "../../../utils/manualProspecting/mailerTemplateFromList";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

const MailerPanel = (props) => {
	const {
		data,
		formValues,
		change,
		sendManualProspecting,
		realtorId,
		dispatch,
		reloadCommunicationFeed,
		toggleMainLoader,
	} = props;

	const onSubmit = (values) => {
		toggleMainLoader(true);
		const html = mailerTemplateHTML({ data, formValues: values });
		if (
			html[1][0] &&
			html[1][1] &&
			html[1][0] !== "" &&
			html[1][1] !== ""
		) {
			const postData = {
				templateType: "mailer",
				prospectingSource: 2,
				realtorId: realtorId,
				prospectingType: 1,
				notificationTitle: "",
				notificationData: html[1][0],
				notificationDataBack: html[1][1],
				prospectingUserId: realtorId,
			};
			sendManualProspecting(
				postData,
				() => {
					toggleMainLoader(false);
					successMessage(6006);
					dispatch({ showPostCardPanel: false });
					dispatch({
						reloadCommunicationFeed: !reloadCommunicationFeed,
					});
				},
				(data) => {
					toggleMainLoader(false);
					dispatch({ showPostCardPanel: false });
					errorMessage(data);
				}
			);
		} else {
			errorMessage(5011);
			toggleMainLoader(false);
		}
	};
	const [prospectingType, setProspectingType] = useState(false);

	return prospectingType === 1 ? (
		<MailerTemplate
			{...{
				data,
				formValues,
				change,
				onSubmit,
				parentDispatch: dispatch,
			}}
		/>
	) : prospectingType === 2 ? (
		<MailerTemplateFromList
			{...{
				change,
				onSubmit,
				parentDispatch: dispatch,
				prospectingSource: 2,
				realtorId: realtorId,
				prospectingType: 1,
				prospectingUserId: realtorId,
			}}
		/>
	) : (
		<div className="create-template-wrap">
			<div className="d-flex align-items-center px-4 py-3 bb-1">
				<h2 className="color-1 m-0 flex-fill">Compose Mailer</h2>
				<button
					type="button"
					className="btn btn-default btn-sm"
					onClick={() =>
						dispatch({
							showPostCardPanel: false,
						})
					}>
					Close
				</button>
			</div>
			<div className="row manual-option-wrap mr-2 ml-2">
				<div className="col">What would you like to do ?</div>
			</div>
			<div className="row manual-option-wrap mr-2 ml-2">
				<div
					className="col md-6 manual-option-wrap-option text-center ml-3 hand"
					onClick={() => setProspectingType(1)}>
					Compose Now
				</div>
				<div
					className="col md-6 manual-option-wrap-option text-center hand"
					onClick={() => setProspectingType(2)}>
					Select From Templates
				</div>
			</div>
		</div>
	);
};
export default MailerPanel;
