import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import DashboardTabsCAListings from "./dashboardTabsCAListings";
import DashboardTabsCARealtors from "./dashboardTabsCARealtors";

const DashboardTabsCA = ({ history }) => {
	const [tab, setTab] = useState(1);

	return (
		<Tabs
			className="dashboard-tabs"
			activeKey={tab}
			onSelect={(k) => setTab(k)}
		>
			<Tab
				eventKey={1}
				title={
					<div>
						<i className="icon icon-home" /> New Listings
					</div>
				}
			>
				<DashboardTabsCAListings history={history} />
			</Tab>
			<Tab
				eventKey={2}
				title={
					<div>
						<i className="icon icon-users-check" /> New Realtors
					</div>
				}
			>
				<DashboardTabsCARealtors history={history} />
			</Tab>
		</Tabs>
	);
};

export default DashboardTabsCA;
