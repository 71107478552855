import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";
import "../realtors.scss";

import SearchField from "../../../utils/searchTable";
import { Table, Pagination } from "../../../partials/table/table";
import { columns as columnsFn } from "./helper";
import TableLoader from "../../../utils/tableLoader";
import { get } from "lodash";

import {
  toggleMainLoader,
  getRealtors,
  setFilter,
} from "../../../store/actions";

const initialStates = {
  realtorList: null,
  recordsTotal: 0,
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "ranking",
  sortDirection: "asc",
  searchKey: "",
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const RealtorListing = ({
  history,
  toggleMainLoader,
  userDetails,
  getRealtors,
  setFilter,
  filter,
}) => {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const { realtorList, recordsTotal } = state;

  useEffect(() => {
    toggleMainLoader(true);
    const successHandler = (e) => {
      toggleMainLoader(false);
      dispatch({
        realtorList: e.result.data,
        recordsTotal: e.result.recordsTotal,
      });
    };
    const errorHandler = () => {
      dispatch({
        realtorList: [],
      });
      toggleMainLoader(false);
    };
    getRealtors(
      {
        pageIndex: filter.pageIndex,
        pageSize: filter.pageSize,
        sortColumn: filter.sortColumn,
        sortDirection: filter.sortDirection,
        searchKey: filter.searchKey,
        withRank: filter.withRank,
        userId: userDetails.userId,
      },
      successHandler,
      errorHandler
    );
  }, [filter, userDetails, toggleMainLoader, getRealtors]);

  const onSelect = (row) => {
    history.push(`/realtors/${row.realtorId}/view`);
  };

  const columns = columnsFn({
    onSelect,
    userDetails,
  });

  const onSort = (e) => {
    let withRank = filter.withRank;
    if (e.by === "ranking") withRank = true;

    return e.by !== filter.sortColumn || e.order !== filter.sortDirection
      ? setFilter({
          ...filter,
          sortColumn: e.by,
          sortDirection: e.order,
          pageIndex: 1,
          withRank,
        })
      : null;
  };

  const onSearch = (searchKey) => {
    setFilter({ ...filter, searchKey, pageIndex: 1 });
  };

  return (
    <section className="moovsoon-page-container">
      <div className="moovsoon-page-body">
        <div className="d-flex align-items-center mb-3">
          <h2 className="mr-3 mb-0">Realtors</h2>
          <SearchField
            initialValue={filter.searchKey}
            onSearch={onSearch}
            className="flex-fill mr-3 form-group mb-0"
            placeholder="Enter your query"
          />
          <button
            type="button"
            className="btn btn-primary btn-md"
            onClick={() => {
              //onSort({ by: "ranking", order: "asc" });
              setFilter({
                ...filter,
                withRank: true,
                pageIndex: 1,
              });
            }}
          >
            Calculate Rank
          </button>
        </div>
        {realtorList && realtorList.length ? (
          <div>
            <Table
              columns={columns}
              data={realtorList}
              onSort={onSort}
              sortBy={filter.sortColumn}
              sortDir={filter.sortDirection}
            />
            <Pagination
              total={recordsTotal}
              count={filter.pageSize}
              page={filter.pageIndex}
              onChange={(pageIndex) => setFilter({ ...filter, pageIndex })}
            />
          </div>
        ) : null}
        <TableLoader list={realtorList} />
      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    userDetails: get(state, "Auth.userDetails", {
      sortColumn: "ranking",
      sortDirection: "asc",
      pageIndex: 1,
      pageSize: 10,
      searchKey: "",
    }),
    filter: get(state, "Realtor.filter", {}),
  };
};
const mapDispatchToProps = {
  toggleMainLoader,
  getRealtors,
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealtorListing);
