import { USER } from "../types";

export default (state = { userList: [] }, action) => {
    switch (action.type) {
        case USER.GET_USERS:
            return {
                ...state,
                userList: action.payload ? action.payload.result : []
            };
        case USER.GET_SELECTED_USER:
            return {
                ...state,
                selectedUserDetails: action.payload ? action.payload.result : []
            };
        case USER.GET_EDITING_USER:
            return {
                ...state,
                editingUserDetails: action.payload ? action.payload.result : []
            };
        case USER.GET_USER_TYPES:
            return {
                ...state,
                userTypeList: action.payload ? action.payload.result : []
            };
        case USER.GET_COMPANY_NAMES:
            return {
                ...state,
                companyNameList: action.payload ? action.payload.result : []
            };
        case USER.GET_REMAINING_SEATS:
            return {
                ...state,
                remainingSeats: action.payload ? action.payload.result : 0
            };
        default:
            return state;
    }
};
