import { REPORTS } from "../types";
import { mockGUID } from "../../constant/global";

export default (state = {}, action) => {
	switch (action.type) {
		case REPORTS.GET_REPORTS_ZIPCODE_LIST:
			return {
				...state,
				zipCodes: action.payload ? action.payload.result : [],
			};
		case REPORTS.GET_REPORTS_REALTOR_LIST:
			return {
				...state,
				realtor: (() => {
					const realtor = action.payload
						? [
								{
									value: mockGUID,
									label: "Select",
								},
								...action.payload.result,
						  ]
						: [];

					return realtor;
				})(),
			};
		case REPORTS.GET_REPORTS_STATUS_LIST:
			return {
				...state,
				status: (() => {
					const status = action.payload
						? [
								{
									value: mockGUID,
									label: "Select",
								},
								...action.payload.result,
						  ]
						: [];

					return status;
				})(),
			};
		case REPORTS.GET_REPORTS_SOURCE_LIST:
			return {
				...state,
				convsource: (() => {
					const convsource = action.payload
						? [
								{
									value: mockGUID,
									label: "Select",
								},
								...action.payload.result,
						  ]
						: [];

					return convsource;
				})(),
			};
		case REPORTS.GET_REPORTS_MOVE_TYPE_LIST:
			return {
				...state,
				movetype: (() => {
					const movetype = action.payload
						? [
								{
									value: mockGUID,
									label: "Select",
								},
								...action.payload.result,
						  ]
						: [];

					return movetype;
				})(),
			};
		case REPORTS.GET_REPORTS_USER_LIST:
			return {
				...state,
				users: (() => {
					const users = action.payload
						? [
								{
									value: mockGUID,
									label: "Select",
								},
								...action.payload.result,
						  ]
						: [];

					return users;
				})(),
			};
		case REPORTS.GET_REPORTS_COLUMN_LIST:
			return {
				...state,
				columnList: action.payload ? action.payload.result : [],
			};
		default:
			return state;
	}
};
