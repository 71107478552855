import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Field, reduxForm } from "redux-form";
import FormGroup from "../../utils/formGroup";
import { successMessage } from "../../utils/successMessage";
import { processReport } from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { filtersArray, downloadFile, getPostData } from "./helper";

const ListingReport = (props) => {
	const {
		processReport,
		zipCodes,
		users,
		status,
		realtor,
		movetype,
		convsource,
		columnList,
		handleSubmit,
		toggleMainLoader,
	} = props;

	const [selectedDate, setSelectedDate] = useState({});

	const submitForm = (values = null) => {
		toggleMainLoader(true);

		const successHandler = (data) => {
			toggleMainLoader(false);
			if (values.actionType === "download") {
				downloadFile(data.result, `Moovsoon_listingreport`);
			} else {
				successMessage(7001);
			}
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};
		processReport(
			getPostData(values, columnList),
			successHandler,
			errorHandler
		);
	};

	const renderSubmitButton = (type, label) => {
		return (
			<button
				type="button"
				onClick={handleSubmit((values) =>
					submitForm({
						...values,
						actionType: type,
					})
				)}
				className="btn btn-primary btn-md action-button mr-4"
			>
				<i className={`pr-2 icon icon-${type}`}></i>
				<span>{label}</span>
			</button>
		);
	};

	return (
		<>
			<form className="report-form" onSubmit={handleSubmit(submitForm)}>
				<div className="card card-primary px-4 pt-2 pb-1 mb-3 p-4">
					<div className="panel-switch  active">
						<h3 className="color-1 text-uppercase">Filter</h3>
						<div className="form-container">
							<div className="row">
								{filtersArray({
									zipCodes,
									users,
									status,
									realtor,
									movetype,
									convsource,
									selectedDate,
									setSelectedDate,
								}).map((item, index) => (
									<Field
										key={index}
										{...item}
										component={FormGroup}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="card card-primary px-4 pt-2 pb-1 mb-3 p-4">
					<div className="panel-switch  active">
						<h3 className="color-1 text-uppercase mb-4">
							Excel Report Columns
						</h3>
						<div className="form-container">
							<div className="row">
								{columnList.map((item, index) => (
									<Field
										key={index}
										{...{
											name: `columnList_${item.value}`,
											disable: index === 0,
											label: item.label,
											type: "checkbox",
											formGroupClass:
												"col-3 pr-2 flex-row-reverse justify-content-end",
										}}
										component={FormGroup}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row justify-content-end">
					{renderSubmitButton("email", "Email")}
					{renderSubmitButton("download", "Download")}
				</div>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userDetails: get(state, "Auth.userDetails", {}),
		zipCodes: get(state, "Report.zipCodes", {}),
		users: get(state, "Report.users", {}),
		status: get(state, "Report.status", {}),
		realtor: get(state, "Report.realtor", {}),
		movetype: get(state, "Report.movetype", {}),
		convsource: get(state, "Report.convsource", {}),
		columnList: get(state, "Report.columnList", {}),
	};
};

const mapDispatchToProps = {
	processReport,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "ListingReportForm",
		enableReinitialize: true,
	})(ListingReport)
);
