import fetchHandler from "../../utils/fetchHandler";
import { PROPERTY } from "../types";

export const getProperties = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-property-list`,
		method: "POST",
		secure: true,
		actionType: PROPERTY.GET_PROPERTIES,
		body: JSON.stringify(values),
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updatePropertySettings = (
	values,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `properties/change-property-status`,
		method: "PUT",
		secure: true,
		body: JSON.stringify(values),
		actionType: PROPERTY.UPDATE_PROPERTY_SETTINGS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPropertyZipcodes = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-property-zipcode-list`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTY_ZIPCODES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPropertyStatuses = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-property-status-list`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTY_STATUSES,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPropertyRealtors = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-property-realtor-list`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTY_REALTORS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

//KAN-52: perform rest-api call to the backend
export const getPropertyChildMarkets = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-property-child-markets`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTY_CHILD_MARKETS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPropertySavedFilters = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-saved-property-filters`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTY_SAVED_FILTERS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const savePropertyFilters = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/save-property-filter`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PROPERTY.CREATE_PROPERTY_SAVED_FILTER,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getProperty = (propertyId, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-property-details?propertyId=${propertyId}`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTY_DETAILS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const clearProperty = () => {
	return {
		type: PROPERTY.CLEAR_PROPERTY_DETAILS,
		payload: null
	}
};
export const getPropertyNotificationSetting = (
	propertyId,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `properties/get-property-notification-setting?propertyId=${propertyId}`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTY_NOTIFICATION_SETTINGS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const savePropertyNotification = (
	values,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `properties/create-or-update-property-notification-setting`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PROPERTY.SAVE_NOTIFICATION_SETTINGS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const updateTrackConversion = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/update-track-conversion`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PROPERTY.UPDATE_TRACK_CONVERSION,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPropertySourceList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-property-source-list`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTY_SOURCE_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getMoveTypeList = (successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/get-move-type-list`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_MOVE_TYPE_LIST,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getPropertyOwnerInfo = (
	propertyId,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `properties/get-property-owner-info?propertyId=${propertyId}`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_PROPERTIES_OWNER_INFO,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const fetchPropertyOwnerInfo = (
	propertyId,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `properties/fetch-property-owner-info?propertyId=${propertyId}`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.FETCH_PROPERTIES_OWNER_INFO,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getTrackConversion = (
	propertyId,
	successHandler,
	errorHandler
) => {
	const fetchOptions = {
		url: `properties/get-track-conversion?propertyId=${propertyId}`,
		method: "GET",
		secure: true,
		actionType: PROPERTY.GET_TRACK_CONVERSION,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const setOwnerInfoRequests = (values, successHandler, errorHandler) => {
	const fetchOptions = {
		url: `properties/owner-info-requests`,
		method: "POST",
		secure: true,
		body: JSON.stringify(values),
		actionType: PROPERTY.SET_PROPERTY_OWNER_INFO_REQUESTS,
	};
	return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export function setPropertyFilter(filter, successHandler) {
	successHandler && successHandler();
	return {
		type: PROPERTY.SET_PROPERTY_FILTER,
		payload: filter,
	};
}
export function setPersistFilter(value, successHandler) {
	successHandler && successHandler();
	return {
		type: PROPERTY.SET_PERSIST_FILTER,
		payload: value,
	};
}
