import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import moment from "moment";

import {
	getDashboardProperties,
	toggleMainLoader,
	updatePropertySettings,
} from "../../store/actions";
import { userTypes } from "../../constant/userManagement";
import { Table, Pagination } from "../../partials/table/table";
import TableLoader from "../../utils/tableLoader";

import { columns as columnsGenerator } from "../properties/helper";

const DashboardTabsCAListings = ({
	history,
	getDashboardProperties,
	userDetails,
	toggleMainLoader,
	updatePropertySettings,
}) => {
	const sortBy = "dateAdded";
	const sortDir = "desc";
	const [propertyList, setPropertyList] = useState(null);
	const [recordsTotal, setRecordsTotal] = useState(0);
	const [dataReload, setDataReload] = useState(false);
	const [filterObject, setFilters] = useState({
		sortColumn: sortBy,
		sortDirection: sortDir,
		pageIndex: 1,
		pageSize: 10,
		searchKey: "",
		filters: [],
	});

	useEffect(() => {
		if (filterObject && filterObject.pageIndex) {
			const successHandler = (e) => {
				setPropertyList(e && e.result ? e.result.data : []);
				setRecordsTotal(e && e.result ? e.result.recordsTotal : 0);
			};
			const errorHandler = (e) => {
				setPropertyList([]);
			};
			getDashboardProperties(
				{
					...filterObject,
					filters: [...filterObject.filters],
				},
				successHandler,
				errorHandler
			);
		}
	}, [filterObject, getDashboardProperties, dataReload]);

	const loggedInAs = (type) => {
		return userDetails.userType === userTypes[type];
	};

	const onSelect = (row) => {
		history.push(`/listings/${row.propertyId}/view`);
	};

	const onTableSort = (element) => {
		if (
			element.by !== filterObject.sortColumn ||
			element.order !== filterObject.sortDirection
		)
			setFilters({
				...filterObject,
				sortColumn: element.by,
				sortDirection: element.order,
				pageIndex: 1,
			});
	};

	const onPaginate = (element) => {
		setFilters({
			...filterObject,
			pageIndex: element,
		});
	};

	const columns = columnsGenerator({
		onSelect,
		updatePropertySettings,
		userDetails,
		toggleMainLoader,
		loggedInAs,
		dataReload,
		setDataReload,
	});

	return propertyList && propertyList.length ? (
		<div className="relative">
			<div className="date-added-to-ms">
				{propertyList[0].dateAdded ? (
					<>
						<span className="text-gray">Date added to MS: </span>
						{moment
							.utc(propertyList[0].dateAdded)
							.format("MM/DD/YYYY")}
					</>
				) : null}
			</div>
			<Table
				columns={columns}
				data={propertyList}
				onSort={onTableSort}
				sortBy={sortBy}
				sortDir={sortDir}
			/>
			<Pagination
				total={recordsTotal}
				count={filterObject.pageSize}
				page={filterObject.pageIndex}
				onChange={onPaginate}
			/>
		</div>
	) : (
		<TableLoader list={propertyList} />
	);
};

const mapStateToProps = (state) => {
	return {
		userDetails: get(state, "Auth.userDetails", {}),
	};
};

const mapDispatchToProps = {
	getDashboardProperties,
	toggleMainLoader,
	updatePropertySettings,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardTabsCAListings);
