import React, { useState } from "react";
import EmailTemplate from "../../../utils/manualProspecting/emailTemplate";
import EmailTemplateFromList from "../../../utils/manualProspecting/emailTemplateFromList";
import { emailTemplateHTML } from "../../../utils/manualProspecting/ematilTemplateHTML";
import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

const EmailPanel = (props) => {
	const {
		selectedID,
		data,
		formValues,
		change,
		sendManualProspecting,
		propertyDetails,
		propertyOwnerInfo,
		dispatch,
		manualProspectingType,
		toggleMainLoader,
	} = props;

	const onSubmit = (values) => {
		console.log("values ", values);
		toggleMainLoader(true);
		const html = emailTemplateHTML({ values, data });
		const postData = {
			templateType: "email",
			prospectingSource: 1,
			propertyId: selectedID,
			prospectingType: manualProspectingType,
			notificationTitle:
				values && values.notificationTitle
					? values.notificationTitle
					: "",
			notificationData: html,
			prospectingUserId:
				manualProspectingType === 1
					? propertyDetails.realtorId
					: propertyOwnerInfo.ownerInfo.propertyOwnerId,
		};
		sendManualProspecting(
			postData,
			() => {
				successMessage(6002);
				toggleMainLoader(false);
				dispatch({ showMailPanel: false });
			},
			(data) => {
				toggleMainLoader(false);
				dispatch({ showMailPanel: false });
				errorMessage(data.error.code);
			}
		);
	};
	const templateDetails = {
		templateEmailShowLogo: true,
		templateEmailShowKnowMoreBtn: true,
		templateEmailShowSocialMedias: true,
		templateEmailShowFacebook: true,
		templateEmailShowYoutube: true,
		templateEmailShowLinkedIn: true,
		templateEmailShowTwitter: true,
		templateEmailShowFooter: true,
	};
	const [prospectingType, setProspectingType] = useState(false);
	return prospectingType === 1 ? (
		<EmailTemplate
			{...{
				data,
				formValues,
				change,
				onSubmit,
				initialValues: templateDetails,
				parentDispatch: dispatch,
				propertyDetails
			}}
		/>
	) : prospectingType === 2 ? (
		<EmailTemplateFromList
			{...{
				onSubmit,
				parentDispatch: dispatch,
				prospectingSource: 1,
				propertyId: selectedID,
				prospectingType: manualProspectingType,
				propertyDetails,
				prospectingUserId:
					manualProspectingType === 1
						? propertyDetails.realtorId
						: propertyOwnerInfo.ownerInfo.propertyOwnerId,
			}}
		/>
	) : (
		<div className="create-template-wrap">
			<div className="d-flex align-items-center justify-content-between px-4 py-3 bb-1">
			<div className="d-flex flex-row align-items-center">
          <h2 className="color-1 m-0">Compose Email</h2>
          <h3 className="color-1 m-0 flex-fill ml-2">-</h3>
          <h3 className="color-1 m-0 flex-fill ml-1">{propertyDetails?.address}</h3>
        </div>
				<button
					type="button"
					className="btn btn-default btn-sm"
					onClick={() =>
						dispatch({
							showMailPanel: false,
						})
					}>
					Close
				</button>
			</div>
			<div className="row manual-option-wrap mr-2 ml-2">
				<div className="col">What would you like to do ?</div>
			</div>
			<div className="row manual-option-wrap mr-2 ml-2">
				<div
					className="col md-6 manual-option-wrap-option text-center ml-3 hand"
					onClick={() => setProspectingType(1)}>
					Compose Now
				</div>
				<div
					className="col md-6 manual-option-wrap-option text-center hand"
					onClick={() => setProspectingType(2)}>
					Select From Templates
				</div>
			</div>
		</div>
	);
};
export default EmailPanel;
