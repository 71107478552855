import React, { useReducer } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import FormGroup from "../../utils/formGroup";
import { createAdminFieldsFn } from "./helper";
import { toggleMainLoader, createUser } from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";

const initialStates = {
	error: null,
	submitting: false,
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const CreateAdmin = ({ handleSubmit, createUser, onCreate, onCancel }) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const { error, submitting } = state;

	const submitForm = (values) => {
		toggleMainLoader(true);
		dispatch({ submitting: true });
		const finalValues = {
			...values,
			userName: values.emailAddress,
			isActive: true,
			userType: "Admin",
			password: "1234!Qwe",
		};

		const successHandler = (event) => {
			successMessage(2001);
			onCreate && onCreate({ success: true });
			dispatch({ submitting: false });
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
			dispatch({ submitting: false });
		};

		createUser(finalValues, successHandler, errorHandler);
	};

	return (
		<>
			<h2 className="mb-4">Add Super Admin</h2>
			<form
				className="create-user-form"
				onSubmit={handleSubmit(submitForm)}>
				<div className="form-container">
					<div className="row">
						{createAdminFieldsFn({
							dispatch,
						}).map((item, index) => (
							<Field
								key={index}
								{...item}
								component={FormGroup}
							/>
						))}
					</div>
				</div>
				<div className="form-footer">
					<button
						className="btn btn-default mt-3 mr-2"
						type="button"
						onClick={onCancel}>
						Cancel
					</button>
					<button
						disabled={submitting}
						className="btn btn-primary mt-3"
						type="submit">
						Submit
					</button>
					{error && (
						<div className="error small text-danger text-center mt-3">
							{error}
						</div>
					)}
				</div>
			</form>
		</>
	);
};

const mapDispatchToProps = {
	toggleMainLoader,
	createUser,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "CreateAdminForm",
		enableReinitialize: true,
	})(CreateAdmin)
);
