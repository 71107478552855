import React from "react";
import "./progressBar.scss";

const ProgressBar = ({ percentage, animate = false }) => {
	return (
		<div className="progress-wrap">
			<div className="progress-container">
				<div
					className="progress-bar"
					style={{ width: `${percentage}%` }}
				>
					<div className="progress-text">{percentage}%</div>
					{animate ? <div className="progress-anim" /> : null}
				</div>
			</div>
		</div>
	);
};

export default ProgressBar;
