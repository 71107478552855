import fetchHandler from "../../utils/fetchHandler";
import { SETTINGS } from "../types";

export const saveSettings = (values, successHandler, errorHandler) => {
  const url = values.createMode
    ? `settings/create-settings`
    : `settings/update-settings`;
  const fetchOptions = {
    url,
    method: "POST",
    secure: true,
    actionType: SETTINGS.UPDATE_SETTINGS,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSettings = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `settings/get-user-settings`,
    method: "GET",
    secure: true,
    actionType: SETTINGS.GET_SETTINGS,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSettingsNotificationEventTypes = (
  successHandler,
  errorHandler
) => {
  const fetchOptions = {
    url: `settings/get-notification-event-types`,
    method: "GET",
    secure: true,
    actionType: SETTINGS.GET_SETTINGS_NOTIFICATION_EVENT_TYPES,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSettingsEmailAccountTypes = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `settings/get-email-account-types`,
    method: "GET",
    secure: true,
    actionType: SETTINGS.GET_SETTINGS_EMAIL_ACCOUNT_TYPES,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSettingsAPIProviders = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `settings/get-api-providers`,
    method: "GET",
    secure: true,
    actionType: SETTINGS.GET_SETTINGS_API_PROVIDERS,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getSettingsAdminList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `user/get-all-admin-users`,
    method: "GET",
    secure: true,
    actionType: SETTINGS.GET_SETTINGS_ADMIN_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getLastSyncInfo = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `settings/get-last-sync-datetime`,
    method: "GET",
    secure: true,
    actionType: SETTINGS.GET_LAST_SYNC_INFO,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getDataUsageCompanyList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `settings/get-companies-list`,
    method: "GET",
    secure: true,
    actionType: SETTINGS.GET_DATA_USAGE_COMPANY_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getCompanyDataUsage = (data, successHandler, errorHandler) => {
  let endPoint = `settings/get-company-api-usage-info?companyUserId=${data.companyUserId}`;
  endPoint = data.fromDate
    ? (endPoint = endPoint + `&fromDate=${data.fromDate}`)
    : endPoint;
  endPoint = data.toDate
    ? (endPoint = endPoint + `&toDate=${data.toDate}`)
    : endPoint;
  const fetchOptions = {
    url: endPoint,
    method: "GET",
    secure: true,
    actionType: SETTINGS.GET_COMPANY_API_USAGE,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const testApiConnection = (data, successHandler, errorHandler) => {
  const fetchOptions = {
    url: "settings/verify-email-account",
    method: "POST",
    secure: true,
    actionType: SETTINGS.TEST_API,
    body: JSON.stringify(data),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const settingsToggleMainLoader = (value) => {
  return {
    type: SETTINGS.SETTINGS_PRELOADER,
    payload: value,
  };
};
