import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import FormGroup from "../../../utils/formGroup";
import { ButtonDefault, ButtonPrimary } from "../../../utils/uiElements";
import { number } from "../../../constant/formValidators";

import {
	getUtcDateFromDatePicker,
	getDatePickerDateFromtUtcDate,
} from "../../../utils/helper";

const TrackConversionPanel = ({
	showtrackConversion,
	propertySourceList,
	moveTypeList,
	updateTrackConversion,
	handleSubmit,
	initialValues,
	setTrackConversionData,
	setShowLoader,
	successMessage,
	dataReload,
	setDataReload,
}) => {
	const [meetingDate, setMeetingDate] = useState(
		initialValues.meetingDate
			? getDatePickerDateFromtUtcDate(initialValues.meetingDate)
			: ""
	);

	const submitForm = (values = null) => {
		setShowLoader(true);
		const successHandler = (e) => {
			setTrackConversionData(e.result);
			showtrackConversion(false);
			setDataReload(!dataReload);
			setShowLoader(false);
			successMessage(6008);
		};
		const postData = {
			...values,
			meetingDate: values.meetingDate
				? getUtcDateFromDatePicker(values.meetingDate)
				: "",
			source: values.source
				? typeof values.source === "object"
					? values.source.value
					: values.source
				: "",
			moovType: values.moovType
				? typeof values.moovType === "object"
					? values.moovType.value
					: values.moovType
				: "",
		};
		updateTrackConversion(postData, successHandler);
	};
	return (
		<>
			<form
				onSubmit={handleSubmit(submitForm)}
				className="create-user-form">
				<div className="panel-switch  active ">
					<h1 className="mb-4">Track Conversion</h1>
					<div className="form-container pl-3">
						<div className="row">
							<span className="label-text">Opportunity</span>
							<div className="w-100 d-flex flex-row">
								<Field
									name="opportunity"
									component={FormGroup}
									type="radio"
									value={2}
									radioLabel="Yes"
									parse={(value) => Number(value)}
								/>
								<Field
									name="opportunity"
									component={FormGroup}
									type="radio"
									value={3}
									radioLabel="No"
									parse={(value) => Number(value)}
								/>
							</div>
							<div className="w-100 d-flex flex-column">
								<Field
									name="source"
									label="Source"
									component={FormGroup}
									type="select"
									multiple={false}
									defaultValue={
										propertySourceList &&
										propertySourceList.find(
											(element) =>
												parseInt(element.value) ===
												initialValues.source
										)
									}
									options={propertySourceList}
								/>
								<div className="d-flex flex-row">
									<Field
										name="meetingDate"
										label="Quote Date"
										component={FormGroup}
										type="datepicker"
										formGroupClass="w-50 mr-2"
										className="form-control"
										selectedDate={meetingDate}
										onChange={(value) => {
											setMeetingDate(value);
										}}
									/>

									<Field
										name="moovType"
										label="Moving Type"
										component={FormGroup}
										type="select"
										formGroupClass="w-50 ml-2"
										multiple={false}
										defaultValue={
											moveTypeList &&
											moveTypeList.find(
												(element) =>
													parseInt(element.value) ===
													initialValues.moovType
											)
										}
										options={moveTypeList}
									/>
								</div>
								<Field
									name="quoteAmount"
									label="Quote Amount($)"
									component={FormGroup}
									type="text"
									formGroupClass="w-100"
									className="form-control"
									validate={[number]}
								/>

								<span className="label-text">Booked</span>
								<div className="w-100 d-flex flex-row">
									<Field
										name="booked"
										component={FormGroup}
										type="radio"
										value={2}
										radioLabel="Yes"
										parse={(value) => Number(value)}
									/>
									<Field
										name="booked"
										component={FormGroup}
										type="radio"
										value={3}
										radioLabel="No"
										parse={(value) => Number(value)}
									/>
									<Field
										name="booked"
										component={FormGroup}
										type="radio"
										value={1}
										radioLabel="Not Decided"
										parse={(value) => Number(value)}
									/>
								</div>
								<Field
									name="comments"
									label="Add Notes"
									component={FormGroup}
									type="textarea"
									formGroupClass="w-100"
									className="form-control"
									placeholder="Write something here.."
								/>
							</div>
						</div>
					</div>
					<div className="form-footer pl-3">
						<ButtonDefault
							type="button"
							onClick={() => showtrackConversion(false)}
							value="Cancel"
						/>
						<ButtonPrimary type="submit" value="Submit" />
					</div>
				</div>
			</form>
		</>
	);
};
export default reduxForm({
	form: "trackConversionForm",
	enableReinitialize: true,
})(TrackConversionPanel);
