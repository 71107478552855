import React from "react";

import Tooltip from "./tooltip";

export const Button = ({ className, type, onClick, value, tooltip = "" }) => {
	return tooltip !== "" ? (
		<Tooltip
			message={tooltip}
			component={
				<button className={className} type={type} onClick={onClick}>
					{value}
				</button>
			}
		/>
	) : (
		<button className={className} type={type} onClick={onClick}>
			{value}
		</button>
	);
};

export const ButtonDefault = ({ type, onClick, value }) => {
	return (
		<button className="btn btn-default mt-3" type={type} onClick={onClick}>
			{value}
		</button>
	);
};

export const ButtonPrimary = ({ type, onClick, value }) => {
	return (
		<button className="btn btn-primary mt-3" type={type} onClick={onClick}>
			{value}
		</button>
	);
};

export const IconButton = ({ onClick, value, icon, functionParams }) => {
	return (
		<button
			type="button"
			onClick={() => onClick(functionParams)}
			className="btn btn-primary btn-md">
			<i className={`pr-2 icon icon-${icon}`}></i>
			{value ? <span>{value}</span> : null}
		</button>
	);
};
export default Button;
