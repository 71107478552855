import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import "./Dashboard.scss";

import { userTypes } from "../../constant/userManagement";
import SuperAdminChart from "./superAdminChart";
import UserChart from "./userChart";
import PerformanceHistory from "./performanceHistory";
import DashboardTabsCA from "./dashboardTabsCA";

const Dashboard = ({ userDetails, history }) => {
	const loggedInAs = (type) => {
		return userDetails.userType === userTypes[type];
	};

	return (
		<section className="moovsoon-page-container">
			<div className="moovsoon-page-body">
				<PerformanceHistory loggedInAs={loggedInAs} />
				{loggedInAs("AD") ? (
					<SuperAdminChart />
				) : (
					<>
						<UserChart loggedInAs={loggedInAs} />
						<DashboardTabsCA history={history} />
					</>
				)}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		userDetails: get(state, "Auth.userDetails", {}),
	};
};

export default connect(mapStateToProps)(Dashboard);
