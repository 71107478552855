import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./index.scss";
import moment from "moment";
import { get } from "lodash";

import {
	getInAppNotifications,
	getInAppNotificationDetails,
	resetField,
} from "../../store/actions";

const Notifications = ({
	getInAppNotifications,
	getInAppNotificationDetails,
	profileDetails,
	resetField,
}) => {
	const [notifications, setNotifications] = useState(null);
	const [activeNotification, setActiveNotification] = useState(null);
	const [notificationData, setNotificationData] = useState(null);

	const alertInfo = (message) => (
		<div className="alert alert-info p-3 text-center mt-3 mb-2">
			{message}
		</div>
	);

	useEffect(() => {
		const successHandler = (e) => {
			e && setNotifications(e.result.sort().reverse());
		};
		getInAppNotifications(successHandler);
	}, [getInAppNotifications]);

	const onToggle = (event) => {
		setNotificationData(null);
		setActiveNotification(null);
		if (
			event &&
			(!activeNotification ||
				activeNotification !== event.userNotificationLogId)
		) {
			const successHandler = (e) => {
				if (e && e.result) {
					const newProfileDetails = {
						...profileDetails,
						unreadNotificationsCount:
							profileDetails.unreadNotificationsCount - 1,
					};
					resetField("Auth.profileDetails", newProfileDetails);
					setNotificationData(e.result);
				}
			};
			setActiveNotification(event.userNotificationLogId);
			getInAppNotificationDetails(event, successHandler);
		}
	};

	const renderNotificationData = (event) => {
		return notificationData &&
			notificationData.length &&
			activeNotification &&
			activeNotification === event.userNotificationLogId ? (
			<div className="notification-details-container row">
				{notificationData.map((item) => {
					const {
						address,
						id,
						mlsId,
						notificationRefItemType,
						realtorName,
						realtorOfficeName,
					} = item;
					return (
						<div className="col-4 p-0" key={id}>
							<div className="notification-details-item">
								<div className="notification-details-item-id">
									{notificationRefItemType === 1
										? mlsId
										: realtorName}
								</div>
								<div className="notification-details-item-title">
									{notificationRefItemType === 1
										? address
										: realtorOfficeName}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		) : (
			alertInfo(`Loading`)
		);
	};

	const handleDate = (str) => {
		const date = moment.utc(str).format("MM/DD/YYYY");
		const today = moment().format("MM/DD/YYYY");
		return date === today ? "Today" : date ? date : "";
	};

	const renderList = () =>
		notifications.map((item, key) => {
			const { eventDate, eventList } = item;
			return (
				<div key={key}>
					<h3 className="color-1 text-uppercase py-2 flex-fill">
						{handleDate(eventDate)}
					</h3>
					{eventList && eventList.length ? (
						<div className="mb-2">
							{eventList.map((event, index) => {
								const {
									notificationRefItemCount,
									notificationViewStatus,
									eventName,
									userNotificationLogId,
								} = event;

								return (
									<div
										className={`card card-primary px-3 py-2 mb-3 ${
											activeNotification &&
											activeNotification ===
												userNotificationLogId
												? "active"
												: ""
										}`}
										key={index}>
										<div className="d-flex align-items-center notification-head">
											<div
												className={`mr-2 notification-read ${
													notificationViewStatus
														? "red"
														: ""
												}`}>
												<i
													className={`icon icon-${
														notificationViewStatus
															? "check"
															: "dot"
													}`}
												/>
											</div>
											<div className="notification-count pr-2">
												{notificationRefItemCount}
											</div>
											<div className="mr-2">
												{eventName}
											</div>

											<div className="flex-fill text-right">
												<div
													className="notification-toggle-btn"
													onClick={() =>
														onToggle(event)
													}>
													<i className="icon icon-arrow-bottom" />
												</div>
											</div>
										</div>
										<div className="notification-body">
											{renderNotificationData(event)}
										</div>
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			);
		});

	return (
		<section className="moovsoon-page-container">
			<div className="moovsoon-page-body">
				{notifications && notifications.length
					? renderList()
					: notifications && notifications.length === 0
					? alertInfo(`No items found!`)
					: alertInfo(`Loading`)}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	profileDetails: get(state, "Auth.profileDetails", null),
	unreadNotificationsCount: get(
		state,
		"Auth.profileDetails.unreadNotificationsCount",
		0
	),
});

const mapDispatchToProps = {
	getInAppNotifications,
	getInAppNotificationDetails,
	resetField,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
