import fetchHandler from "../../utils/fetchHandler";
import { REPORTS } from "../types";

export const getReportZipcodeList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `reports/get-property-zipcode-list`,
    method: "GET",
    secure: true,
    actionType: REPORTS.GET_REPORTS_ZIPCODE_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getReportRealtorList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `reports/get-property-realtor-list`,
    method: "GET",
    secure: true,
    actionType: REPORTS.GET_REPORTS_REALTOR_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getReportStatusList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `reports/get-property-status-list`,
    method: "GET",
    secure: true,
    actionType: REPORTS.GET_REPORTS_STATUS_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getReportSourceList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `reports/get-property-source-list`,
    method: "GET",
    secure: true,
    actionType: REPORTS.GET_REPORTS_SOURCE_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getReportMoveTypeList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `reports/get-move-type-list`,
    method: "GET",
    secure: true,
    actionType: REPORTS.GET_REPORTS_MOVE_TYPE_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getReportUserList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `reports/get-users-list`,
    method: "GET",
    secure: true,
    actionType: REPORTS.GET_REPORTS_USER_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};
export const getReportTimeFrameList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `reports/get-time-frame`,
    method: "GET",
    secure: true,
    actionType: REPORTS.GET_REPORTS_TIMEFRAME_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getReportClumnList = (successHandler, errorHandler) => {
  const fetchOptions = {
    url: `reports/get-columns-to-display`,
    method: "GET",
    secure: true,
    actionType: REPORTS.GET_REPORTS_COLUMN_LIST,
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const processReport = (values, successHandler, errorHandler) => {
  let endPoint = ``;
  switch (values.reportType) {
    case "listing":
      endPoint = `reports/get-listing-report`;
      break;
    case "conversion":
      endPoint = `reports/get-conversion-report`;
      break;
    case "operation":
      endPoint = `reports/get-operations-report`;
      break;
    case "pipeline":
      endPoint = `reports/get-pipeline-report`;
      break;
    default:
      endPoint = "`reports/get-listing-report`";
  }
  const fetchOptions = {
    url: endPoint,
    method: "POST",
    secure: true,
    actionType: REPORTS.PROCESS_REPORT,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const realtorReport = (values, successHandler, errorHandler) => {
  const fetchOptions = {
    url: "realtors/get-realtor-data-download",
    method: "POST",
    secure: true,
    actionType: REPORTS.REALTOR_REPORT,
    body: JSON.stringify(values),
  };
  return fetchHandler(fetchOptions, successHandler, errorHandler);
};
