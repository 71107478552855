import {
	required,
	email,
	min1,
	USPhoneNumber,
	normalizePhone,
} from "../../../constant/formValidators";
import { userRoles, userTypes } from "../../../constant/userManagement";

export const formFields = ({
	setError,
	createMode,
	profileEdit,
	userDetails,
	formValues,
	companyNames,
	fetchCompanyNames,
	initialValues,
}) => {
	const loggedInAs = (type) => {
		return userDetails.userType === userTypes[type];
	};

	const companyCondition =
		(loggedInAs("AD") && profileEdit) ||
		(loggedInAs("CA") && !profileEdit) ||
		loggedInAs("SM") ||
		loggedInAs("BO") ||
		(initialValues &&
			initialValues.userType === userTypes.CA &&
			!profileEdit);

	const permissionCondition =
		userDetails && (loggedInAs("SM") || loggedInAs("BO") || profileEdit);

	return [
		{
			name: "h3User",
			type: "h3",
			label: loggedInAs("AD") ? "Admin" : "User",
			className: "color-1 mt-3 mb-3 text-uppercase col-12",
		},
		{
			name: "uploadedFile64",
			label: "Profile Image",
			type: "file",
			hide: !profileEdit,
			multiple: false,
			defaultValue: formValues && formValues.profileImageURL,
			onChange: () => setError(null),
			formGroupClass: "col-12",
		},
		{
			name: "isActive",
			label: "Status",
			type: "boolean",
			hide: createMode || profileEdit,
			onChange: () => setError(null),
			formGroupClass: "col-12",
		},
		{
			name: "name",
			label: "First Name",
			type: "text",
			onChange: () => setError(null),
			formGroupClass: "col-12",
			validate: [required],
			maxlength: 64,
		},
		{
			name: "surname",
			label: "Last Name",
			type: "text",
			onChange: () => setError(null),
			formGroupClass: "col-12",
			validate: [required],
			maxlength: 64,
		},
		{
			name: "emailAddress",
			label: "Email Address",
			type: "email",
			hide: !createMode,
			onChange: () => setError(null),
			formGroupClass: "col-12",
			validate: [required, email],
			maxlength: 256,
		},
		{
			name: "personalPhoneNo",
			label: "Mobile Number",
			type: "text",
			onChange: () => setError(null),
			formGroupClass: "col-12",
			validate: [required, USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "userType",
			label: "Role",
			type: "select",
			options: roleNamesOptions(userDetails),
			hide: !loggedInAs("CA") || profileEdit || !createMode,
			onChange: (value) => {
				setError(null);
				fetchCompanyNames && fetchCompanyNames(value);
			},
			defaultValue:
				(formValues &&
					userRoles.filter(
						(item) => item.value === formValues.userType
					)[0]) ||
				null,
			formGroupClass: "col-12",
			validate: loggedInAs("CA") && !profileEdit ? [required] : null,
		},
		{
			name: "companyNameObj",
			label: "Submarket Owner",
			type: "select",
			hide:
				loggedInAs("CA") ||
				!(
					formValues &&
					formValues.userType &&
					(formValues.userType === userTypes.BO ||
						formValues.userType.value === userTypes.BO)
				) ||
				!createMode,
			options: companyNames.map((item) => {
				return {
					label: item.companyName || item.fullName,
					value: item.userId,
				};
			}),
			formGroupClass: "col-12",
			onChange: () => setError(null),
			// validate:
			//     formValues &&
			//     formValues.userType &&
			//     formValues.userType.value === userTypes.BO
			//         ? [required]
			//         : null
		},
		//
		{
			name: "h3Company",
			type: "h3",
			label: "Company",
			hide: companyCondition,
			className: "color-1 mt-4 mb-3 text-uppercase col-12",
		},
		{
			name: "companyName",
			label: "Company Name",
			type: "text",
			onChange: () => setError(null),
			formGroupClass: "col-12",
			hide: companyCondition,
			validate: [required],
			maxlength: 64,
		},
		{
			name: "officePhoneNo",
			label: "Phone Number",
			type: "text",
			onChange: () => setError(null),
			formGroupClass: "col-12",
			hide: companyCondition,
			validate: [required, USPhoneNumber],
			normalize: normalizePhone,
		},
		{
			name: "address1",
			label: "Address 1",
			type: "text",
			onChange: () => setError(null),
			hide: companyCondition,
			formGroupClass: "col-12",
		},
		{
			name: "address2",
			label: "Address 2",
			type: "text",
			onChange: () => setError(null),
			hide: companyCondition,
			formGroupClass: "col-12",
		},
		//
		{
			name: "h3Permissions",
			type: "h3",
			label: "Permissions",
			hide: profileEdit || loggedInAs("SM") || loggedInAs("BO"),
			className: "color-1 mt-4 mb-3 text-uppercase col-12",
		},
		{
			name: "noOfSeats",
			label: "Number of Seats",
			type: "number",
			onChange: () => setError(null),
			formGroupClass: "col-8",
			hide: permissionCondition || loggedInAs("CA"),
			validate: [required, min1],
		},
		{
			name: "fullService",
			label: "Full Service",
			type: "boolean",
			onChange: () => setError(null),
			hide: permissionCondition || loggedInAs("CA"),
			formGroupClass: "col-12",
		},
		{
			name: "homeOwnerDataAccessLimit",
			label: "Home Owner Data Access Limit",
			type: "number",
			hide:
				permissionCondition ||
				(formValues && formValues.fullService) ||
				loggedInAs("CA"),
			onChange: () => setError(null),
			formGroupClass: "col-8",
			validate: [min1],
		},
		{
			name: "accessToTemplates",
			label: "Access to Templates",
			type: "boolean",
			hide: profileEdit || !loggedInAs("CA"),
			onChange: () => setError(null),
			formGroupClass: "col-12",
		},
	];
};

export const roleNamesOptions = (userDetails) => {
	switch (userDetails.userType) {
		case userTypes.AD:
			return userRoles;
		case userTypes.CA:
			return [userRoles[2], userRoles[3]];
		case userTypes.SM:
			return [userRoles[3]];
		default:
			return [];
	}
};
