import fetchHandler from "../../utils/fetchHandler";
import { USER, SESSION } from "../types";

export const createUser = (values, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `user/create`,
        method: "POST",
        secure: true,
        actionType: USER.CREATE_USER,
        body: JSON.stringify(values),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getUsers = (values, successHandler, errorHandler) => {
    const fetchOptions = {
        // url: `user/get-users-list`,
        url: `user/get-users-paged-list`,
        method: "POST",
        secure: true,
        actionType: USER.GET_USERS,
        body: JSON.stringify(values),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getUser = (
    { userId, type = "SELECTED" },
    successHandler,
    errorHandler
) => {
    const getActionType = () => {
        switch (type) {
            case "SESSION":
                return SESSION.GET_USER_DETAILS;
            case "EDITING":
                return USER.GET_EDITING_USER;
            default:
                return USER.GET_SELECTED_USER;
        }
    };
    const fetchOptions = {
        url: `user/get-user?userId=${encodeURIComponent(userId)}`,
        method: "POST",
        secure: true,
        actionType: getActionType(),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const editUser = (
    values,
    successHandler,
    errorHandler,
    isLoggedInUser = false
) => {
    const fetchOptions = {
        url: `user/update-user`,
        method: "POST",
        secure: true,
        actionType: isLoggedInUser ? SESSION.EIDT_USER_DETAILS : USER.EIDT_USER,
        body: JSON.stringify(values),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getUserTypes = () => {
    const fetchOptions = {
        url: `user/user-types`,
        method: "GET",
        secure: true,
        actionType: USER.GET_USER_TYPES,
    };
    return fetchHandler(fetchOptions);
};

export const getCompanyNames = (userId, successHandler) => {
    const fetchOptions = {
        url: `user/get-users-list?userId=${encodeURIComponent(userId)}`,
        method: "POST",
        secure: true,
        actionType: USER.GET_COMPANY_NAMES,
    };
    return fetchHandler(fetchOptions, successHandler);
};

export const changePassword = (values, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `user/change-password`,
        method: "POST",
        secure: true,
        actionType: USER.CHANGE_PASSWORD,
        body: JSON.stringify(values),
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const deleteUser = (userId, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `user/delete-user?userId=${userId}`,
        method: "DELETE",
        secure: true,
        actionType: USER.DELETE_USER,
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};

export const getRemainingSeats = (userId, successHandler, errorHandler) => {
    const fetchOptions = {
        url: `user/find-remaining-seats?userId=${userId}`,
        method: "GET",
        secure: true,
        actionType: USER.GET_REMAINING_SEATS,
    };
    return fetchHandler(fetchOptions, successHandler, errorHandler);
};
