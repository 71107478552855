import React from "react";
import { propertyStatus } from "../../../constant/propertyManagement";
import { Date } from "../../../utils/formatter";
import { dateFormat } from "../../../constant/global";

const TimeLine = ({ propertyDetails, dataLoaded }) => {
	const getPropertyStatus = (value) => {
		return (
			propertyDetails &&
			propertyDetails.propertyStatusTimeline &&
			propertyDetails.propertyStatusTimeline.filter((item) => {
				return item.propertyStatus === value;
			})[0]
		);
	};

	const getBlock = (key, value) => {
		const status = getPropertyStatus(value);
		const classValue = status?.isCurrentStatus ? "orange" : "gray";
		const disabledClass = status?.isDisabled ? "icon-disable" : "";
		const lineClassValue = "dark-border-div";
		return propertyDetails && dataLoaded ? (
			<div className="w-25 d-flex flex-row">
				{key === "active" && classValue === "orange" ? (
					<i
						className={`status-iccon ${disabledClass} icon-status-active-orange-png`}
					></i>
				) : (
					<i
						className={`status-iccon ${disabledClass} icon-status-${key}-${classValue}`}
					></i>
				)}

				{key !== "offmarket" ? (
					<div className={`${lineClassValue}`}></div>
				) : null}
			</div>
		) : null;
	};

	const getTextBlock = (value, labe) => {
		const status = getPropertyStatus(value);

		return propertyDetails && dataLoaded ? (
			<div className="w-25 d-flex flex-row">
				<span className="label-text">{labe}</span>
				{status?.statusChangeDate ? (
					<span className="pl-2 label-text">
						<Date
							utcTime={status.statusChangeDate}
							format={dateFormat}
						/>
					</span>
				) : null}
			</div>
		) : null;
	};

	return (
		<>
			<div className="card d-flex flex-row card-primary pt-2 pl-2 h-50 no-border-shadow">
				{getBlock("new", propertyStatus.new.value)}
				{getBlock("active", propertyStatus.active.value)}
				{getBlock("pending", propertyStatus.pending.value)}
				{getBlock("offmarket", propertyStatus.offmarket.value)}
			</div>
			<div className="card d-flex flex-row card-primary pl-2 no-border-shadow">
				{getTextBlock(
					propertyStatus.new.value,
					propertyStatus.new.label
				)}
				{getTextBlock(
					propertyStatus.active.value,
					propertyStatus.active.label
				)}
				{getTextBlock(
					propertyStatus.pending.value,
					propertyStatus.pending.label
				)}
				{getTextBlock(
					propertyStatus.offmarket.value,
					propertyStatus.offmarket.label
				)}
			</div>
		</>
	);
};
export default TimeLine;
