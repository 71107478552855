import { required } from "../../../constant/formValidators";
import { HTMLFinder } from "../../../utils/htmlFinder";

export const templateTypeOptions = [
	{
		label: "Email",
		value: 1,
	},
	{
		label: "SMS",
		value: 2,
	},
	{
		label: "Mailer",
		value: 3,
	},
];

export const basicDetailsFormFields = ({
	dispatch,
	getTemplateProspectingEvents,
	prospectingEventOptions,
	initialValues,
	creating,
	formValues,
}) => {
	const isEditing = initialValues && initialValues.templateId;

	return {
		templateName: {
			name: "templateName",
			label: "Template Name",
			type: "text",
			onChange: () => dispatch({ error: null }),
			formGroupClass: "col-12",
			validate: [required],
			maxlength: 150,
		},
		templateType: {
			name: "templateType",
			label: "Communication Mode",
			type: "select",
			onChange: (value) => {
				const successHandler = (event) => {
					event &&
						event.result &&
						dispatch({
							prospectingEventOptions: event.result,
							error: null,
						});
				};
				getTemplateProspectingEvents(
					{ templateType: value.value },
					successHandler
				);
			},
			options: templateTypeOptions,
			defaultValue:
				initialValues && initialValues.templateType
					? templateTypeOptions.filter(
							(item) =>
								item.value === initialValues.templateType.value
					  )[0]
					: null,
			formGroupClass: "col-12",
			disable: isEditing || creating,
			viewMode: isEditing || creating,
			validate: [required],
		},
		prospectingEvent: {
			name: "prospectingEvent",
			label: "Listing Status",
			type: "select",
			onChange: () => dispatch({ error: null }),
			options: prospectingEventOptions,
			defaultValue: initialValues ? initialValues.prospectingEvent : null,
			formGroupClass: "col-12",
			disable: formValues && !formValues.templateType,
		},
	};
};

export const createdDefaultValues = (initialValues) => {
	const { templateType, templateBody, templateBodyBack } = initialValues;
	const findHTML = (id) => HTMLFinder(templateBody + templateBodyBack, id);
	const templateTypeObject = templateTypeOptions.filter(
		(item) => item.value === templateType
	);

	switch (templateType) {
		case 1:

		    const findHtmlSubject = findHTML(
				"#htmlTemplateEmailSubjectContainer"
			);

		    const findHtmlBody = findHTML("#htmlTemplateEmailBodyContainer");
			const findHtmlSignature = findHTML(
				"#htmlTemplateEmailSignatureContainer"
			);
			const findHtmlLogo = findHTML("#htmlTemplateEmailLogo");
			const findHtmlKnowMore = findHTML("#htmlTemplateEmailKnowMore");
			const findHtmlSocialMedias = findHTML(
				"#htmlTemplateEmailSocialMedias"
			);
			const findHtmlFaceBookUrl = findHTML(
				"#htmlTemplateEmailFaceBookUrl"
			);
			const findHtmlYoutubeUrl = findHTML("#htmlTemplateEmailYoutubeUrl");
			const findHtmlLinkedInUrl = findHTML(
				"#htmlTemplateEmailLinkedInUrl"
			);
			const findHtmlTwitterUrl = findHTML("#htmlTemplateEmailTwitterUrl");
			const findHtmlFooter = findHTML("#htmlTemplateEmailFooter");

			const returnData = {
				...initialValues,
				templateType: templateTypeObject[0],
				templateEmailSubject: findHtmlSubject
					? findHtmlSubject.innerHTML
					: "Email Subject..",
				templateEmailBody: findHtmlBody
					? findHtmlBody.innerHTML
					: "Write something here..",
				templateEmailSignature: findHtmlSignature
					? findHtmlSignature.innerHTML
					: "Enter your signature..",
				templateEmailShowLogo: findHtmlLogo ? true : false,
				templateEmailShowKnowMoreBtn: findHtmlKnowMore ? true : false,
				templateEmailShowSocialMedias: findHtmlSocialMedias
					? true
					: false,
				templateEmailShowFacebook: findHtmlFaceBookUrl ? true : false,
				templateEmailShowYoutube: findHtmlYoutubeUrl ? true : false,
				templateEmailShowLinkedIn: findHtmlLinkedInUrl ? true : false,
				templateEmailShowTwitter: findHtmlTwitterUrl ? true : false,
				templateEmailShowFooter: findHtmlFooter ? true : false,
			};
			return returnData;
		case 2:
			return {
				...initialValues,
				templateType: templateTypeObject[0],
				templateSMSBody: initialValues.templateBody,
			};
		case 3:
			const findPostCardFrontImage = findHTML("#postCardFrontImage");
			const findPostCardBackImage = findHTML("#postCardBackImage");
			const findTemplateMailerFooterText = findHTML(
				"#templateMailerFooterText"
			);
			const findTemplateMailerBodyText = findHTML(
				"#templateMailerBodyText"
			);

			return {
				...initialValues,
				templateType: templateTypeObject[0],
				postCardFrontImage: findPostCardFrontImage
					? findPostCardFrontImage.src
					: null,
				templateMailerBodyText: findTemplateMailerBodyText
					? findTemplateMailerBodyText.innerHTML
					: null,
				templateMailerFooterText: findTemplateMailerFooterText
					? findTemplateMailerFooterText.innerHTML
					: null,
				postCardBackImage: findPostCardBackImage
					? findPostCardBackImage.src
					: null,
				templateMailerMessageType: findPostCardBackImage ? 2 : 1,
			};
		default:
			return initialValues;
	}
};
