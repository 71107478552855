import React from "react";
import { connect } from "react-redux";

import { toggleMainLoader, resetField } from "../../../store/actions";
import {
	priceMin,
	priceMax,
	sizeMin,
	sizeMax,
} from "../../../constant/userManagement";

import Preloader from "../../../utils/preloader";
import TemplatePanel from "../../../utils/templatesPanel";

const MarketDetails = ({
	history,
	resetField,
	data,
	onEdit,
	loggedInAs,
	ownerTemplateProspectingEvents,
	relatorTemplateProspectingEvents,
	templateList,
}) => {
	const getLabel = (value, collection) => {
		const obj = collection.filter((item) => item.value === value);
		return obj && obj.length ? obj[0].label : null;
	};

	const onBackToListing = () => {
		resetField("Market.marketList", null);
		resetField("Market.recordsTotal", null);
		resetField("Market.selectedMarketDetails", null);
		history.goBack();
	};

	return (
		<section className="market-details">
			<button
				className="btn btn-default btn-sm mr-2 mb-3"
				onClick={onBackToListing}>
				<span className="text-primary">&lt;</span> Back
			</button>
			<div
				className={`user-details-wrap ${
					data === null ? "" : "loaded mb-3"
				}`}>
				<Preloader show={data === null} className="inner" />
				{data ? (
					<>
						<div className="user-details-card card card-primary px-4 pt-4 pb-2 mb-3">
							<div className="d-flex align-items-center mb-3">
								<h1 className="m-0 flex-fill">
									{data.itemName ? data.itemName : "--"}
								</h1>
								{(loggedInAs("AD") && data.subMarketId) ||
								(loggedInAs("CA") &&
									!data.subMarketId) ? null : (
									<button
										type="button"
										className="btn btn-default btn-md"
										onClick={() => onEdit(data)}>
										<i className="icon icon-edit" />
										<span className="ml-2">Edit</span>
									</button>
								)}
							</div>
							<div className="bb-1 pt-0 mb-4"></div>
							<h3 className="color-1 text-uppercase pb-2 mb-2 mr-3">
								Market definition
							</h3>
							<div className="row align-items-start">
								<div className="col-md-4  form-group">
									<div className="form-value">
										{data.subMarketType === 2
											? "Bucket"
											: data.subMarketType === 1
											? "Sub Market"
											: "Market"}
									</div>
									<div className="form-label">Category</div>
								</div>
								{data.subMarketType === 0 ? null : (
									<div className="col-md-4  form-group">
										<div className="form-value">
											{data.subMarketType === 2
												? data.parentSubMarketName
												: data.marketName}
										</div>
										<div className="form-label">Parent</div>
									</div>
								)}
								<div className="col-md-4  form-group">
									<div className="form-value">
										{data.numListings || "--"}
									</div>
									<div className="form-label">
										# of Listings
									</div>
								</div>
								{data.subMarketType === 0 ? (
									<div className="col-md-4  form-group">
										<div className="form-value">
											{data.county}
										</div>
										<div className="form-label">County</div>
									</div>
								) : null}
							</div>
							<div className="row align-items-start">
								<div className="col-md-4  form-group">
									<div className="form-value">
										{getLabel(data.propMinVal, priceMin)} -{" "}
										{getLabel(data.propMaxVal, priceMax)}
									</div>
									<div className="form-label">
										Listing Price
									</div>
								</div>
								<div className="col-md-4  form-group">
									<div className="form-value">
										{getLabel(data.propMinSize, sizeMin)} -{" "}
										{getLabel(data.propMaxSize, sizeMax)}
									</div>
									<div className="form-label">
										Listing Size
									</div>
								</div>
								<div className="col-md-4  form-group">
									<div className="form-value">
										{data.zipCodes
											? data.zipCodes.map((zip) => (
													<div
														className="badge badge-secondary badge-sm mr-2 mb-2"
														key={zip}>
														{zip}
													</div>
											  ))
											: "--"}
									</div>
									<div className="form-label">Zip Codes</div>
								</div>
							</div>
							<div className="bb-1 pt-0 mb-4"></div>
							<h3 className="color-1 text-uppercase pb-2 mb-2">
								Assigned User
							</h3>
							<div className="row align-items-start">
								<div className="col-md-4  form-group">
									<div className="form-value">
										{data.companyUserIds
											? data.companyUserIds.map(
													(user) => (
														<div
															className="badge badge-secondary badge-sm mr-2"
															key={user}>
															{user}
														</div>
													)
											  )
											: "--"}
									</div>
								</div>
							</div>
							{loggedInAs("AD") ? null : (
								<>
									<div className="bb-1 pt-0 mb-4"></div>
									<h3 className="color-1 text-uppercase pb-2 mb-2">
										Outreach
									</h3>
									<div className="form-group flex-row align-items-center">
										<div className="form-label mr-3">
											Automatic Prospecting :
										</div>
										<div className="form-value">
											{data.automaticProspecting
												? "On"
												: "Off"}
										</div>
									</div>
									{data.automaticProspecting ? (
										<>
											<h3 className="color-1 text-uppercase pb-2 mb-2">
												Templates
											</h3>
											<TemplatePanel
												{...{
													ownerTemplateProspectingEvents,
													relatorTemplateProspectingEvents,
													templateList,
													dataValues: data,
													createMode: 0,
												}}
											/>
										</>
									) : null}
								</>
							)}
						</div>
					</>
				) : null}
			</div>
		</section>
	);
};

const mapDispatchToProps = {
	toggleMainLoader,
	resetField,
};

export default connect(null, mapDispatchToProps)(MarketDetails);
