export const googleFonts = [
	"Alegreya='Alegreya', serif",
	"Alegreya Sans='Alegreya Sans', sans-serif",
	"Alfa Slab One='Alfa Slab One', cursive",
	"Anton='Anton', sans-serif",
	"Architects Daughter='Architects Daughter', cursive",
	"Archivo='Archivo', sans-serif",
	"Archivo Black='Archivo Black', sans-serif",
	"B612='B612', sans-serif",
	"BioRhyme='BioRhyme', serif",
	"Cairo='Cairo', sans-serif",
	"Caveat='Caveat', cursive",
	"Crimson Text='Crimson Text', serif",
	"Dawning of a New Day='Dawning of a New Day', cursive",
	"EB Garamond='EB Garamond', serif",
	"Fjalla One='Fjalla One', sans-serif",
	"Frank Ruhl Libre='Frank Ruhl Libre', serif",
	"Gloria Hallelujah='Gloria Hallelujah', cursive",
	"IBM Plex Serif='IBM Plex Serif', serif",
	"Karla='Karla', sans-serif",
	"Lato='Lato', sans-serif",
	"Liu Jian Mao Cao='Liu Jian Mao Cao', cursive",
	"Lora='Lora', serif",
	"Merriweather='Merriweather', serif",
	"Montserrat='Montserrat', sans-serif",
	"Mulish='Mulish', sans-serif",
	"Open Sans='Open Sans', sans-serif",
	"Permanent Marker='Permanent Marker', cursive",
	"Playfair Display='Playfair Display', serif",
	"Prata='Prata', serif",
	"PT Serif='PT Serif', serif",
	"Raleway='Raleway', sans-serif",
	"Reenie Beanie='Reenie Beanie', cursive",
	"Roboto='Roboto', sans-serif",
	"Roboto Slab='Roboto Slab', serif",
	"Rubik='Rubik', sans-serif",
	"Source Sans Pro='Source Sans Pro', sans-serif",
	"Special Elite='Special Elite', cursive",
	"Spectral='Spectral', serif",
	"Titillium Web='Titillium Web', sans-serif",
	"Varela='Varela', sans-serif",
	"Vollkorn='Vollkorn', serif",
];
