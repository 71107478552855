import React from "react";
import Tooltip from "./../../../utils/tooltip";

const SettingsBlock = ({ propertySettings, updateSettings }) => {
	return (
		<div className="d-flex flex-row p-1 w-25 br-1">
			<Tooltip
				message={
					propertySettings.markAsFavourite
						? "Unmark As Favorite"
						: "Mark As Favorite"
				}
				placement="right"
				component={
					<button
						type="button"
						onClick={() => updateSettings("markAsFavourite")}
						className="ml-3 btn btn-default btn-cell">
						<i
							className={`icomoon icon-valentines-heart-1 ${
								propertySettings.markAsFavourite
									? "icon-valentines-heart-red"
									: "icon-valentines-heart-white"
							}`}></i>
					</button>
				}
			/>

			<Tooltip
				message={
					propertySettings.markAsDiscarded
						? "Unmark As Discarded"
						: "Mark As Discarded"
				}
				placement="right"
				component={
					<button
						type="button"
						onClick={() => updateSettings("markAsDiscarded")}
						className="ml-3 btn btn-default btn-cell">
						<i
							className={`icomoon ${
								propertySettings.markAsDiscarded
									? "icon-archive icon-archive-active"
									: "icon-archive icon-archive-inactive"
							}`}></i>
					</button>
				}
			/>
		</div>
	);
};
export default SettingsBlock;
