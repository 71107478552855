import { REALTOR } from "../types";

export default (
  state = {
    filter: {
      sortColumn: "ranking",
      sortDirection: "asc",
      pageIndex: 1,
      pageSize: 10,
      searchKey: "",
      withRank: false,
    },
  },
  action
) => {
  switch (action.type) {
    case REALTOR.SET_FILTER:
      return {
        ...state,
        filter: action.payload ? action.payload : {},
      };
    case REALTOR.REALTOR_PRELOADER:
      return {
        ...state,
        realtorPreloader: action.payload,
      };
    default:
      return state;
  }
};
