import React, { useReducer, useEffect } from "react";
import { Field } from "redux-form";

import FormGroup from "../../../utils/formGroup";
import ToggleElementWrapper from "../../../utils/toggleElementWrapper";
import { emailTemplateHTML } from "./ematilTemplateHTML";

import {
	facebookIcon,
	youtubeIcon,
	twitterIcon,
	linkedInIcon,
} from "../../../constant/templateManagement";

const initialStates = {
	templateEmailShowLogo: true,
	templateEmailShowKnowMoreBtn: true,
	templateEmailShowSocialMedias: true,
	templateEmailShowFacebook: true,
	templateEmailShowYoutube: true,
	templateEmailShowLinkedIn: true,
	templateEmailShowTwitter: true,
	templateEmailShowFooter: true,
	templateEmailSubject: "",
	templateEmailBody: "",
	templateEmailSignature: "",

	previewData: null,
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const EmailTemplate = ({
	data,
	onTouch,
	formValues,
	initialValues,
	change,
	submitting,
}) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const {
		templateEmailShowLogo,
		templateEmailShowKnowMoreBtn,
		templateEmailShowSocialMedias,
		templateEmailShowFacebook,
		templateEmailShowYoutube,
		templateEmailShowLinkedIn,
		templateEmailShowTwitter,
		templateEmailShowFooter,
		previewData,
		templateEmailSubject,
		templateEmailBody,
		templateEmailSignature,
	} = state;

	useEffect(() => {
		if (initialValues && initialValues.templateId) {
			const {
				templateEmailShowLogo,
				templateEmailShowKnowMoreBtn,
				templateEmailShowSocialMedias,
				templateEmailShowFacebook,
				templateEmailShowYoutube,
				templateEmailShowLinkedIn,
				templateEmailShowTwitter,
				templateEmailShowFooter,
			} = initialValues;
			dispatch({
				templateEmailShowLogo,
				templateEmailShowKnowMoreBtn,
				templateEmailShowSocialMedias,
				templateEmailShowFacebook,
				templateEmailShowYoutube,
				templateEmailShowLinkedIn,
				templateEmailShowTwitter,
				templateEmailShowFooter,
			});
		}
	}, [initialValues]);

	const onPreview = () => {
		const templateBody = emailTemplateHTML({
			values: {
				...initialValues,
				...formValues,
			},
			data,
		});
		dispatch({
			previewData: previewData
				? null
				: { templateBody, templateName: data.templateName },
		});
	};

	const formFields = {
		templateEmailSubject: {
			name: "templateEmailSubject",
			maxLength: 255,
			placeholder: 'Set your email subject',
			formGroupClass: "mr-6 my-3 template-email-text-subject",
			onChange: (e) => onTouch(true),
			rteData:
				initialValues && initialValues.templateEmailSubject
					? initialValues.templateEmailSubject
					: templateEmailSubject,
		},
		templateEmailBody: {
			name: "templateEmailBody",
			type: "rte1",
			customeTag: true,
			formGroupClass: "mr-6 my-3 template-email-rte-body",
			onChange: (e) => onTouch(true),
			rteData:
				initialValues && initialValues.templateEmailBody
					? initialValues.templateEmailBody
					: templateEmailBody,
		},
		templateEmailSignature: {
			name: "templateEmailSignature",
			type: "rte1",
			formGroupClass: "mr-6 my-3 template-email-rte-signature",
			onChange: (e) => onTouch(true),
			rteData:
				initialValues && initialValues.templateEmailSignature
					? initialValues.templateEmailSignature
					: templateEmailSignature,
		},
		templateEmailShowLogo: {
			name: `templateEmailShowLogo`,
			type: "hidden",
		},
		templateEmailShowKnowMoreBtn: {
			name: `templateEmailShowKnowMoreBtn`,
			type: "hidden",
		},
		templateEmailShowSocialMedias: {
			name: `templateEmailShowSocialMedias`,
			type: "hidden",
		},
		templateEmailShowFacebook: {
			name: `templateEmailShowFacebook`,
			type: "hidden",
		},
		templateEmailShowYoutube: {
			name: `templateEmailShowYoutube`,
			type: "hidden",
		},
		templateEmailShowLinkedIn: {
			name: `templateEmailShowLinkedIn`,
			type: "hidden",
		},
		templateEmailShowTwitter: {
			name: `templateEmailShowTwitter`,
			type: "hidden",
		},
		templateEmailShowFooter: {
			name: `templateEmailShowFooter`,
			type: "hidden",
		},
	};

	const createAddress =
		data && (data.companyName || data.address1 || data.address2)
			? data.companyName +
			  (data.address1 ? `, ${data.address1}` : "") +
			  (data.address2 ? `, ${data.address2}` : "")
			: "";

	return (
		<div className="create-template-box">
			<Field
				component={FormGroup}
				{...formFields.templateEmailShowLogo}
			/>
			<Field
				component={FormGroup}
				{...formFields.templateEmailShowKnowMoreBtn}
			/>
			<Field
				component={FormGroup}
				{...formFields.templateEmailShowSocialMedias}
			/>
			<Field
				component={FormGroup}
				{...formFields.templateEmailShowFacebook}
			/>
			<Field
				component={FormGroup}
				{...formFields.templateEmailShowYoutube}
			/>
			<Field
				component={FormGroup}
				{...formFields.templateEmailShowLinkedIn}
			/>
			<Field
				component={FormGroup}
				{...formFields.templateEmailShowTwitter}
			/>
			<Field
				component={FormGroup}
				{...formFields.templateEmailShowFooter}
			/>
			<div className="d-flex my-3 px-4">
				<h2 className="flex-fill mr-3 mb-0">Email Template</h2>
				<button
					type="button"
					className="btn btn-default btn-sm"
					onClick={onPreview}>
					{previewData ? "Close " : ""}Preview
				</button>
			</div>
			<div className="create-template-box-inner">
				<div
					className={`create-template-box-inner-box create-template-form ${
						previewData ? "" : "show"
					}`}>
					<div
						className="card card-primary p-4"
						onClick={() => onTouch(true)}>
						<div className="form-container">
							{data && data.companyLogoURL ? (
								<div className="text-center">
									<ToggleElementWrapper
										value={templateEmailShowLogo}
										onToggle={(templateEmailShowLogo) => {
											change(
												"templateEmailShowLogo",
												templateEmailShowLogo
											);
											dispatch({ templateEmailShowLogo });
										}}>
										<img
											src={data.companyLogoURL}
											alt="Company Logo"
										/>
									</ToggleElementWrapper>
								</div>
							) : null}
							{/* <div className="text-left mt-3">
								Hi <i>(Recipient name)</i>,
							</div> */}

							<div>
								{initialValues &&
								(initialValues.templateEmailSubject ||
									!initialValues.templateId) ? (
									<>
										<div className="italic">
											Enter your email subject here
										</div>
										<Field
											component={FormGroup}
											{...formFields.templateEmailSubject}
										/>
									</>
								) : null }
							</div>


							<div>
								{initialValues &&
								(initialValues.templateEmailBody ||
									!initialValues.templateId) ? (
									<>
										<div className="italic">
											Enter your content here.
										</div>
										<Field
											component={FormGroup}
											{...formFields.templateEmailBody}
										/>
									</>
								) : null}
							</div>
							{data && data.website ? (
								<div className="text-center">
									<ToggleElementWrapper
										value={templateEmailShowKnowMoreBtn}
										onToggle={(
											templateEmailShowKnowMoreBtn
										) => {
											change(
												"templateEmailShowKnowMoreBtn",
												templateEmailShowKnowMoreBtn
											);
											dispatch({
												templateEmailShowKnowMoreBtn,
											});
										}}>
										<div className="btn btn-primary text-uppercase">
											Know More
										</div>
									</ToggleElementWrapper>
								</div>
							) : null}
							<div>
								{initialValues &&
								(initialValues.templateEmailSignature ||
									!initialValues.templateId) ? (
									<>
										<div className="italic">
											Enter your signature
										</div>
										<Field
											component={FormGroup}
											{...formFields.templateEmailSignature}
										/>
									</>
								) : null}
							</div>
							{data &&
							(data.faceBookUrl ||
								data.youtubeUrl ||
								data.linkedInUrl ||
								data.twitterUrl) ? (
								<ToggleElementWrapper
									value={templateEmailShowSocialMedias}
									onToggle={(
										templateEmailShowSocialMedias
									) => {
										change(
											"templateEmailShowSocialMedias",
											templateEmailShowSocialMedias
										);
										dispatch({
											templateEmailShowSocialMedias,
										});
									}}
									className="w-100">
									<>
										<div className="text-center py-2">
											Stay up to date with our latest news
											& features
										</div>
										<div className="d-flex justify-content-center py-2">
											<div className="mx-2">
												{data && data.faceBookUrl ? (
													<ToggleElementWrapper
														value={
															templateEmailShowFacebook
														}
														onToggle={(
															templateEmailShowFacebook
														) => {
															change(
																"templateEmailShowFacebook",
																templateEmailShowFacebook
															);
															dispatch({
																templateEmailShowFacebook,
															});
														}}>
														<div className="px-2 d-flex align-items-center">
															<img
																src={
																	facebookIcon
																}
																alt="Facebook"
															/>
															<div className="ml-2">
																Facebook
															</div>
														</div>
													</ToggleElementWrapper>
												) : null}
											</div>
											<div className="mx-2">
												{data && data.youtubeUrl ? (
													<ToggleElementWrapper
														value={
															templateEmailShowYoutube
														}
														onToggle={(
															templateEmailShowYoutube
														) => {
															change(
																"templateEmailShowYoutube",
																templateEmailShowYoutube
															);
															dispatch({
																templateEmailShowYoutube,
															});
														}}>
														<div className="px-2 d-flex align-items-center">
															<img
																src={
																	youtubeIcon
																}
																alt="Facebook"
															/>
															<div className="ml-2">
																Youtube
															</div>
														</div>
													</ToggleElementWrapper>
												) : null}
											</div>
											<div className="mx-2">
												{data && data.linkedInUrl ? (
													<ToggleElementWrapper
														value={
															templateEmailShowLinkedIn
														}
														onToggle={(
															templateEmailShowLinkedIn
														) => {
															change(
																"templateEmailShowLinkedIn",
																templateEmailShowLinkedIn
															);
															dispatch({
																templateEmailShowLinkedIn,
															});
														}}>
														<div className="px-2 d-flex align-items-center">
															<img
																src={
																	linkedInIcon
																}
																alt="Facebook"
															/>
															<div className="ml-2">
																LinkedIn
															</div>
														</div>
													</ToggleElementWrapper>
												) : null}
											</div>
											<div className="mx-2">
												{data && data.twitterUrl ? (
													<ToggleElementWrapper
														value={
															templateEmailShowTwitter
														}
														onToggle={(
															templateEmailShowTwitter
														) => {
															change(
																"templateEmailShowTwitter",
																templateEmailShowTwitter
															);
															dispatch({
																templateEmailShowTwitter,
															});
														}}>
														<div className="px-2 d-flex align-items-center">
															<img
																src={
																	twitterIcon
																}
																alt="Facebook"
															/>
															<div className="ml-2">
																Twitter
															</div>
														</div>
													</ToggleElementWrapper>
												) : null}
											</div>
										</div>
									</>
								</ToggleElementWrapper>
							) : null}

							<ToggleElementWrapper
								value={templateEmailShowFooter}
								onToggle={(templateEmailShowFooter) => {
									change(
										"templateEmailShowFooter",
										templateEmailShowFooter
									);
									dispatch({ templateEmailShowFooter });
								}}
								className="w-100 bg-gray">
								<div className="text-center pt-2">
									{createAddress} <br /> Tel:{" "}
									{data && data.officePhoneNo}
								</div>
								{data && data.website ? (
									<div className="text-center py-2">
										Interested to know more, visit{" "}
										<span className="text-primary">
											{data.website}
										</span>
									</div>
								) : null}
							</ToggleElementWrapper>
						</div>
					</div>
					<div className="form-footer text-right py-3">
						<button
							className="btn btn-primary mt-2"
							type="submit"
							disabled={submitting}>
							Save
						</button>
					</div>
				</div>
				<div
					className={`create-template-box-inner-box template-preview-panel ${
						previewData ? "show" : ""
					}`}>
					{previewData ? (
						<div
							dangerouslySetInnerHTML={{
								__html: previewData.templateBody,
							}}></div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default EmailTemplate;
