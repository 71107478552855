import React from "react";
import "./EditPanel.scss";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import FormGroup from "../../../utils/formGroup";
import {
	email,
	USPhoneNumber,
	normalizePhone,
} from "../../../constant/formValidators";

const formFields = [
	{
		name: "email",
		label: "Email",
		type: "text",
		formGroupClass: "col-12",
		validate: [email],
	},
	{
		name: "mobile1",
		label: "Mobile Number",
		type: "text",
		formGroupClass: "col-12",
		validate: [USPhoneNumber],
		normalize: normalizePhone,
	},
	{
		name: "officeName",
		label: "Office Name",
		type: "text",
		formGroupClass: "col-12",
		maxlength: 200,
	},
	{
		name: "officeAddress1",
		label: "Office Address 1",
		type: "text",
		formGroupClass: "col-12",
		maxlength: 500,
	},
	{
		name: "officeAddress2",
		label: "Office Address 2",
		type: "text",
		formGroupClass: "col-12",
		maxlength: 500,
	},
	{
		name: "officePhone1",
		label: "Office Phone",
		type: "text",
		formGroupClass: "col-12",
		validate: [USPhoneNumber],
		normalize: normalizePhone,
	},
];

const EditPanel = ({
	handleSubmit,
	onEditSubmit = null,
	onCancel = null,
	initialValues,
	dirtyForm,
}) => {
	return (
		<React.Fragment>
			<h2 className="mb-2">Edit Realtor</h2>
			<form
				className="create-user-form"
				onSubmit={handleSubmit(onEditSubmit)}>
				<div className="form-container">
					<div className="row">
						{formFields.map((item, index) => {
							return item.hide ? null : (
								<Field
									key={index}
									{...item}
									component={FormGroup}
								/>
							);
						})}
					</div>
				</div>
				<div className="form-footer">
					<button
						className="btn btn-default mt-3 mr-2"
						type="button"
						onClick={() => onCancel && onCancel()}>
						Cancel
					</button>
					<button className="btn btn-primary mt-3" type="submit">
						Submit
					</button>
				</div>
			</form>
		</React.Fragment>
	);
};

export default connect(
	null,
	null
)(
	reduxForm({
		form: "EditRealtorForm",
		enableReinitialize: true,
	})(EditPanel)
);
