import React, { useState } from "react";
import { Field } from "redux-form";

import FormGroup from "../../../utils/formGroup";
import { required, max1600 } from "../../../constant/formValidators";
import { Spinner } from "react-bootstrap";

const SMSTemplate = ({ onTouch, initialValues, submitting }) => {
  const [charCount, setCharCount] = useState(0);

  const formFields = {
    templateSMSBody: {
      name: "templateSMSBody",
      type: "textarea",
      formGroupClass: "col-12",
      validate: [required, max1600],
      onChange: (e) => {
        onTouch(true);
        setCharCount(e.target.value.length);
      },
      placeholder: "Write something here..",
    },
  };

  return (
    <div className="create-template-box">
      <div className="create-template-form px-4">
        <div className="form-container">
          <h2 className="my-3">SMS Template</h2>
          <div className="card card-primary p-4">
            <div className="row">
              {charCount >= 360 && (
                <div className="alert alert-text" role="alert">
                  SMS has exceeded 360 characters. This may incur additional
                  charges.
                </div>
              )}
              <Field
                component={FormGroup}
                {...formFields.templateSMSBody}
                style={{ height: "300px" }}
              />
            </div>
          </div>
        </div>
        <div className="form-footer d-flex justify-content-end align-items-center py-3">
          <button
            className="btn btn-primary mt-2 d-flex align-items-center"
            type="submit"
            disabled={submitting}
          >
            <span className="mr-2">Save</span>
            {submitting && (
              <Spinner animation="border" size="sm" variant="light" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SMSTemplate;
