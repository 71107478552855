import React from "react";
import NoTemplateTypeChoosenImage from "../../../assets/no-template-type-choosen.jpg";

const ChooseTemplate = () => {
	return (
		<div className="card card-primary p-4 no-template-type-choosen-card">
			<div className="text-center">
				<img
					src={NoTemplateTypeChoosenImage}
					alt="no-template-type-choosen"
				/>
			</div>
			<div className="text-center text-md px-5 py-4">
				Personalize your outreach by creating templates
			</div>
		</div>
	);
};

export default ChooseTemplate;
