import { SETTINGS } from "../types";

export default (state = {}, action) => {
	switch (action.type) {
		case SETTINGS.GET_SETTINGS:
			return {
				...state,
				userSettings: action.payload ? action.payload.result : {},
			};
		case SETTINGS.GET_SETTINGS_NOTIFICATION_EVENT_TYPES:
			return {
				...state,
				notificationEventTypes: action.payload
					? action.payload.result
					: {},
			};
		case SETTINGS.GET_SETTINGS_EMAIL_ACCOUNT_TYPES:
			return {
				...state,
				emailAccountTypes: action.payload
					? [
							{
								emailAccountName: "Select",
								emailAccountTypeId: 0,
								smtpPort: null,
								smtpServer: null,
							},
							...action.payload.result,
					  ]
					: {},
			};
		case SETTINGS.GET_SETTINGS_API_PROVIDERS:
			return {
				...state,
				APIProviders: action.payload ? action.payload.result : {},
			};
		case SETTINGS.GET_SETTINGS_ADMIN_LIST:
			return {
				...state,
				adminList: action.payload ? action.payload.result : {},
			};
		case SETTINGS.GET_LAST_SYNC_INFO:
			return {
				...state,
				lastSyncInfo: action.payload ? action.payload.result : {},
			};
		case SETTINGS.GET_DATA_USAGE_COMPANY_LIST:
			return {
				...state,
				dataUsageCompanyList: action.payload
					? action.payload.result
					: {},
			};
		case SETTINGS.SETTINGS_PRELOADER:
			return {
				...state,
				settingsPreloader: action.payload,
			};
		default:
			return state;
	}
};
