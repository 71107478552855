import React from "react";
import Tooltip from "../../../utils/tooltip";
import moment from "moment";

export const navData = [
  {
    label: "Email",
    icon: "icon icon-inbox",
  },
  {
    label: "SMS",
    icon: "icon icon-sms",
  },
  {
    label: "Mailer",
    icon: "icon icon-wallet",
  },
];

export const columns = ({
  onSelect,
  onDelete,
  onEdit,
  loggedInAs,
  userDetails,
}) => {
  return [
    {
      name: "Template Name",
      accessor: "templateName",
      className: "col-template-name",
      headerClassName: "col-template-name-header",
      render: (row) => (
        <div
          className="text-primary pointer ellipsis"
          onClick={() => onSelect(row)}
        >
          {row.templateName}
        </div>
      ),
    },
    {
      name: "Last Modified By",
      accessor: "lastModifierUser",
      className: "col-template-last-modified",
      headerClassName: "col-template-last-modified-header",
      hide: true,
    },
    {
      name: "Last Modified Date",
      accessor: "lastModifiedDate",
      className: "col-template-last-modified",
      headerClassName: "col-template-last-modified-header",
      render: (row) => {
        const date = row.lastModifiedDate
          ? moment.utc(row.lastModifiedDate).format("MM/DD/YYYY")
          : "";
        return date;
      },
    },
    {
      name: "Listing Status",
      accessor: "prospectingEventTypeName",
      className: "col-template-status",
      headerClassName: "col-template-status-header",
    },
    {
      name: "Company",
      accessor: "companyName",
      className: "col-template-company",
      headerClassName: "col-template-company-header",
      hide: !loggedInAs("AD"),
    },
    {
      name: "Actions",
      className: "col-actions text-right",
      headerClassName: "col-actions-header",
      sortable: false,
      hide:
        loggedInAs("AD") ||
        !userDetails ||
        (userDetails && !userDetails.accessToTemplates),
      render: (row) => (
        <div>
          <Tooltip
            message="Edit"
            component={
              <button
                type="button"
                className="btn btn-cell btn-default ml-2"
                onClick={() => onEdit(row)}
              >
                <i className="icon icon-edit" />
              </button>
            }
          />
          <Tooltip
            message="Delete"
            component={
              <button
                type="button"
                className="btn btn-cell btn-default ml-2"
                onClick={() => onDelete(row)}
              >
                <i className="icon icon-trash" />
              </button>
            }
          />
        </div>
      ),
    },
  ];
};
