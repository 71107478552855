import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import "../templates.scss";
import { get } from "lodash";

import NavTabs from "../../../utils/navTabs";
import {
  toggleMainLoader,
  getTemplates,
  getTemplateDetails,
  deleteTemplate,
} from "../../../store/actions";
import SearchField from "../../../utils/searchTable";
import { errorMessage } from "../../../utils/errorMessage";
import { Table, Pagination } from "../../../partials/table/table";
import { navData, columns as columnsFn } from "./helper";
import { createdDefaultValues } from "../create/helper";
import TableLoader from "../../../utils/tableLoader";
import SidePanel from "../../../utils/sidePanel";
import CreateTemplate from "../create";
import InfoModal from "../../../utils/infoModal";
import { successMessage } from "../../../utils/successMessage";
import Confirm from "../../../utils/confirmDelete";
import { userTypes } from "../../../constant/userManagement";
import emptyImageTemplate from "../../../assets/templates.png";

const initialStates = {
  templates: null,

  forceLoad: 0,
  templateType: 1,
  sortColumn: "templateName",
  sortDirection: "asc",
  pageIndex: 1,
  pageSize: 10,
  searchKey: "",
  recordsTotal: 0,

  showCreateTemplatePanel: false,
  templateDetails: null,
  deleteTemplateData: null,
  isEditing: false,
  disableDetailsForm: false,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const TemplateListing = ({
  getTemplates,
  getTemplateDetails,
  toggleMainLoader,
  deleteTemplate,
  userDetails,
}) => {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const {
    templates,
    templateType,
    sortColumn,
    sortDirection,
    pageIndex,
    pageSize,
    searchKey,
    recordsTotal,
    showCreateTemplatePanel,
    templateDetails,
    forceLoad,
    deleteTemplateData,
    isEditing,
    disableDetailsForm,
  } = state;

  useEffect(() => {
    const successHandler = (event) => {
      event &&
        event.result &&
        dispatch({
          recordsTotal: event.result.recordsTotal,
          templates: event.result.data,
        });
      toggleMainLoader(false);
    };
    const errorHandler = (event) => {
      errorMessage(event);
      toggleMainLoader(false);
    };
    getTemplates(
      {
        templateType,
        sortColumn,
        sortDirection,
        pageIndex,
        pageSize,
        searchKey,
      },
      successHandler,
      errorHandler
    );
  }, [
    getTemplates,
    templateType,
    sortColumn,
    sortDirection,
    pageIndex,
    pageSize,
    searchKey,
    toggleMainLoader,
    forceLoad,
  ]);

  const onTabChange = (nav, index) => {
    // toggleMainLoader(true);
    dispatch({ templateType: index + 1, templates: null, pageIndex: 1 });
  };

  const setDisableDetailsForm = (val) => {
    dispatch({ disableDetailsForm: val });
  };

  const onSelect = (row, editing = false) => {
    const successHandler = (event) => {
      if (event && event.result) {
        const templateDetails = createdDefaultValues(event.result);
        dispatch({
          templateDetails: {
            ...templateDetails,
            postCardFrontImage: templateDetails.templateBody,
            postCardBackImage: templateDetails.templateBodyBack,
          },
          isEditing: editing,
        });
      }
      toggleMainLoader(false);
    };
    row &&
      row.templateId &&
      getTemplateDetails({ templateId: row.templateId }, successHandler);
  };

  const onEdit = (row) => {
    onSelect(row, true);
  };

  const onDelete = (deleteTemplateData) => {
    dispatch({ deleteTemplateData });
  };

  const renderReason = (e) => {
    const { referenceDetails } = e;
    const newList = [];
    referenceDetails.forEach((element) => {
      if (
        element &&
        element.bucketName &&
        !newList.find((item) => item.bucketName === element.bucketName)
      ) {
        newList.push(element);
      }
      if (
        element &&
        element.subMarketName &&
        !newList.find((item) => item.subMarketName === element.subMarketName)
      ) {
        newList.push(element);
      }
      if (
        element &&
        element.marketName &&
        !newList.find((item) => item.marketName === element.marketName)
      ) {
        newList.push(element);
      }
    });
    return (
      <div>
        <h3>Template deletion failed!</h3>
        <div>This template is linked with following markets!</div>
        <ul className="list-bullet">
          {newList.map((item, index) => (
            <li key={index}>
              {item.bucketName ? item.bucketName : item.subMarketName}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const confirmDelete = () => {
    const successHandler = (e) => {
      e && e.result && !e.result.responseValue
        ? errorMessage(e, renderReason)
        : successMessage(4003);

      dispatch({ forceLoad: forceLoad + 1, deleteTemplateData: null });
    };
    const errorHandler = (e) => {
      errorMessage(e);
      dispatch({ deleteTemplateData: null });
    };
    deleteTemplateData &&
      deleteTemplate(
        { templateId: deleteTemplateData.templateId },
        successHandler,
        errorHandler
      );
  };

  const loggedInAs = (type) => {
    return userDetails.userType === userTypes[type];
  };

  const columns = columnsFn({
    onSelect,
    onDelete,
    onEdit,
    loggedInAs,
    userDetails,
  });

  const onCancel = () => {
    dispatch({
      showCreateTemplatePanel: false,
      templateDetails: null,
      isEditing: false,
      disableDetailsForm: false,
    });
  };

  const onCreate = (e) => {
    dispatch({
      showCreateTemplatePanel: false,
      templateDetails: null,
      isEditing: false,
      forceLoad: forceLoad + 1,
    });
  };

  const onSort = (e) =>
    e.by !== sortColumn || e.order !== sortDirection
      ? dispatch({
          sortColumn: e.by,
          sortDirection: e.order,
          pageIndex: 1,
        })
      : null;

  const createTemplatePreview = () => {
    const { templateType, templateBody, templateBodyBack } = templateDetails;
    return templateType && templateType.value === 3
      ? `<table style="display:table;margin: 0 auto;width: 600px;border-collapse: separate;border-spacing: 10px;">
				<tbody>
				<tr>
				<td>
				${
          templateBody
            ? `<img style="height:4.25in; width:6.25in;" id="postCardFrontImage" src="${templateBody}"/>`
            : ""
        }
				</td>
				</tr>
				<tr>
				<td>
				${
          templateBodyBack
            ? `<img style="height:4.25in; width:6.25in;" id="postCardBackImage" src="${templateBodyBack}"/>`
            : ""
        }
				</td>
				</tr>            
				</tbody>
			</table>`
      : templateBody
      ? templateBody
      : "";
  };

  const emptyComponent = () => {
    return (
      <div className="empty-component">
        <img
          src={emptyImageTemplate}
          alt="emptyImageTemplate"
          className="empty-component-icon"
        />
        <h3 className="color-1 text-uppercase">
          Creating templates for events
        </h3>
        <p>
          <span className="text-primary">Step 1: </span>Ensure that the company
          details are saved in the company settings for template creation.
        </p>
        <p>
          <span className="text-primary">Step 2: </span>Create email, sms and
          mailer templates for various events in the listing status.
        </p>
        <p>
          <span className="text-primary">Step 3: </span>Set templates against a
          submarket or bucket to automatically prospect to the home owner or
          realtor.
        </p>
      </div>
    );
  };

  return (
    <section className="moovsoon-page-container">
      <div className="moovsoon-page-body">
        <div className="d-flex align-items-center mb-3">
          <h2 className="mr-3 mb-0">Templates</h2>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="d-flex">
            <NavTabs data={navData} onSelect={onTabChange} />
          </div>
          <SearchField
            initialValue={searchKey}
            onSearch={(searchKey) => dispatch({ searchKey, pageIndex: 1 })}
            className={`ml-3 flex-fill form-group mb-0`}
            placeholder="Enter your query"
          />
          {loggedInAs("AD") ||
          !userDetails ||
          (userDetails && !userDetails.accessToTemplates) ? null : (
            <button
              type="button"
              className="btn btn-primary btn-md ml-3"
              onClick={() =>
                dispatch({
                  showCreateTemplatePanel: true,
                  isEditing: false,
                  templateDetails: {
                    templateEmailShowLogo: true,
                    templateEmailShowKnowMoreBtn: true,
                    templateEmailShowSocialMedias: true,
                    templateEmailShowFacebook: true,
                    templateEmailShowYoutube: true,
                    templateEmailShowLinkedIn: true,
                    templateEmailShowTwitter: true,
                    templateEmailShowFooter: true,
                  },
                })
              }
            >
              Create Template
            </button>
          )}
        </div>
        {templates && templates.length ? (
          <div>
            <Table columns={columns} data={templates} onSort={onSort} />
            <Pagination
              total={recordsTotal}
              count={pageSize}
              page={pageIndex}
              onChange={(pageIndex) => dispatch({ pageIndex })}
            />
          </div>
        ) : null}
        <TableLoader list={templates} emptyComponent={emptyComponent} />
      </div>
      <div className="template-pan">
        <SidePanel
          show={isEditing || showCreateTemplatePanel}
          onHide={() =>
            dispatch({
              showCreateTemplatePanel: false,
              isEditing: false,
            })
          }
          component={CreateTemplate}
          className={`create-template-panel  ${
            disableDetailsForm
              ? "create-template-panel-mm"
              : "create-template-panel-lm"
          }`}
          enforceFocus={false}
          componentProps={{
            onCancel,
            onCreate,
            isEditing,
            initialValues: templateDetails,
            disableDetailsForm,
            setDisableDetailsForm,
          }}
        />
      </div>
      <InfoModal
        show={
          !isEditing && templateDetails && templateDetails.templateId
            ? true
            : false
        }
        message={
          templateDetails ? (
            <div
              dangerouslySetInnerHTML={{
                __html: createTemplatePreview(),
              }}
            ></div>
          ) : null
        }
        title={templateDetails && templateDetails.templateName}
        onYes={() => dispatch({ templateDetails: null })}
        className="view-template-modal"
      />
      <Confirm
        message={`Are you sure you want to delete this template?`}
        onYes={confirmDelete}
        onNo={(e) => dispatch({ deleteTemplateData: null })}
        show={deleteTemplateData ? true : false}
      />
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    userDetails: get(state, "Auth.userDetails", {}),
  };
};
const mapDispatchToProps = {
  toggleMainLoader,
  getTemplates,
  getTemplateDetails,
  deleteTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateListing);
