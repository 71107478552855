import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import moment from "moment";

import { toggleMainLoader, getDashboardRealtors } from "../../store/actions";
import { Table, Pagination } from "../../partials/table/table";
import TableLoader from "../../utils/tableLoader";

import { columns as columnsFn } from "../realtors/listing/helper";

const initialStates = {
	realtorList: null,
	recordsTotal: 0,
	pageIndex: 1,
	pageSize: 10,
	sortColumn: "ranking",
	sortDirection: "asc",
	searchKey: "",
};

const reducer = (state, action) => {
	return { ...state, ...action };
};

const DashboardTabsCARealtors = ({
	history,
	toggleMainLoader,
	userDetails,
	getDashboardRealtors,
}) => {
	const [state, dispatch] = useReducer(reducer, initialStates);
	const {
		realtorList,
		pageIndex,
		pageSize,
		sortColumn,
		sortDirection,
		searchKey,
		recordsTotal,
	} = state;

	useEffect(() => {
		toggleMainLoader(true);
		const successHandler = (e) => {
			toggleMainLoader(false);
			dispatch({
				realtorList: e.result.data,
				recordsTotal: e.result.recordsTotal,
			});
		};
		const errorHandler = () => {
			toggleMainLoader(false);
		};
		getDashboardRealtors(
			{
				pageIndex,
				pageSize,
				sortColumn,
				sortDirection,
				searchKey,
				userId: userDetails.userId,
			},
			successHandler,
			errorHandler
		);
	}, [
		getDashboardRealtors,
		pageIndex,
		pageSize,
		sortColumn,
		sortDirection,
		searchKey,
		userDetails,
		toggleMainLoader,
	]);

	const onSelect = (row) => {
		history.push(`/realtors/${row.realtorId}/view`);
	};

	const columns = columnsFn({
		onSelect,
		userDetails,
	});

	const onSort = (e) =>
		e.by !== sortColumn || e.order !== sortDirection
			? dispatch({
					sortColumn: e.by,
					sortDirection: e.order,
					pageIndex: 1,
			  })
			: null;

	return realtorList && realtorList.length ? (
		<div className="relative">
			<div className="date-added-to-ms">
				{realtorList[0].dateAdded ? (
					<>
						<span className="text-gray">Date added to MS: </span>
						{moment
							.utc(realtorList[0].dateAdded)
							.format("MM/DD/YYYY")}
					</>
				) : null}
			</div>
			<Table
				columns={columns}
				data={realtorList}
				onSort={onSort}
				sortBy={initialStates.sortColumn}
				sortDir={initialStates.sortDirection}
			/>
			<Pagination
				total={recordsTotal}
				count={pageSize}
				page={pageIndex}
				onChange={(pageIndex) => dispatch({ pageIndex })}
			/>
		</div>
	) : (
		<TableLoader list={realtorList} />
	);
};

const mapStateToProps = (state) => {
	return {
		userDetails: get(state, "Auth.userDetails", {}),
	};
};

const mapDispatchToProps = {
	toggleMainLoader,
	getDashboardRealtors,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardTabsCARealtors);
