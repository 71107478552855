import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { successMessage } from "../../utils/successMessage";
import { processReport } from "../../store/actions";
import { errorMessage } from "../../utils/errorMessage";
import { downloadFile } from "./helper";

const PipelineReport = (props) => {
	const { processReport, handleSubmit, toggleMainLoader } = props;

	const submitForm = (values = null) => {
		toggleMainLoader(true);

		const successHandler = (data) => {
			toggleMainLoader(false);
			if (values.actionType === "download") {
				downloadFile(data.result, `Moovsoon_pipelinereport`);
			} else {
				successMessage(7001);
			}
		};
		const errorHandler = (event) => {
			errorMessage(event);
			toggleMainLoader(false);
		};

		const postData = {};
		postData.responseType = values.actionType === "download" ? 1 : 2;
		postData.reportType = "pipeline";
		processReport(postData, successHandler, errorHandler);
	};

	const renderSubmitButton = (type, label) => {
		return (
			<button
				type="button"
				onClick={handleSubmit((values) =>
					submitForm({
						...values,
						actionType: type,
					})
				)}
				className="btn btn-primary btn-md action-button mr-4">
				<i className={`pr-2 icon icon-${type}`}></i>
				<span>{label}</span>
			</button>
		);
	};

	return (
		<>
			<form className="report-form" onSubmit={handleSubmit(submitForm)}>
				<div className="card card-primary px-4 pt-2 pb-1 mb-3 p-4">
					<div className="panel-switch  active p-4">
						Email or Download the listings in pipeline !
					</div>
				</div>

				<div className="d-flex flex-row justify-content-end">
					{renderSubmitButton("email", "Email")}
					{renderSubmitButton("download", "Download")}
				</div>
			</form>
		</>
	);
};

const mapDispatchToProps = {
	processReport,
};

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "PipelineReportForm",
		enableReinitialize: true,
	})(PipelineReport)
);
